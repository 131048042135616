type ErrorCodeToTranslationMapper = {
  [key: number]: string;
};

export const errorCodeToTranslationMapper: ErrorCodeToTranslationMapper = {
  1000: "errors.SERVER_ERROR",
  1001: "errors.EMAIL_ALREADY_USED",
  1002: "errors.BAD_REQUEST",
  1003: "errors.WRONG_VERIFICATION_CODE",
  1004: "errors.UNAUTHORIZED",
  1005: "errors.USER_NOT_FOUND",
  1006: "errors.SESSION_EXPIRED",
  1007: "errors.INVALID_EMAIL",
  1008: "errors.WRONG_CREDENTIALS",
  1009: "errors.USER_NOT_VERIFIED",
  1010: "errors.EMAIL_NOT_SENT",
  1011: "errors.MISSING_PRIVILEGES",
  1012: "errors.NOT_FOUND",
};
