import { Center, Flex, Text } from "@chakra-ui/react";
import { ReactNode, useContext } from "react";
import { Image } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { AlertMessage } from "../components/Molecules/AlertMessage/AlertMessage";
import { AppContext } from "../context/AppContext";
import { useCheckIsMobile } from "../lib/utils";

interface LoginLayoutProps {
  children: ReactNode;
}

const LoginLayout = ({ children }: LoginLayoutProps) => {
  const isMobile = useCheckIsMobile();
  const { showAlertMessage, alertMessageProps } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <Flex bg="white" minH="100vh" w="100%" h={"100%"}>
      {!isMobile && (
        <Flex
          w="25%"
          minW={"400px"}
          minH="100vh"
          height="100%"
          sx={{
            backgroundColor: "green.foundationDark",
            borderRadius: "0px 20px 0px 0px",
            color: "white",
            padding: "48px",
            gap: "88px",
            flexDirection: "column",
            alignItems: "flexStart",
            alignSelf: "stretch",
          }}
        >
          <Image src="/Logo.svg" width={"215px"} alt="Logo" />
          <Flex
            sx={{
              flexGrow: 1,
              flexDir: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image src="Mockups.svg" width={"100%"} mt={"30px"} mb={"10px"} />
            <Image src="Stars.svg" width={"120px"} />
            <Text
              sx={{ fontsize: "20px", fontWeight: "bold", textAlign: "center" }}
            >
              {t("login.appReview")}
            </Text>
          </Flex>
          <Text sx={{ textAlign: "center", fontsize: "16px", fontWeight: 600 }}>
            {t("login.appDesc")}
          </Text>
        </Flex>
      )}

      <Flex
        w={isMobile ? "100%" : "75%"}
        minH="100vh"
        height="100%"
        mt={isMobile ? 6 : 0}
        backgroundColor={"white"}
        sx={{
          flexDir: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {showAlertMessage && alertMessageProps && (
          <AlertMessage {...alertMessageProps} position="top" />
        )}

        <Center
          sx={{
            p: "16px",
            flexGrow: 1,
          }}
        >
          <Flex
            sx={{
              flexDir: "column",
              alignItems: "flexStart",
            }}
          >
            {children}
          </Flex>
        </Center>
      </Flex>
    </Flex>
  );
};

export default LoginLayout;
