import { useTranslation } from "react-i18next";
import Modal from "../../Molecules/Modal/Modal";
import { Flex, Text } from "@chakra-ui/react";
import { AppContext } from "../../../context/AppContext";
import { useContext, useEffect, useState } from "react";
import Select, { Option } from "../../Molecules/Select/Select";
import { useGetUsersByEmail } from "../../../hooks/user";

interface InviteUsersModalProps {
  onClick: (userId: number, workspaceId: number, role: string) => void;
  onClose: () => void;
  isOpen: boolean;
  isDisabled?: boolean;
}

const InviteUsersModal = ({
  onClick,
  onClose,
  isOpen,
  isDisabled = false,
}: InviteUsersModalProps) => {
  const { t } = useTranslation();
  const { currentWorkspace } = useContext(AppContext);
  const [usersOptions, setUserOptions] = useState<Option[]>([]);
  const [emailFilter, setEmailFilter] = useState<string | undefined>("");
  const [errorMessage, setErrorMessage] = useState<string | undefined>("");

  const { users } = useGetUsersByEmail({
    workspaceId: currentWorkspace?.id,
    email: emailFilter || "",
  });

  const userTypeOptions = [
    {
      value: "basicUser",
      label: t("workspace.basicUser"),
    },
    {
      value: "admin",
      label: t("workspace.admin"),
    },
  ];

  useEffect(() => {
    if (users?.length) {
      const options = users.map((user) => {
        const option: Option = {
          value: user.id?.toString(),
          label: user.firstName + " " + user.lastName,
          src: user.filePath,
          subtitle: user.email,
        };
        return option;
      });
      setUserOptions(options);
    } else {
      setUserOptions([]);
    }
  }, [users, emailFilter]);

  const [selectedUserTypeOption, setSelectedUserTypeOption] = useState<Option>(
    userTypeOptions[0]
  );
  const [selectedUserOption, setSelectedUserOption] = useState<
    Option | undefined
  >(undefined);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  return (
    <Modal
      onClick={() => {
        if (!selectedUserOption) {
          setErrorMessage(t("validation.requiredFiled"));
        } else {
          if (
            !currentWorkspace?.id ||
            !selectedUserOption.value ||
            !selectedUserTypeOption.value
          )
            return;
          onClick(
            Number(selectedUserOption.value),
            currentWorkspace.id,
            selectedUserTypeOption.value
          );
        }
      }}
      onClose={() => onClose()}
      isOpen={isOpen}
      primaryLabel={t("common.cancel")}
      secondaryLabel={t("common.add")}
      variant="success"
      title={<Text>{t("users.addUserToWorkspace")}</Text>}
      subtitle={
        <Text sx={{ wordBreak: "break-all" }}>
          {t("users.addUserToWorkspaceDesc")}{" "}
          <Text as="span" sx={{ display: "inline-block", fontWeight: 600 }}>
            {currentWorkspace?.name}.
          </Text>{" "}
          {t("users.addUserWarning")}
        </Text>
      }
      imageSrc="/AddUser.svg"
      size="md"
      disabled={isDisabled}
    >
      <Flex sx={{ flexDir: "column", my: 6, gap: 6 }}>
        <Select
          isMulti={false}
          name="email"
          theme="withSuggestions"
          label={t("users.email")}
          placeholder={t("users.emailPlaceholder")}
          options={usersOptions}
          withImages={true}
          defaultOption={selectedUserOption}
          onChange={(option) => {
            if (timeoutId) {
              clearTimeout(timeoutId);
              setTimeoutId(null);
            }
            setErrorMessage("");
            if (option) setSelectedUserOption(option);
          }}
          isSearchable={true}
          onInputChange={(value) => {
            if (timeoutId) {
              clearTimeout(timeoutId);
              setTimeoutId(null);
            }
            setErrorMessage("");
            const id = setTimeout(() => {
              setEmailFilter(value.toString() || undefined);
            }, 500);
            setTimeoutId(id);
          }}
          onRemove={() => {
            if (timeoutId) {
              clearTimeout(timeoutId);
              setTimeoutId(null);
            }
            setErrorMessage("");
            setSelectedUserOption(undefined);
            setEmailFilter(undefined);
          }}
          errorMessage={errorMessage}
          isDisabled={isDisabled}
        />
        <Select
          isMulti={false}
          name="userTypes"
          theme="default"
          label={t("profile.role")}
          options={userTypeOptions}
          withImages={false}
          defaultOption={selectedUserTypeOption}
          onChange={(option) => {
            if (option) setSelectedUserTypeOption(option);
          }}
          isSearchable={false}
          isDisabled={isDisabled}
        />
      </Flex>
    </Modal>
  );
};

export default InviteUsersModal;
