import { useTranslation } from "react-i18next";
import { AppContext } from "../context/AppContext";
import { useContext } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { errorCodeToTranslationMapper } from "../mappers/ErrorCodeMapper";
import { AlertMessageStatus } from "../components/Molecules/AlertMessage/AlertMessage";
import { ErrorCodes } from "../types/ErrorCodes";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import {
  addJobStatusMutate,
  deleteJobStatusMutate,
  editJobStatusMutate,
  reorderJobStatusMutate,
} from "../services/jobStatus";

export const useAddJobStatus = () => {
  const { t } = useTranslation();
  const { setAlertMessage, showAlertMessage, closeAlertMessage } =
    useContext(AppContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutateAsync: addJobStatus, isPending: isPendingJobStatus } =
    useMutation({
      mutationFn: addJobStatusMutate,
      onSuccess: (data) => {
        if (showAlertMessage) closeAlertMessage();
        if (data.jobStatus) {
          setAlertMessage(
            {
              title: t("success.jobStatusCreated"),
              variant: AlertMessageStatus.SUCCESS,
            },
            true
          );
          queryClient.invalidateQueries({ queryKey: ["currentUser"] });
        }
        if (data.errorCode) {
          if (data.errorCode === ErrorCodes.SESSION_EXPIRED) {
            Object.keys(Cookies.get()).forEach(function (cookieName) {
              Cookies.remove(cookieName);
            });
            navigate("/login");
          }
          setAlertMessage(
            {
              title: t(errorCodeToTranslationMapper[data.errorCode]),
              description: data.error
                ? data.error
                : t("errors.ERROR_CODE", { code: data.errorCode }),
              variant: AlertMessageStatus.ERROR,
            },
            true
          );
        }
      },
    });

  return { addJobStatus, isPendingJobStatus };
};

export const useEditJobStatus = () => {
  const { t } = useTranslation();
  const { setAlertMessage, showAlertMessage, closeAlertMessage } =
    useContext(AppContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutateAsync: editJobStatus, isPending: isPendingEditJobStatus } =
    useMutation({
      mutationFn: editJobStatusMutate,
      onSuccess: (data) => {
        if (showAlertMessage) closeAlertMessage();
        if (data.jobStatus) {
          setAlertMessage(
            {
              title: t("success.jobStatusEdited"),
              variant: AlertMessageStatus.SUCCESS,
            },
            true
          );
          queryClient.invalidateQueries({ queryKey: ["currentUser"] });
        }
        if (data.errorCode) {
          if (data.errorCode === ErrorCodes.SESSION_EXPIRED) {
            Object.keys(Cookies.get()).forEach(function (cookieName) {
              Cookies.remove(cookieName);
            });
            navigate("/login");
          }
          setAlertMessage(
            {
              title: t(errorCodeToTranslationMapper[data.errorCode]),
              description: data.error
                ? data.error
                : t("errors.ERROR_CODE", { code: data.errorCode }),
              variant: AlertMessageStatus.ERROR,
            },
            true
          );
        }
      },
    });

  return { editJobStatus, isPendingEditJobStatus };
};

export const useDeleteJobStatus = () => {
  const { t } = useTranslation();
  const { setAlertMessage, showAlertMessage, closeAlertMessage } =
    useContext(AppContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutateAsync: deleteJobStatus, isPending: isPendingDeleteJobStatus } =
    useMutation({
      mutationFn: deleteJobStatusMutate,
      onSuccess: (data) => {
        if (showAlertMessage) closeAlertMessage();
        if (data.jobStatuses) {
          setAlertMessage(
            {
              title: t("success.jobStatusRemoved"),
              variant: AlertMessageStatus.SUCCESS,
            },
            true
          );
          queryClient.invalidateQueries({ queryKey: ["currentUser"] });
        }
        if (data.errorCode) {
          if (data.errorCode === ErrorCodes.SESSION_EXPIRED) {
            Object.keys(Cookies.get()).forEach(function (cookieName) {
              Cookies.remove(cookieName);
            });
            navigate("/login");
          }
          setAlertMessage(
            {
              title: t(errorCodeToTranslationMapper[data.errorCode]),
              description: data.error
                ? data.error
                : t("errors.ERROR_CODE", { code: data.errorCode }),
              variant: AlertMessageStatus.ERROR,
            },
            true
          );
        }
      },
    });

  return { deleteJobStatus, isPendingDeleteJobStatus };
};

export const useReorderJobStatus = () => {
  const { t } = useTranslation();
  const { setAlertMessage, showAlertMessage, closeAlertMessage } =
    useContext(AppContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    mutateAsync: reorderJobStatuses,
    isPending: isPendingReorderJobStatuses,
  } = useMutation({
    mutationFn: reorderJobStatusMutate,
    onSuccess: (data) => {
      if (showAlertMessage) closeAlertMessage();
      if (data.jobStatuses) {
        queryClient.invalidateQueries({ queryKey: ["currentUser"] });
      }
      if (data.errorCode) {
        if (data.errorCode === ErrorCodes.SESSION_EXPIRED) {
          Object.keys(Cookies.get()).forEach(function (cookieName) {
            Cookies.remove(cookieName);
          });
          navigate("/login");
        }
        setAlertMessage(
          {
            title: t(errorCodeToTranslationMapper[data.errorCode]),
            description: data.error
              ? data.error
              : t("errors.ERROR_CODE", { code: data.errorCode }),
            variant: AlertMessageStatus.ERROR,
          },
          true
        );
      }
    },
  });

  return { reorderJobStatuses, isPendingReorderJobStatuses };
};
