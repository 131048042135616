import { Thead, Tr, Th, Tbody, Text } from "@chakra-ui/react";
import { Table } from "../../Atoms/Table/Table";
import { AppContext } from "../../../context/AppContext";
import { useContext, useEffect, useState } from "react";
import { JobStatus } from "../../../types/workspace";
import { useTranslation } from "react-i18next";
import { jobStatusSchema } from "./JobStatusesTab";
import Modal from "../../Molecules/Modal/Modal";
import { Input } from "../../Atoms/Input/Input";
import {
  useDeleteJobStatus,
  useEditJobStatus,
  useReorderJobStatus,
} from "../../../hooks/jobStatus";
import DraggableJobStatusRow from "./DraggableJobStatusRow";
import { DragDropContext } from "react-beautiful-dnd";
import { StrictModeDroppable } from "./StrictModeDroppable";
import LottiePlayer from "../../Molecules/Lottie/Lottie";
import animationData from "../../../lotties/anim_not_found.json";

const reorderStatuses = (
  list: JobStatus[],
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex - 1, 1);
  result.splice(endIndex - 1, 0, removed);

  result.forEach((status, index) => {
    status.order = index + 1;
  });

  return result;
};

const JobStatusTable = () => {
  const { currentWorkspace } = useContext(AppContext);
  const [statuses, setStatuses] = useState<JobStatus[]>([]);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [editStatusId, setEditStatusId] = useState<number | null>(null);
  const [newStatusName, setNewStatusName] = useState<string>("");
  const [newStatusNameError, setNewStatusNameError] = useState<string>("");
  const [deleteStatus, setDeleteStatus] = useState<JobStatus | null>(null);

  const { t } = useTranslation();
  const { editJobStatus, isPendingEditJobStatus } = useEditJobStatus();
  const { deleteJobStatus, isPendingDeleteJobStatus } = useDeleteJobStatus();
  const { reorderJobStatuses, isPendingReorderJobStatuses } =
    useReorderJobStatus();

  useEffect(() => {
    setStatuses(currentWorkspace?.jobStatuses || []);
  }, [currentWorkspace]);

  const onEditClick = (status: JobStatus) => {
    if (status.id) {
      setShowEditModal(true);
      setEditStatusId(status.id);
      setNewStatusName(status?.name || "");
    }
  };

  const onDeleteClick = (status: JobStatus) => {
    setDeleteStatus(status);
    setShowDeleteModal(true);
  };

  const handleEditStatus = async () => {
    if (isPendingDeleteJobStatus || isPendingEditJobStatus) return;
    try {
      await jobStatusSchema.validate(newStatusName);
      if (currentWorkspace?.id && editStatusId) {
        await editJobStatus({
          name: newStatusName,
          workspaceId: currentWorkspace.id,
          jobStatusId: editStatusId,
        });
        setShowEditModal(false);
        setNewStatusName("");
        setNewStatusNameError("");
        setEditStatusId(null);
      }
    } catch (error: any) {
      error.errors.forEach((err: { field: string; translationKey: string }) => {
        setNewStatusNameError(t(err.translationKey));
      });
    }
  };

  const handleDeleteStatus = async () => {
    if (isPendingDeleteJobStatus || isPendingEditJobStatus) return;
    if (deleteStatus?.id && currentWorkspace?.id) {
      await deleteJobStatus(deleteStatus.id);
      setDeleteStatus(null);
      setShowDeleteModal(false);
    }
  };

  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }

    const items = reorderStatuses(
      statuses,
      result.source.index,
      result.destination.index
    );
    setStatuses(items);

    if (currentWorkspace?.id)
      await reorderJobStatuses({
        jobStatuses: items,
        workspaceId: currentWorkspace?.id,
      });
  };

  return (
    <>
      {!!statuses.length ? (
        <Table variant="status">
          <Thead>
            <Tr>
              <Th>{t("workspace.status")}</Th>
            </Tr>
          </Thead>
          <DragDropContext onDragEnd={onDragEnd}>
            <StrictModeDroppable droppableId="statuses">
              {(provided) => (
                <Tbody {...provided.droppableProps} ref={provided.innerRef}>
                  {statuses.map((status) => {
                    return (
                      <DraggableJobStatusRow
                        status={status}
                        iconsDisabled={
                          isPendingDeleteJobStatus ||
                          isPendingEditJobStatus ||
                          isPendingReorderJobStatuses
                        }
                        onEdit={onEditClick}
                        onDelete={onDeleteClick}
                        key={status.id?.toString()}
                      />
                    );
                  })}
                  {provided.placeholder}
                </Tbody>
              )}
            </StrictModeDroppable>
          </DragDropContext>
        </Table>
      ) : (
        <LottiePlayer
              message={t("workspace.noStatusesSet")}
              animationData={animationData}
            />
      )}
      {showEditModal && (
        <Modal
          onClick={() => handleEditStatus()}
          onClose={() => {
            setNewStatusName("");
            setNewStatusNameError("");
            setShowEditModal(false);
            setEditStatusId(null);
          }}
          isOpen={showEditModal}
          primaryLabel={t("common.cancel")}
          secondaryLabel={t("common.update")}
          variant="success"
          title={<Text>{t("workspace.editStatus")}</Text>}
          subtitle={t("workspace.editStatusDescription", {
            name: currentWorkspace?.name,
          })}
          imageSrc="/Edit.svg"
          disabled={false}
        >
          <Input
            label={t("workspace.statusName")}
            placeholder={t("workspace.statusNamePlaceholder")}
            onChange={(value) => {
              if (value && value.trim()) setNewStatusNameError("");
              setNewStatusName(value);
            }}
            value={newStatusName}
            formControlWidth={"100%"}
            labelStyles={{ marginTop: "32px", color: "black.darkText" }}
            errorMessage={newStatusNameError}
            isDisabled={false}
          />
        </Modal>
      )}
      {showDeleteModal && (
        <Modal
          onClick={() => handleDeleteStatus()}
          onClose={() => {
            setDeleteStatus(null);
            setShowDeleteModal(false);
          }}
          isOpen={showDeleteModal}
          primaryLabel={t("common.cancel")}
          secondaryLabel={t("common.delete")}
          variant="warning"
          title={<Text>{t("workspace.deleteStatus")}</Text>}
          subtitle={t("workspace.deleteStatusDescription", {
            name: deleteStatus?.name,
          })}
          imageSrc="/AlertErrorCheckmark.svg"
          disabled={false}
        ></Modal>
      )}
    </>
  );
};

export default JobStatusTable;
