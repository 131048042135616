import {
  Flex,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Tooltip,
  Icon,
  AvatarGroup,
} from "@chakra-ui/react";
import { Table } from "../../Atoms/Table/Table";
import { useTranslation } from "react-i18next";
import { useContext, useState } from "react";
import Modal from "../../Molecules/Modal/Modal";
import { useCheckIsMobile } from "../../../lib/utils";
import UserActions from "../Workspace/UserActions";
import { Job } from "../../../types/Job";
import { FaRegBell } from "react-icons/fa6";
import TooltipAvatar from "../../Molecules/TooltipAvatar/TooltipAvatar";
import { useNavigate } from "react-router-dom";
import { useDeleteJob } from "../../../hooks/jobs";
import { AppContext } from "../../../context/AppContext";
import { WorkspaceUserRoles } from "../../../types/workspace";

interface JobTableProps {
  jobs: Job[];
  onLastJob: () => void;
}

const JobTable = ({ jobs, onLastJob }: JobTableProps) => {
  const { t } = useTranslation();
  const isMobile = useCheckIsMobile();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const [jobToRemove, setJobToRemove] = useState<Job | null>(null);
  const { deleteJob, isPendingDeleteJob, invalidateQueries } = useDeleteJob();
  const { currentWorkspace } = useContext(AppContext);

  const canRemoveJob =
    currentWorkspace?.privileges?.canRemoveJob ||
    currentWorkspace?.role === WorkspaceUserRoles.ADMIN ||
    currentWorkspace?.role === WorkspaceUserRoles.OWNER;

  const handleDeleteJob = async (jobId: number) => {
    if (!canRemoveJob) return;
    const { isJobDeleted } = await deleteJob(jobId);
    if (isJobDeleted) {
      if (jobs.length > 1) invalidateQueries();
      else {
        onLastJob();
      }
    }
    setJobToRemove(null);
    setShowDeleteModal(false);
  };

  return (
    <>
      {isMobile ? (
        <Flex sx={{ flexDir: "column", w: "full", gap: "8px" }}>
          {jobs.map((job) => {
            return (
              <Flex
                key={job.id}
                sx={{
                  flexDir: "row",
                  width: "full",
                  p: "8px",
                  alignItems: "center",
                  borderRadius: "8px",
                }}
                boxShadow={"base"}
                border={"1px solid"}
                borderColor={"gray.light"}
              >
                <Flex
                  sx={{
                    flexDir: "column",
                    width: "full",
                    fontSize: "16px",
                    color: "gray.darker",
                    fontWeight: "bold",
                    flexGrow: 1,
                    overflow: "hidden",
                    gap: "8px",
                  }}
                >
                  <Text
                    sx={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {job.name}
                  </Text>
                  <Text
                    sx={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      fontSize: "12px",
                    }}
                  >
                    {job?.status?.name || ""}
                  </Text>
                  {!!job.assignedUsers?.length && (
                    <AvatarGroup max={3}>
                      {job.assignedUsers.map((assignedUser) => (
                        <TooltipAvatar
                          bg={"green.foundationLighter"}
                          color={"white"}
                          key={assignedUser.id}
                          name={
                            assignedUser.user.firstName +
                            " " +
                            assignedUser.user.lastName
                          }
                          src={assignedUser.user.filePath}
                        />
                      ))}
                    </AvatarGroup>
                  )}
                </Flex>
                <Flex sx={{ flexDir: "row" }}>
                  {job.hasReminder && (
                    <Tooltip
                      label={job.reminderText}
                      placement={"top-end"}
                      backgroundColor={"green.foundationLighter"}
                    >
                      <span>
                        <Icon
                          mr={"16px"}
                          as={FaRegBell}
                          height="20px"
                          width="20px"
                          color={"teal"}
                        />
                      </span>
                    </Tooltip>
                  )}
                  <UserActions
                    onEditClick={() => navigate(`/job/edit/${job.id}`)}
                    onRemoveClick={() => {
                      setJobToRemove(job);
                      setShowDeleteModal(true);
                    }}
                    isEditDisabled={isPendingDeleteJob}
                    isRemoveDisabled={isPendingDeleteJob || !canRemoveJob}
                    editToolTip={t("job.update")}
                    removeToolTip={t("job.delete")}
                  />
                </Flex>
              </Flex>
            );
          })}
        </Flex>
      ) : (
        <Table variant="jobs">
          <Thead>
            <Tr>
              <Th>{t("job.title")}</Th>
              <Th>{t("job.status")}</Th>
              <Th>{t("job.assignedTo")}</Th>
              <Th w={"100px !important"}>{""}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {jobs.map((job) => {
              return (
                <Tr key={Number(job.id)}>
                  <Td>
                    <Text>{job.name}</Text>
                  </Td>
                  <Td>
                    <Text>{job?.status?.name || ""}</Text>
                  </Td>
                  <Td>
                    {!!job.assignedUsers?.length && (
                      <AvatarGroup max={3}>
                        {job.assignedUsers.map((assignedUser) => (
                          <TooltipAvatar
                            bg={"green.foundationLighter"}
                            color={"white"}
                            key={assignedUser.id}
                            name={
                              assignedUser.user.firstName +
                              " " +
                              assignedUser.user.lastName
                            }
                            src={assignedUser.user.filePath}
                          />
                        ))}
                      </AvatarGroup>
                    )}
                  </Td>
                  <Td w={"100px !important"}>
                    <Flex sx={{ flexDir: "row" }}>
                      <UserActions
                        onEditClick={() => navigate(`/job/edit/${job.id}`)}
                        onRemoveClick={() => {
                          setJobToRemove(job);
                          setShowDeleteModal(true);
                        }}
                        isEditDisabled={false}
                        isRemoveDisabled={false}
                        editToolTip={t("job.update")}
                        removeToolTip={t("job.delete")}
                      />
                      {job.hasReminder && (
                        <Tooltip
                          label={job.reminderText}
                          placement={"top-end"}
                          backgroundColor={"green.foundationLighter"}
                        >
                          <span>
                            <Icon
                              as={FaRegBell}
                              height="20px"
                              width="20px"
                              color={"teal"}
                            />
                          </span>
                        </Tooltip>
                      )}
                    </Flex>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      )}
      {showDeleteModal && (
        <Modal
          onClick={() => {
            !!jobToRemove?.id && handleDeleteJob(jobToRemove?.id);
          }}
          onClose={() => {
            setShowDeleteModal(false);
            setJobToRemove(null);
          }}
          isOpen={showDeleteModal}
          primaryLabel={t("common.cancel")}
          secondaryLabel={t("common.remove")}
          variant="warning"
          title={<Text>{t("job.delete")}</Text>}
          subtitle={t("job.removeWarning", { name: jobToRemove?.name })}
          imageSrc="/AlertErrorCheckmark.svg"
          disabled={false}
        ></Modal>
      )}
    </>
  );
};
export default JobTable;
