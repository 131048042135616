/* eslint-disable react-hooks/exhaustive-deps */
import { Flex, Spinner, Image, Text } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAcceptInvitation } from "../hooks/workspace";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../components/Atoms/Button/Button";
import { errorCodeToTranslationMapper } from "../mappers/ErrorCodeMapper";

export const InvitationAcceptedPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const [data, setData] = useState<any>(undefined);
  const [errorCode, setErrorCode] = useState<number | undefined>(undefined);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { acceptInvitation, status, isPendingAcceptInvitation } =
    useAcceptInvitation();

  useEffect(() => {
    async function fetchData() {
      if (token) {
        const res = await acceptInvitation({ token: token });
        setData(res.data);
        setErrorCode(res.errorCode);
      }
    }
    fetchData();
  }, [token]);

  return (
    <Flex
      sx={{
        width: "100%",
        minH: "100vh",
        justifyContent: "center",
        alignItems: "center",
        flexDir: "column",
        backgroundColor: "gray.light",
        p: "48px",
        textAlign: "center",
      }}
    >
      <Image src={"/Logo3.svg"} width={"255px"} alt="Logo" />
      <Flex
        sx={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
          flexDir: "column",
        }}
      >
        {isPendingAcceptInvitation && (
          <Spinner
            thickness="8px"
            speed="1.45s"
            emptyColor="gray.200"
            color="green.foundationNormal"
            size="xl"
          />
        )}
        {status === "success" && (
          <Flex
            sx={{
              flexDir: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
              borderRadius: "20px",
              p: "20px",
              width: ["400px"],
            }}
          >
            <Image
              src={
                !errorCode
                  ? "/CheckIconChecked.svg"
                  : "/AlertErrorCheckmark.svg"
              }
              width={"70px"}
              alt="Logo"
            />
            <Text
              sx={{
                color: "green.foundationNormal",
                fontSize: "18px",
                fontWeight: "500",
                my: "20px",
              }}
            >
              {errorCode && (
                <Text color={"red.delete"}>
                  <Text fontWeight={"bold"}>
                    {t(errorCodeToTranslationMapper[errorCode])}{" "}
                    {t("errors.ERROR_CODE", { code: errorCode })}
                  </Text>
                  {t("users.inviteFail")}
                </Text>
              )}
              {!errorCode && t("users.inviteAccepted")}
              <Text as="span" fontWeight={"bold"}>
                {!errorCode && data?.workspace?.name}
              </Text>
            </Text>
            <Button
              sx={{
                mt: "40px",
                background: errorCode ? "red.delete" : "green.foundationDark",
              }}
              onClick={() => {
                navigate("/login");
              }}
            >
              {t("login.backToLogin")}
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
