import { useTranslation } from "react-i18next";
import { Heading } from "../../Atoms/Heading/Heading";
import { Input } from "../../Atoms/Input/Input";
import { useState } from "react";
import { Workspace } from "../../../types/workspace";
import { FileUpload } from "../../Atoms/FileUpload/FileUpload";
import { Button } from "../../Atoms/Button/Button";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Flex, Text } from "@chakra-ui/react";

type StepWorkspaceSetupProps = {
  workspaceData: Workspace;
  isLoading?: boolean;
  onNext: (data: Workspace) => void;
  onSkip: () => void;
};

export const StepWorkspaceSetup = ({
  isLoading,
  workspaceData,
  onNext,
  onSkip,
}: StepWorkspaceSetupProps) => {
  const { t } = useTranslation();
  const [workspaceName, setWorkspaceName] = useState<string>("");
  const [workspaceImage, setWorkspaceImage] = useState<File | undefined>(
    undefined
  );

  return (
    <>
      <Heading sx={{ mb: "40px", maxWidth: "420px" }}>
        {t("register.workspaceTitle")}
      </Heading>
      <Input
        placeholder={t("workspace.nameInputPlaceholder")}
        label={t("workspace.nameInputTitle")}
        size="md"
        onChange={(value) => {
          setWorkspaceName(value);
        }}
        value={workspaceName}
      />

      <FileUpload
        label={t("workspace.imageInputTitle")}
        onChange={(files) => setWorkspaceImage(files[0])}
      />
      <Text
        sx={{
          width: ["100%", "420px"],
          color: "gray.darker",
          marginTop: "40px",
          marginBottom: "5px",
          fontSize: "12px",
          fontStyle: "italic",
          fontWeight: "bold",
        }}
      >
        {t("common.skipInfo")}
      </Text>
      <Flex
        sx={{
          width: "100%",
          flexDir: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button variant="secondary" onClick={onSkip} isDisabled={isLoading}>
          {t("common.skip")}
        </Button>
        <Button
          variant="primary"
          rightIcon={<ArrowForwardIcon />}
          onClick={() => onNext({ name: workspaceName, image: workspaceImage })}
          isDisabled={isLoading}
        >
          {t("common.next")}
        </Button>
      </Flex>
    </>
  );
};
