import { Flex, Text, Tooltip } from "@chakra-ui/react";
import { AppContext } from "../../../context/AppContext";
import { useContext } from "react";
import { IconType } from "react-icons";

interface NavItemProps {
  label: string;
  isActive: boolean;
  Icon: IconType;
  onClick: () => void;
}

const NavItem = ({ label, isActive, Icon, onClick }: NavItemProps) => {
  const { isSidebarOpen } = useContext(AppContext);
  const iconColor = isActive ? "#A7EDF1" : "white";
  return (
    <Tooltip
      label={!isSidebarOpen && label}
      placement="left"
      backgroundColor="green.foundationLighter"
    >
      <Flex
        sx={{
          width: "full",
          height: "44px",
          backgroundColor: isActive ? "green.foundationLighter" : "unset",
          borderRadius: "6px",
          my: "8px",
          flexDir: "row",
          alignItems: "center",
          p: "12px",
          cursor: "pointer",
          _hover: {
            backgroundColor: "green.foundationLighter",
          },
        }}
        onClick={() => onClick()}
      >
        <Icon size={"22px"} color={iconColor} />

        {isSidebarOpen && (
          <Text
            sx={{
              ml: "14px",
              color: isActive ? "green.foundationLightActive" : "white",
              fontSize: "14px",
              fontWeight: "bold",
            }}
          >
            {label}
          </Text>
        )}
      </Flex>
    </Tooltip>
  );
};

export default NavItem;
