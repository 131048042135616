import { useContext, useState } from "react";
import RegisterLayout from "../layouts/RegisterLayout";
import { RegistrationStepsEnum } from "../types/Registration";
import { StepBasicInfo } from "../components/Organisms/Registration/StepBasicInfo";
import { StepPassword } from "../components/Organisms/Registration/StepPassword";
import { User } from "../types/user";
import {
  useCheckUserEmail,
  useRegisterUser,
  useResendVerificationEmailToUser,
  useVerifyEmail,
} from "../hooks/user";
import { StepConfirmEmail } from "../components/Organisms/Registration/StepConfirmEmail";
import { AppContext } from "../context/AppContext";
import { StepWorkspaceSetup } from "../components/Organisms/Registration/StepWorkspaceSetup";
import { Workspace } from "../types/workspace";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCreateWorkspace } from "../hooks/workspace";

export const RegisterPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const stepQuery = searchParams.get("step");
  const [step, setStep] = useState(
    Number(stepQuery) || RegistrationStepsEnum.BASIC_INFO
  );
  const [userData, setUserData] = useState<User>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });
  const [workspaceData, setWorkspaceData] = useState<Workspace>({
    name: "",
  });

  const { registerUser, isPendingRegistration } = useRegisterUser();
  const { checkUserEmail, isPendingEmailValidation } = useCheckUserEmail();
  const { resendVerificationEmailToUser, isPendingResendEmail } =
    useResendVerificationEmailToUser();
  const { verifyUserEmail, isPendingEmailVerification } = useVerifyEmail();
  const { createWorkspace, isPendingCreateWorkspace } = useCreateWorkspace();

  const { user } = useContext(AppContext);

  return (
    <RegisterLayout currentStep={step}>
      {step === RegistrationStepsEnum.BASIC_INFO && (
        <StepBasicInfo
          onNext={async (userData: User) => {
            const { isValidEmail } = await checkUserEmail(userData.email);
            if (isValidEmail) {
              setUserData(userData);
              setStep(RegistrationStepsEnum.PASSWORD);
            }
          }}
          userData={userData}
          isLoading={isPendingEmailValidation}
        />
      )}
      {step === RegistrationStepsEnum.PASSWORD && (
        <StepPassword
          onNext={async (password: string) => {
            const { user } = await registerUser({ ...userData, password });
            if (user?.id) setStep(RegistrationStepsEnum.CONFIRM_EMAIL);
          }}
          onBack={() => {
            setStep(RegistrationStepsEnum.BASIC_INFO);
          }}
          isLoading={isPendingRegistration}
        />
      )}
      {step === RegistrationStepsEnum.CONFIRM_EMAIL && (
        <StepConfirmEmail
          onConfirm={async (code) => {
            if (!user?.id) return;
            const { isVerified } = await verifyUserEmail({
              userId: user?.id,
              verificationCode: Number(code),
            });
            if (isVerified) setStep(RegistrationStepsEnum.WORKSPACE_SETUP);
          }}
          onResend={async () => {
            user?.id && (await resendVerificationEmailToUser(user.id));
          }}
          isLoading={isPendingResendEmail || isPendingEmailVerification}
        />
      )}
      {step === RegistrationStepsEnum.WORKSPACE_SETUP && (
        <StepWorkspaceSetup
          workspaceData={workspaceData}
          onNext={async (data) => {
            setWorkspaceData(data);
            const formData = new FormData();
            formData.append("name", data.name);
            if (data.image) formData.append("files", data.image);
            const { workspace } = await createWorkspace(formData);
            console.log(workspace);
          }}
          onSkip={() => {
            navigate("/profile");
          }}
          isLoading={isPendingCreateWorkspace}
        />
      )}
    </RegisterLayout>
  );
};
