import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useCheckIsMobile } from "../../../lib/utils";

export type PhoneNumberInputProps = {
  onChange?: (
    value: string,
    country: any,
    e: any,
    formattedValue: string
  ) => void;
  value: string;
  label?: string;
  errorMessage?: string;
  placeholder?: string;
  isDisabled?: boolean;
};

export const PhoneNumberInput = ({
  onChange,
  value,
  label,
  errorMessage,
  placeholder,
  isDisabled = false,
}: PhoneNumberInputProps) => {
  const isMobile = useCheckIsMobile();

  return (
    <FormControl width={isMobile ? "100%" : "420px"} isInvalid={!!errorMessage}>
      {label && (
        <FormLabel
          color={"gray.darker"}
          sx={{
            color: "gray.darker",
            marginBottom: "6px",
            fontSize: "14px",
            fontWeight: 600,
          }}
        >
          {label}
        </FormLabel>
      )}

      <PhoneInput
        country={"hr"}
        value={value}
        preferredCountries={["hr"]}
        countryCodeEditable={false}
        onChange={onChange && onChange}
        placeholder={placeholder}
        autoFormat={false}
        inputStyle={{
          width: "100%",
          height: "40px",
          boxShadow:
            "0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06)",
          outline: "2px solid transparent",
          fontSize: "1rem",
          border: !!errorMessage ? "2px solid #E53E3E" : "inherit",
          opacity: isDisabled ? "0.5" : 1,
        }}
        buttonStyle={{
          backgroundColor: "white",
          borderTop: !!errorMessage ? "2px solid #E53E3E" : "inherit",
          borderLeft: !!errorMessage ? "2px solid #E53E3E" : "inherit",
          borderBottom: !!errorMessage ? "2px solid #E53E3E" : "inherit",
          borderRight: "inherit",
          borderTopLeftRadius: "0.375rem",
          borderBottomLeftRadius: "0.375rem",
          opacity: isDisabled ? "0.5" : 1,
        }}
        disabled={isDisabled}
        disableCountryCode={isDisabled}
      />
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
