export enum LabelType {
  singleLine = "singleLine",
  textBox = "textBox",
}

export interface JobLabel {
  id?: number;
  jobId?: number;
  labelId?: number;
  text?: string;
  label?: Label;
}

export interface Label {
  id?: number;
  name?: string;
  workspaceId?: number;
  type?: LabelType;
  order?: number;
}
