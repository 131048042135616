import { handleErrorResponse } from "../lib/utils";
import { User } from "../types/user";
import { Workspace } from "../types/workspace";
import {
  del,
  postDataWithFormData,
  putDataWithFormData,
  get,
  post,
} from "./api";

export const createWorkspaceMutate = async (
  formData: FormData
): Promise<{ workspace?: Workspace; errorCode?: number; error?: string }> => {
  const url = `/workspace`;

  try {
    const res = await postDataWithFormData(url, formData);
    if (res.data) return { workspace: res.data.workspace };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const updateWorkspaceMutate = async (data: {
  formData: FormData;
  workspaceId: number;
}): Promise<{ workspace?: Workspace; errorCode?: number; error?: string }> => {
  const url = `/workspace/${data.workspaceId}`;

  try {
    const res = await putDataWithFormData(url, data.formData);
    if (res.data) return { workspace: res.data.workspace };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const deleteWorkspaceMutate = async (
  workspaceId: number
): Promise<{
  isWorkspaceDeleted?: boolean;
  errorCode?: number;
  error?: string;
}> => {
  const url = `/workspace/${workspaceId}`;

  try {
    const res = await del(url);
    if (res.data) return { isWorkspaceDeleted: res.data.isWorkspaceDeleted };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const getWorkspaceUsersQuery = async ({
  workspaceId,
  filterString,
  roleFilter,
  page,
}: {
  workspaceId?: number;
  filterString?: string;
  roleFilter?: string;
  page?: number;
}): Promise<{
  users?: User[];
  isLastPage?: boolean;
  userCount?: number;
  filteredUsersCount?: number;
  errorCode?: number;
  error?: string;
}> => {
  let url = `/workspace/${workspaceId}/users?page=${page ? page : 1}`;

  if (filterString)
    url = url + `&filterString=${encodeURIComponent(filterString)}`;
  if (roleFilter) url = url + `&roleFilter=${roleFilter}`;
  try {
    const res = await get(url);
    if (res.data) return { ...res.data };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const removeUserFromWorkspaceMutate = async ({
  workspaceId,
  removedUserId,
}: {
  workspaceId: number;
  removedUserId: number;
}): Promise<{
  isUserRemoved?: boolean;
  errorCode?: number;
  error?: string;
}> => {
  const url = `/workspace/${workspaceId}/users/${removedUserId}`;

  try {
    const res = await del(url);
    if (res.data) return { isUserRemoved: res.data.isUserRemoved };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const leaveWorkspaceMutate = async ({
  workspaceId,
}: {
  workspaceId: number;
}): Promise<{
  isLeavingSuccessful?: boolean;
  errorCode?: number;
  error?: string;
}> => {
  const url = `/workspace/${workspaceId}/leave-workspace`;

  try {
    const res = await del(url);
    if (res.data) return { isLeavingSuccessful: res.data.isLeavingSuccessful };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const getUserDataMutate = async ({
  workspaceId,
  userId,
}: {
  workspaceId?: number;
  userId?: number;
}): Promise<{
  user?: User;
  errorCode?: number;
  error?: string;
}> => {
  const url = `/workspace/${workspaceId}/users/${userId}`;

  try {
    const res = await get(url);
    if (res.data) return { user: res.data.user };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const inviteUserToWorkspaceMutate = async ({
  workspaceId,
  userId,
  role,
}: {
  workspaceId: number;
  userId: number;
  role: string;
}): Promise<{
  isEmailSent?: boolean;
  errorCode?: number;
  error?: string;
}> => {
  const url = `/workspace/invite-user`;

  try {
    const res = await post(url, { workspaceId, id: userId, role });
    if (res.data) return { isEmailSent: res.data.isEmailSent };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const acceptInvitationMutate = async ({
  token,
}: {
  token: string;
}): Promise<{
  data?: {
    user: {
      firstName: string;
      lastName: string;
      imageUrl: string;
    };
    workspace: {
      name: string;
      imageUrl: string;
    };
  };
  errorCode?: number;
  error?: string;
}> => {
  const url = `/workspace/accept-invitation`;

  try {
    const res = await post(url, undefined, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.data) return { data: res.data.data };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const getAllWorkspacesQuery = async ({
  filter,
  role,
  page,
}: {
  filter?: string;
  role?: string;
  page?: number;
}): Promise<{
  workspaces?: Workspace[];
  isLastPage?: boolean;
  filteredWorkspacesCount?: number;
  allWorkspacesCount?: number;
  errorCode?: number;
  error?: string;
}> => {
  let url = `/workspace?page=${page ? page : 1}`;

  if (filter) url = url + `&filter=${encodeURIComponent(filter)}`;
  if (role) url = url + `&role=${role}`;
  try {
    const res = await get(url);
    if (res.data) return { ...res.data };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};
