import { Avatar, Tooltip } from "@chakra-ui/react";

const TooltipAvatar: typeof Avatar = (props: any) => {
  return (
    <Tooltip
      label={props.name}
      placement="top"
      backgroundColor={"green.foundationLighter"}
    >
      <Avatar
        {...props}
        size={"sm"}
        sx={{ height: "40px !important", width: "40px !important" }}
      />
    </Tooltip>
  );
};

export default TooltipAvatar;
