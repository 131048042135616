/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Flex, Heading } from "@chakra-ui/react";
import BaseLayout from "../layouts/BaseLayout";
import SectionHeading from "../components/Molecules/Common/SectionHeading";
import SectionItem from "../components/Molecules/Common/SectionItem";
import { Input } from "../components/Atoms/Input/Input";
import { MdOutlineMailOutline } from "react-icons/md";
import { PhoneNumberInput } from "../components/Atoms/PhoneNumberInput/PhoneNumberInput";
import Select, { Option } from "../components/Molecules/Select/Select";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useGetUserDetails } from "../hooks/workspace";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import { WorkspaceUserRoles } from "../types/workspace";
import { useEditUserRole } from "../hooks/userRole";
import UserPrivileges from "../components/Organisms/Users/UserPrivileges";
import {
  PrivilegesEnum,
  UserPrivileges as UserPrivilegesType,
} from "../types/Privileges";
import { useUpdateUserPrivileges } from "../hooks/privileges.";

const UserDetailPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { currentWorkspace, user: currentUser } = useContext(AppContext);
  const { editUserRole, isPendingEditUserRole } = useEditUserRole();
  const { updateUserPrivileges, isPendingUpdateUserPrivileges } =
    useUpdateUserPrivileges();

  const navigate = useNavigate();

  const { user, isFetchingUserData } = useGetUserDetails({
    userId: Number(id),
    workspaceId: currentWorkspace?.id,
  });

  const [privileges, setPrivileges] = useState<UserPrivilegesType | undefined>(
    undefined
  );

  const userTypeOptions = [
    {
      value: "owner",
      label: t("workspace.owner"),
      disabled: true,
    },
    {
      value: "admin",
      label: t("workspace.admin"),
    },
    {
      value: "basicUser",
      label: t("workspace.basicUser"),
    },
  ];

  const [selectedOption, setSelectedOption] = useState<Option | undefined>(
    undefined
  );

  useEffect(() => {
    if (currentUser?.id?.toString() === id) navigate("/profile");
    if (user?.role)
      setSelectedOption(
        userTypeOptions.filter((o) => o.value === user.role)[0]
      );
    if (user?.privileges) {
      setPrivileges(user.privileges);
    }
  }, [user]);

  const handleChangeUserRole = async (option: Option | null) => {
    if (option && !option.disabled) {
      setSelectedOption(option);
    }
  };

  const isChangePrivilegeDisabled = () => {
    if (!user?.privileges || !privileges) return true;
    const isDisabled = Object.values(PrivilegesEnum).map(
      (privilege: PrivilegesEnum) => {
        return !!privileges[privilege] === !!user?.privileges?.[privilege];
      }
    );

    return !isDisabled.some((e) => e === false);
  };

  const handleUpdateUserPrivileges = async () => {
    if (!currentWorkspace?.id || !user?.id || !privileges) return;
    await updateUserPrivileges({
      workspaceId: currentWorkspace?.id,
      updatedUserId: user?.id,
      privileges,
    });
  };

  return (
    <BaseLayout>
      <Flex
        sx={{
          p: "48px",
          flexDir: "column",
          width: "100%",
          gap: "32px",
          fontSize: "32px",
          alignItems: "center",
        }}
      >
        <Heading sx={{ color: "black.title" }}>
          {t("users.userDetails")}
        </Heading>

        <Flex sx={{ flexDir: "column", width: "100%", gap: "24px" }}>
          <SectionHeading
            title={t("profile.personalDetails")}
            subtitle={t("users.userDetailsDescription")}
          />
          <SectionItem
            label={t("profile.firstName")}
            sx={{
              flexDir: ["column", "column", "row"],
              gap: "20px",
            }}
          >
            <Input
              formControlWidth={"full"}
              sx={{ margin: 0, maxW: "420px" }}
              value={user?.firstName || ""}
              isDisabled={true}
            />
            <Input
              formControlWidth={"full"}
              sx={{ margin: 0, maxW: "420px" }}
              value={user?.lastName || ""}
              isDisabled={true}
            />
          </SectionItem>
          <SectionItem label={t("profile.email")}>
            <Input
              sx={{ margin: 0 }}
              formControlWidth={["full", "full", "420px"]}
              isDisabled={true}
              LeftIcon={MdOutlineMailOutline}
              value={user?.email || ""}
            />
          </SectionItem>
          <SectionItem label={t("profile.phoneNumber")}>
            <PhoneNumberInput
              value={user?.phoneNumber || ""}
              isDisabled={true}
              placeholder=""
            />
          </SectionItem>
          <SectionItem label={t("profile.profileImage")} sx={{ w: "100%" }}>
            <Flex
              sx={{
                w: "100%",
                gap: "20px",
                flexDir: ["column", "column", "row"],
                alignItems: ["center", "center", "flex-start"],
              }}
            >
              <Avatar
                w={"64px"}
                h={"64px"}
                name={user?.firstName + " " + user?.lastName}
                src={user?.filePath || ""}
                color={"white"}
              />
            </Flex>
          </SectionItem>
          <SectionHeading
            title={t("profile.role")}
            subtitle={t("users.userTypeDescription")}
            primaryButtonLabel={t("common.update")}
            secondaryButtonLabel={t("common.cancel")}
            disabled={
              isFetchingUserData ||
              user?.role === WorkspaceUserRoles.OWNER ||
              user?.role === selectedOption?.value ||
              isPendingEditUserRole ||
              isPendingUpdateUserPrivileges
            }
            onClickSecondary={() => {
              setSelectedOption(
                userTypeOptions.filter((o) => o.value === user?.role)[0]
              );
            }}
            onClickPrimary={async () => {
              if (
                selectedOption &&
                !selectedOption.disabled &&
                currentWorkspace?.id &&
                id &&
                selectedOption.value
              ) {
                await editUserRole({
                  workspaceId: currentWorkspace?.id,
                  updatedUserId: Number(id),
                  role: selectedOption.value,
                });
              }
            }}
          />
          <SectionItem label={t("profile.role")} sx={{ w: "100%" }}>
            <Flex sx={{ width: ["full", "full", "420px"] }}>
              <Select
                isMulti={false}
                name="userTypes"
                theme="default"
                options={userTypeOptions}
                withImages={false}
                defaultOption={selectedOption}
                onChange={handleChangeUserRole}
                isDisabled={
                  isFetchingUserData ||
                  user?.role === WorkspaceUserRoles.OWNER ||
                  isPendingEditUserRole ||
                  isPendingUpdateUserPrivileges
                }
                isSearchable={false}
              />
            </Flex>
          </SectionItem>
          {user?.role === WorkspaceUserRoles.BASIC_USER && (
            <>
              <SectionHeading
                title={t("privileges.title")}
                subtitle={t("privileges.subtitle")}
                primaryButtonLabel={t("common.update")}
                secondaryButtonLabel={t("common.cancel")}
                disabled={
                  isFetchingUserData ||
                  isPendingEditUserRole ||
                  isChangePrivilegeDisabled() ||
                  isPendingUpdateUserPrivileges
                }
                onClickPrimary={() => handleUpdateUserPrivileges()}
                onClickSecondary={() => {
                  setPrivileges({ ...user?.privileges });
                }}
              />
              <UserPrivileges
                privileges={privileges || {}}
                onChange={(privilege: PrivilegesEnum, value) => {
                  const newPrivileges = { ...privileges };

                  if (newPrivileges) {
                    newPrivileges[privilege] = value;
                    setPrivileges({ ...newPrivileges });
                  }
                }}
                isDisabled={
                  isFetchingUserData ||
                  isPendingEditUserRole ||
                  isPendingUpdateUserPrivileges
                }
              />
            </>
          )}
        </Flex>
      </Flex>
    </BaseLayout>
  );
};

export default UserDetailPage;
