import { useTranslation } from "react-i18next";
import { Heading } from "../components/Atoms/Heading/Heading";
import BaseLayout from "../layouts/BaseLayout";
import { useState } from "react";
import { Flex } from "@chakra-ui/react";
import TabContainer from "../components/Molecules/Tab/TabContainer";
import TabItem from "../components/Molecules/Tab/TabItem";
import WorkspaceDetailsTab from "../components/Organisms/Workspace/WorkspaceDetailsTab";
import JobStatusesTab from "../components/Organisms/Workspace/JobStatusesTab";
import JobInputsTab from "../components/Organisms/Workspace/LabelsTab";

enum WorkspaceTabs {
  WORKSPACE_DETAILS = "workspaceDetails",
  BILLING_INFORMATION = "billingInformation",
  JOB_STATUSES = "jobStatuses",
  JOB_INPUTS = "jobInputs",
}

const WorkspacePage = () => {
  const [currentTab, setCurrentTab] = useState<WorkspaceTabs>(
    WorkspaceTabs.WORKSPACE_DETAILS
  );
  const { t } = useTranslation();
  return (
    <BaseLayout>
      <Flex
        sx={{
          p: "48px",
          flexDir: "column",
          width: "100%",
          gap: "32px",
          fontSize: "32px",
          alignItems: "center",
        }}
      >
        <Heading sx={{ color: "black.title" }}>
          {t("workspace.workspaceOptions")}
        </Heading>
        <TabContainer>
          <TabItem
            isActive={currentTab === WorkspaceTabs.WORKSPACE_DETAILS}
            label={t("workspace.workspaceDetails")}
            onClick={() => setCurrentTab(WorkspaceTabs.WORKSPACE_DETAILS)}
          />
          <TabItem
            isActive={currentTab === WorkspaceTabs.BILLING_INFORMATION}
            label={t("workspace.billingInformation")}
            onClick={() => setCurrentTab(WorkspaceTabs.BILLING_INFORMATION)}
          />
          <TabItem
            isActive={currentTab === WorkspaceTabs.JOB_STATUSES}
            label={t("workspace.jobStatuses")}
            onClick={() => setCurrentTab(WorkspaceTabs.JOB_STATUSES)}
          />
          <TabItem
            isActive={currentTab === WorkspaceTabs.JOB_INPUTS}
            label={t("workspace.jobInputs")}
            onClick={() => setCurrentTab(WorkspaceTabs.JOB_INPUTS)}
          />
        </TabContainer>
        {currentTab === WorkspaceTabs.WORKSPACE_DETAILS && (
          <WorkspaceDetailsTab />
        )}
        {currentTab === WorkspaceTabs.JOB_STATUSES && <JobStatusesTab />}
        {currentTab === WorkspaceTabs.JOB_INPUTS && <JobInputsTab />}
      </Flex>
    </BaseLayout>
  );
};

export default WorkspacePage;
