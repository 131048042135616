import { Avatar, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import SectionHeading from "../../Molecules/Common/SectionHeading";
import SectionItem from "../../Molecules/Common/SectionItem";
import { FileUpload } from "../../Atoms/FileUpload/FileUpload";
import { Input } from "../../Atoms/Input/Input";
import { HiOfficeBuilding } from "react-icons/hi";
import { AppContext } from "../../../context/AppContext";
import { useContext, useEffect, useState } from "react";
import {
  useDeleteWorkspace,
  useUpdateWorkspace,
} from "../../../hooks/workspace";
import { object, string } from "yup";
import { Workspace, WorkspaceUserRoles } from "../../../types/workspace";
import Modal from "../../Molecules/Modal/Modal";

export let workspaceSchema = object({
  name: string().required({
    field: "name",
    translationKey: "validation.requiredFiled",
  }),
});

const WorkspaceDetailsTab = () => {
  const { t } = useTranslation();
  const { currentWorkspace } = useContext(AppContext);
  const { updateWorkspace, isPendingUpdateWorkspace } = useUpdateWorkspace();
  const { deleteWorkspace, isPendingDeleteWorkspace } = useDeleteWorkspace();

  const [newFile, setNewFile] = useState<File | null>(null);
  const [newFileUrl, setNewFileUrl] = useState<string | undefined>(undefined);
  const [form, setForm] = useState<Workspace>(
    currentWorkspace || { name: "", imageUrl: "" }
  );
  const [formErrors, setFormErrors] = useState<Workspace>({ name: "" });
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (currentWorkspace) {
      setForm(currentWorkspace);
      setFormErrors({ name: "" });
    }
  }, [currentWorkspace]);

  const handleWorkspaceUpdate = async () => {
    try {
      await workspaceSchema.validate(form, { abortEarly: false });
      const formData = new FormData();
      formData.append("name", form.name);
      if (newFile) formData.append("files", newFile);
      if (currentWorkspace?.id) {
        await updateWorkspace({ formData, workspaceId: currentWorkspace?.id });
      }
      setNewFile(null);
      setNewFileUrl(undefined);
    } catch (error: any) {
      let errors: any = {};
      if (error.errors.length) {
        error.errors.forEach(
          (err: { field: string; translationKey: string }) => {
            errors[err.field as keyof typeof errors] = t(err.translationKey);
          }
        );
        setFormErrors(errors);
      }
    }
  };

  const handleDeleteModal = async () => {
    if (currentWorkspace?.id) {
      await deleteWorkspace(currentWorkspace?.id);
    }
    setShowModal(false);
  };

  return (
    <Flex sx={{ flexDir: "column", width: "100%", gap: "24px" }}>
      <SectionHeading
        title={t("workspace.workspaceOptions")}
        subtitle={t("workspace.workspaceDetailsDescription")}
        primaryButtonLabel={t("common.update")}
        secondaryButtonLabel={t("common.cancel")}
        onClickPrimary={() => handleWorkspaceUpdate()}
        disabled={
          isPendingUpdateWorkspace ||
          isPendingDeleteWorkspace ||
          (!newFile && form.name === currentWorkspace?.name)
        }
      />
      <SectionItem label={t("workspace.nameInputTitle")}>
        <Input
          placeholder={t("workspace.nameInputPlaceholder")}
          value={form.name}
          onChange={(value) => {
            setForm({ ...form, name: value });
            if (value) setFormErrors({ ...formErrors, name: "" });
          }}
          errorMessage={formErrors.name}
          isDisabled={isPendingUpdateWorkspace}
        />
      </SectionItem>
      <SectionItem label={t("workspace.workspaceImage")} sx={{ w: "100%" }}>
        <Flex
          sx={{
            w: "100%",
            gap: "20px",
            flexDir: ["column", "column", "row"],
            alignItems: ["center", "center", "flex-start"],
          }}
        >
          <Avatar
            w={"64px"}
            h={"64px"}
            color={"white"}
            icon={<HiOfficeBuilding size={"35px"} />}
            src={!!newFileUrl ? newFileUrl : form.imageUrl}
          />
          <FileUpload
            onChange={(files) => {
              setNewFile(files[0]);
              setNewFileUrl(URL.createObjectURL(files[0]));
            }}
            showFileName={false}
            sx={{ width: ["full", "380px"] }}
          />
        </Flex>
      </SectionItem>
      <SectionHeading
        title={t("workspace.delete")}
        subtitle={t("workspace.deleteDescription")}
        primaryButtonLabel={t("common.delete")}
        primaryButtonSX={{ backgroundColor: "red.delete", minW: "100px" }}
        onClickPrimary={() => setShowModal(true)}
        disabled={
          isPendingUpdateWorkspace ||
          isPendingDeleteWorkspace ||
          currentWorkspace?.role !== WorkspaceUserRoles.OWNER
        }
      />
      {showModal && (
        <Modal
          onClick={() => handleDeleteModal()}
          onClose={() => {
            setShowModal(false);
          }}
          isOpen={showModal}
          primaryLabel={t("common.cancel")}
          secondaryLabel={t("common.delete")}
          variant="warning"
          title={
            <Text>
              {t("workspace.deleteModal")}
              <span>{currentWorkspace?.name}</span>
            </Text>
          }
          subtitle={t("workspace.deleteModalSubtitle", {
            name: currentWorkspace?.name,
          })}
          imageSrc="/AlertErrorCheckmark.svg"
        ></Modal>
      )}
    </Flex>
  );
};

export default WorkspaceDetailsTab;
