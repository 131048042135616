import { defineStyleConfig } from "@chakra-ui/react";

export const Input = defineStyleConfig({
  baseStyle: {},

  sizes: {},

  variants: {},

  defaultProps: {},
});
