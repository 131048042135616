import { Center } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface CountBoxProps {
  count: number;
}

const CountBox = ({ count }: CountBoxProps) => {
  const { t } = useTranslation();
  return (
    <Center
      sx={{
        color: "green.foundationNormal",
        fontSize: "14px",
        padding: "12px",
        height: "32px",
        border: "1px solid",
        borderColor: "#CDECEA",
        borderRadius: "8px",
      }}
    >
      {t("common.count", { count })}
    </Center>
  );
};

export default CountBox;
