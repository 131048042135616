import { User } from "../types/user";
import { get, post, put, putDataWithFormData } from "./api";
import { handleErrorResponse } from "../lib/utils";
import Cookies from "js-cookie";
import { Workspace } from "../types/workspace";

export const registerUserMutate = async (
  userData: User
): Promise<{ user?: User; errorCode?: number; error?: string }> => {
  const url = `/user`;

  try {
    const res = await post(url, userData);
    if (res.data) return { user: res.data.user };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const checkUserEmailMutate = async (
  email: string
): Promise<{ isValidEmail?: string; errorCode?: number; error?: string }> => {
  const url = `/user/check-email`;

  try {
    const res = await post(url, { email });
    if (res.data) return { isValidEmail: res.data.isValidEmail };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const resendVerificationEmailToUserMutate = async (
  userId: number
): Promise<{
  isEmailSent?: boolean | undefined;
  errorCode?: number;
  error?: string;
}> => {
  const url = `/user/resend-email`;

  try {
    const res = await post(url, { userId });
    if (res.data) return { isEmailSent: res.data.isEmailSent };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const verifyUserEmailMutate = async ({
  userId,
  verificationCode,
}: {
  userId: number;
  verificationCode: number;
}): Promise<{
  isVerified?: boolean;
  errorCode?: number;
  error?: string;
}> => {
  const url = `/user/verify-email/web`;

  try {
    const res = await post(url, { userId, verificationCode });
    if (res.data) {
      Cookies.set("acc_tkn", `${res.data.accessToken}`);
      Cookies.set("ref_tkn", `${res.data.refreshToken}`);
      return { isVerified: res.data.isVerified };
    } else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const LoginUserMutate = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<{
  user?: User;
  errorCode?: number;
  error?: string;
}> => {
  const url = `/user/login`;

  try {
    const res = await post(url, { email, password });
    if (res.data) {
      if (res.data.accessToken && res.data.refreshToken) {
        Cookies.set("acc_tkn", `${res.data.accessToken}`);
        Cookies.set("ref_tkn", `${res.data.refreshToken}`);
      }
      return { user: res.data.user };
    } else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const sendForgotPasswordEmailMutate = async (
  email: string
): Promise<{
  isEmailSent?: boolean | undefined;
  errorCode?: number;
  error?: string;
}> => {
  const url = `/user/forgot-password`;

  try {
    const res = await post(url, { email });
    if (res.data) return { isEmailSent: res.data.isEmailSent };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const resetPasswordMutate = async (data: {
  password: string;
  token: string;
}): Promise<{
  isPasswordUpdated?: boolean;
  errorCode?: number;
  error?: string;
}> => {
  const url = `/user/reset-password`;

  try {
    const res = await put(
      url,
      { password: data.password },
      {
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }
    );
    if (res.data) return { isPasswordUpdated: res.data.isPasswordUpdated };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const currentUserQuery = async (): Promise<{
  user?: User;
  workspaces?: Workspace[];
  errorCode?: number;
  error?: string;
}> => {
  const url = `/user/profile`;

  try {
    const res = await get(url);
    if (res.data)
      return { user: res.data.user, workspaces: res.data.workspaces };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const updateUserDataMutate = async (
  formData: FormData
): Promise<{ user?: User; errorCode?: number; error?: string }> => {
  const url = `/user`;

  try {
    const res = await putDataWithFormData(url, formData);
    if (res.data) return { user: res.data.user };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const updateUserPasswordMutate = async (data: {
  currentPassword: string;
  newPassword: string;
}): Promise<{
  isUserPasswordUpdated?: boolean;
  errorCode?: number;
  error?: string;
}> => {
  const url = `/user/update-password`;

  try {
    const res = await put(url, data);
    if (res.data)
      return { isUserPasswordUpdated: res.data.isUserPasswordUpdated };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const getUsersByEmailQuery = async ({
  workspaceId,
  email,
}: {
  workspaceId?: number;
  email?: string;
}): Promise<{
  users?: User[];
  errorCode?: number;
  error?: string;
}> => {
  let url = `/user/by-email?workspaceId=${workspaceId}&email=${encodeURIComponent(
    email || ""
  )}`;

  try {
    const res = await get(url);
    if (res.data) return { ...res.data };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};
