import { Tr, Td, Flex, Icon } from "@chakra-ui/react";
import { Draggable } from "react-beautiful-dnd";
import { MdDragIndicator } from "react-icons/md";
import { JobStatus } from "../../../types/workspace";
import UserActions from "./UserActions";
import { useTranslation } from "react-i18next";

interface DraggableJobStatusRowProps {
  status: JobStatus;
  onEdit: (status: JobStatus) => void;
  onDelete: (status: JobStatus) => void;
  iconsDisabled?: boolean;
}

const DraggableJobStatusRow = ({
  status,
  onDelete,
  onEdit,
  iconsDisabled = false,
}: DraggableJobStatusRowProps) => {
  const { t } = useTranslation();
  if (!status.id || !status.order) return null;

  return (
    <Draggable
      key={status.id.toString()}
      draggableId={status.id.toString()}
      index={status.order}
    >
      {(provided, snapshot) => (
        <Tr
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          cursor="grab"
          key={status.id?.toString()}
          width="100%"
          display={"flex"}
          sx={{
            backgroundColor: snapshot.isDragging ? "gray.head" : "white",
          }}
        >
          <Td sx={{ flexGrow: 1 }}>{status.name}</Td>
          <Td width="100px">
            <Flex w="full">
              <UserActions
                onRemoveClick={() => {
                  onDelete(status);
                }}
                onEditClick={() => onEdit(status)}
                isRemoveDisabled={iconsDisabled}
                isEditDisabled={iconsDisabled}
                removeToolTip={t(`workspace.deleteStatus`)}
                editToolTip={t(`workspace.editStatus`)}
              />
              <Icon
                as={MdDragIndicator}
                height="20px"
                width="20px"
                color="gray.600"
                cursor="grab"
                opacity={iconsDisabled ? 0.3 : 1}
                pointerEvents={iconsDisabled ? "none" : "auto"}
              />
            </Flex>
          </Td>
        </Tr>
      )}
    </Draggable>
  );
};

export default DraggableJobStatusRow;
