/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import BaseLayout from "../layouts/BaseLayout";
import { Flex } from "@chakra-ui/react";
import { Heading } from "../components/Atoms/Heading/Heading";
import CountBox from "../components/Atoms/CountBox/CountBox";
import {
  useGetWorkspaceUsers,
  useInviteUserToWorkspace,
} from "../hooks/workspace";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import WorkspaceUsersTable from "../components/Organisms/Workspace/WorkspaceUsersTable";
import { Input } from "../components/Atoms/Input/Input";
import { FiSearch } from "react-icons/fi";
import Select, { Option } from "../components/Molecules/Select/Select";
import { useCheckIsMobile } from "../lib/utils";
import { Button } from "../components/Atoms/Button/Button";
import { FaPlus } from "react-icons/fa";
import Pagination from "../components/Molecules/Common/Pagination";
import { Constants } from "../lib/constants";
import InviteUsersModal from "../components/Organisms/Users/InviteUsersModal";
import LottiePlayer from "../components/Molecules/Lottie/Lottie";
import animationData from "../lotties/anim_not_found.json";
import { WorkspaceUserRoles } from "../types/workspace";

const UsersPage = () => {
  const { t } = useTranslation();
  const isMobile = useCheckIsMobile();
  const { currentWorkspace } = useContext(AppContext);
  const [showInviteUserModal, setShowInviteUserModal] =
    useState<boolean>(false);
  const [filters, setFilters] = useState({
    workspaceId: currentWorkspace?.id,
    page: 1,
    roleFilter: "",
    filterString: "",
  });
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const { inviteUser, isPendingInviteUser } = useInviteUserToWorkspace();

  const {
    userCount,
    users,
    isFetchingWorkspaceUsers,
    filteredUsersCount,
    invalidateQueries,
  } = useGetWorkspaceUsers(filters);

  useEffect(() => {
    if (currentWorkspace?.id) {
      setFilters({
        workspaceId: currentWorkspace.id,
        page: 1,
        roleFilter: "",
        filterString: "",
      });
    }
  }, [currentWorkspace]);

  const userTypeOptions = [
    {
      value: "",
      label: t("workspace.all"),
    },
    {
      value: "owner",
      label: t("workspace.owner"),
    },
    {
      value: "admin",
      label: t("workspace.admin"),
    },
    {
      value: "basicUser",
      label: t("workspace.basicUser"),
    },
  ];

  const [selectedOption, setSelectedOption] = useState<Option>(
    userTypeOptions[0]
  );

  const pageCount = filteredUsersCount
    ? Math.ceil(filteredUsersCount / Constants.DefaultItemsPerPage)
    : 0;

  const handleLastUserInPage = () => {
    if (pageCount > 1) setFilters({ ...filters, page: filters.page - 1 });
    else invalidateQueries();
  };

  return (
    <BaseLayout>
      <Flex
        sx={{
          p: isMobile ? "24px" : "48px",
          flexDir: "column",
          width: "100%",
          gap: "32px",
          fontSize: "32px",
          alignItems: "center",
        }}
      >
        <Heading
          sx={{
            color: "black.title",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            gap: "12px",
          }}
        >
          {t("users.users")} <CountBox count={userCount || 0} />
        </Heading>
        <Flex
          sx={{
            flexDir: isMobile ? "column" : "row",
            width: "100%",
            gap: isMobile ? 0 : "16px",
            alignItems: "start",
          }}
        >
          <Input
            type="text"
            LeftIcon={FiSearch}
            placeholder={t("users.searchPlaceholder")}
            label={t("users.search")}
            formControlStyles={{ flexGrow: 1 }}
            formControlWidth={"full"}
            onChange={(value) => {
              if (timeoutId) {
                clearTimeout(timeoutId);
                setTimeoutId(null);
              }
              const id = setTimeout(() => {
                setFilters({
                  ...filters,
                  filterString: value || "",
                  page: 1,
                });
              }, 500);
              setTimeoutId(id);
            }}
          />
          <Select
            isMulti={false}
            isSearchable={false}
            name="userTypes"
            theme="default"
            label={t("profile.role")}
            options={userTypeOptions}
            withImages={false}
            defaultOption={selectedOption}
            onChange={(option) => {
              if (option) {
                setSelectedOption(option);
                setFilters({
                  ...filters,
                  roleFilter: option?.value || "",
                  page: 1,
                });
              }
            }}
          />
          <Button
            size="lg"
            leftIcon={<FaPlus color="white" />}
            sx={{
              mt: "26px",
              height: "40px",
              w: "230px !important",
              fontSize: "14px",
              fontWeight: 600,
            }}
            onClick={() => setShowInviteUserModal(true)}
            isDisabled={
              isPendingInviteUser ||
              (currentWorkspace?.role === WorkspaceUserRoles.BASIC_USER &&
                !currentWorkspace.privileges?.canAddUsersToWorkspace)
            }
          >
            {t("users.addUser")}
          </Button>
        </Flex>
        {!!users?.length && filteredUsersCount && (
          <>
            <WorkspaceUsersTable
              users={users}
              onLastUser={handleLastUserInPage}
            />
            {pageCount > 1 && (
              <Pagination
                pageCount={pageCount}
                handlePageClick={(selectedItem) => {
                  setFilters({ ...filters, page: selectedItem.selected + 1 });
                }}
                currentPage={filters.page - 1}
              />
            )}
          </>
        )}
        {!users?.length && !isFetchingWorkspaceUsers && (
          <>
            <LottiePlayer
              message={t("users.noUsersFound")}
              animationData={animationData}
            />
          </>
        )}
      </Flex>
      {showInviteUserModal && (
        <InviteUsersModal
          isOpen={showInviteUserModal}
          onClose={() => setShowInviteUserModal(false)}
          onClick={async (
            userId: number,
            workspaceId: number,
            role: string
          ) => {
            setShowInviteUserModal(false);
            await inviteUser({ userId, workspaceId, role });
          }}
          isDisabled={isPendingInviteUser}
        />
      )}
    </BaseLayout>
  );
};

export default UsersPage;
