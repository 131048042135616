import { Thead, Tr, Th, Tbody, Td, Avatar, Text, Flex } from "@chakra-ui/react";
import { Workspace, WorkspaceUserRoles } from "../../../types/workspace";
import { Table } from "../../Atoms/Table/Table";
import UserActions from "../Workspace/UserActions";
import { useTranslation } from "react-i18next";
import { RxExit } from "react-icons/rx";
import { useContext, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { useLeaveWorkspace } from "../../../hooks/workspace";
import Modal from "../../Molecules/Modal/Modal";
import { useCheckIsMobile } from "../../../lib/utils";

interface WorkspacesTableProps {
  workspaces: Workspace[];
  onLastWorkspaceInPage: () => void;
}

const WorkspacesTable = ({
  workspaces,
  onLastWorkspaceInPage,
}: WorkspacesTableProps) => {
  const { t } = useTranslation();
  const { user } = useContext(AppContext);
  const [showLeaveModal, setShowLeaveModal] = useState<boolean>(false);
  const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace | null>(
    null
  );
  const isMobile = useCheckIsMobile();

  const { leaveWorkspace, isPendingLeaveWorkspace, invalidateQueries } =
    useLeaveWorkspace();

  const handleLeaveWorkspace = async () => {
    if (user?.id && selectedWorkspace?.id) {
      const { isLeavingSuccessful } = await leaveWorkspace({
        workspaceId: selectedWorkspace.id,
      });
      if (isLeavingSuccessful) {
        if (workspaces.length > 1) invalidateQueries();
        else {
          onLastWorkspaceInPage();
        }
      }
      setSelectedWorkspace(null);
      setShowLeaveModal(false);
    }
  };

  return (
    <>
      {isMobile ? (
        <Flex sx={{ flexDir: "column", w: "full", gap: "8px" }}>
          {workspaces.map((workspace) => {
            const isRemoveDisabled =
              workspace?.role === WorkspaceUserRoles.OWNER;
            return (
              <Flex
                key={workspace.id}
                sx={{
                  flexDir: "row",
                  width: "full",
                  p: "8px",
                  alignItems: "center",
                  borderRadius: "8px",
                }}
                boxShadow={"base"}
                border={"1px solid"}
                borderColor={"gray.light"}
              >
                <Avatar
                  w={"60px"}
                  h={"60px"}
                  name={workspace.name}
                  src={workspace.imageUrl}
                  color={"white"}
                />
                <Flex
                  sx={{
                    flexDir: "column",
                    width: "full",
                    fontSize: "16px",
                    color: "gray.darker",
                    fontWeight: "bold",
                    flexGrow: 1,
                    overflow: "hidden",
                    ml: "10px",
                  }}
                >
                  <Text
                    sx={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {workspace.name}
                  </Text>
                  <Text
                    sx={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      fontSize: "12px",
                    }}
                  >
                    {t(`workspace.${workspace.role}`)}
                  </Text>
                </Flex>
                <UserActions
                  onRemoveClick={() => {
                    setSelectedWorkspace(workspace);
                    setShowLeaveModal(true);
                  }}
                  showEdit={false}
                  isRemoveDisabled={isRemoveDisabled || isPendingLeaveWorkspace}
                  removeIcon={RxExit}
                  removeToolTip={t(`profile.leaveWorkspace`)}
                  flexDir="column"
                />
              </Flex>
            );
          })}
        </Flex>
      ) : (
        <Table variant="status">
          <Thead>
            <Tr>
              <Th w={"60px !important"}>{""}</Th>
              <Th>{t("profile.firstName")}</Th>
              <Th>{t("profile.role")}</Th>
              <Th w={"40px !important"}>{""}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {workspaces.map((workspace) => {
              const isRemoveDisabled =
                workspace?.role === WorkspaceUserRoles.OWNER;
              return (
                <Tr key={workspace.id}>
                  <Td w={"40px !important"}>
                    <Avatar
                      w={"60px"}
                      h={"60px"}
                      name={workspace.name}
                      src={workspace.imageUrl}
                      color={"white"}
                    />
                  </Td>
                  <Td>{workspace.name}</Td>
                  <Td>{t(`workspace.${workspace.role}`)}</Td>
                  <Td w={"40px !important"}>
                    <UserActions
                      onRemoveClick={() => {
                        setSelectedWorkspace(workspace);
                        setShowLeaveModal(true);
                      }}
                      showEdit={false}
                      isRemoveDisabled={
                        isRemoveDisabled || isPendingLeaveWorkspace
                      }
                      removeIcon={RxExit}
                      removeToolTip={t(`profile.leaveWorkspace`)}
                    />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      )}
      {showLeaveModal && selectedWorkspace && !!user?.id && (
        <Modal
          onClick={() => handleLeaveWorkspace()}
          onClose={() => {
            setShowLeaveModal(false);
            setSelectedWorkspace(null);
          }}
          isOpen={showLeaveModal}
          primaryLabel={t("common.cancel")}
          secondaryLabel={t("common.leave")}
          variant="warning"
          title={<Text>{t("profile.leaveWorkspace")}</Text>}
          subtitle={t("profile.leaveWorkspaceConfirm", {
            name: selectedWorkspace.name,
          })}
          imageSrc="/AlertErrorCheckmark.svg"
          disabled={false}
        ></Modal>
      )}
    </>
  );
};

export default WorkspacesTable;
