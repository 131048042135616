import { defineStyleConfig } from "@chakra-ui/react";

export const Modal = defineStyleConfig({
  baseStyle: {
    overlay: {
      background: "rgba(52, 64, 84, 0.70)",
      backdropBlur: "3px",
    },
    dialog: {
      padding: "24px",
      alignItems: "center",
    },
    header: {
      fontSize: "18px",
      textAlign: "center",
    },
    body: {
      color: "gray.darker",
      fontSize: "14px",
      textAlign: "center",
      width: "100%",
    },
  },

  sizes: {
    md: {
      dialog: {
        maxWidth: "400px",
      },
    },
  },

  variants: {
    warning: {
      footer: {
        button: { backgroundColor: "red.delete" },
      },
      header: {
        span: {
          color: "red.delete",
        },
      },
    },
    success: {
      footer: {
        button: { backgroundColor: "green.foundationNormal" },
      },
      header: {
        span: {
          color: "green.foundationNormal",
        },
      },
    },
  },

  defaultProps: {
    size: "md",
  },
});
