import { handleErrorResponse } from "../lib/utils";
import { JobStatus } from "../types/workspace";
import { post, put, del } from "./api";

export const addJobStatusMutate = async (data: {
  name: string;
  workspaceId: number;
}): Promise<{ jobStatus?: JobStatus; errorCode?: number; error?: string }> => {
  const url = `/job-status`;

  try {
    const res = await post(url, data);
    if (res.data) return { jobStatus: res.data.jobStatus };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const editJobStatusMutate = async (data: {
  name: string;
  workspaceId: number;
  jobStatusId: number;
}): Promise<{ jobStatus?: JobStatus; errorCode?: number; error?: string }> => {
  const url = `/job-status/${data.jobStatusId}`;

  try {
    const res = await put(url, data);
    if (res.data) return { jobStatus: res.data.jobStatus };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const deleteJobStatusMutate = async (
  jobStatusId: number
): Promise<{
  jobStatuses?: JobStatus[];
  errorCode?: number;
  error?: string;
}> => {
  const url = `/job-status/${jobStatusId}`;

  try {
    const res = await del(url);
    if (res.data) return { jobStatuses: res.data.jobStatuses };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const reorderJobStatusMutate = async (data: {
  workspaceId: number;
  jobStatuses: JobStatus[];
}): Promise<{
  jobStatuses?: JobStatus[];
  errorCode?: number;
  error?: string;
}> => {
  const url = `/job-status/reorder`;

  try {
    const res = await put(url, data);
    if (res.data) return { jobStatuses: res.data.jobStatuses };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};
