import { Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import BaseLayout from "../layouts/BaseLayout";
import { useCheckIsMobile } from "../lib/utils";
import { Heading } from "../components/Atoms/Heading/Heading";
import { useParams } from "react-router-dom";
import { useGetJobById, useUpdateJobLabels } from "../hooks/jobs";
import Inputs from "../components/Organisms/Job/Inputs";
import { useContext, useEffect, useState } from "react";
import Statuses from "../components/Organisms/Job/Statuses";
import { Option } from "../components/Molecules/Select/Select";
import AssignedTo from "../components/Organisms/Job/AssignedTo";
import Documents from "../components/Organisms/Job/Documents";
import { JobDocuments } from "../types/Job";
import Reminder from "../components/Organisms/Job/Reminder";
import { Label } from "../types/Labels";
import { jobSchema } from "./NewJobPage";
import { AppContext } from "../context/AppContext";

const EditJobPage = () => {
  const { t } = useTranslation();
  const isMobile = useCheckIsMobile();
  const { id } = useParams();
  const { currentWorkspace } = useContext(AppContext);

  const { job, isFetchingJob, jobStatuses } = useGetJobById({
    jobId: Number(id),
  });

  const { updateJobLabels, isPendingUpdateJobLabels } = useUpdateJobLabels();

  const [errors, setErrors] = useState({ text: "" });
  const [jobLabels, setJobLabels] = useState<
    { labelId: number; text: string }[]
  >([]);
  const [jobStatusOptions, setJobStatusOptions] = useState<Option[]>([
    {
      value: "",
      label: t("job.statusNotAssigned"),
    },
  ]);
  const [selectedOption, setSelectedOption] = useState<Option>(
    jobStatusOptions[0]
  );

  const [selectedUserOption, setSelectedUserOption] = useState<
    Option | Option[] | undefined
  >(undefined);

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [fileUrls, setFileUrls] = useState<JobDocuments[]>([]);
  const [jobReminder, setJobReminder] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (job?.id && job.jobLabels) {
      const labels = job.jobLabels.map((label) => ({
        labelId: label?.label?.id || 0,
        text: label.text || "",
        id: label.id,
      }));
      setJobLabels(labels);
    }
    if (jobStatuses && jobStatuses.length) {
      const statusOptions: Option[] = jobStatuses?.map(
        (status) =>
          ({
            label: status.name,
            value: status.id,
          } as Option)
      );
      if (statusOptions)
        setJobStatusOptions([...jobStatusOptions, ...statusOptions]);

      if (job?.statusId) {
        const selectedStatus = statusOptions.find(
          (option) => option.value === job.statusId
        );
        if (selectedStatus) setSelectedOption(selectedStatus);
      }
    }
    if (job?.id && job.assignedUsers) {
      const options = job.assignedUsers.map((user) => {
        const option: Option = {
          value: user.id?.toString(),
          label: user.user.firstName + " " + user.user.lastName,
          src: user.user.filePath,
          subtitle: user.user.email,
        };
        return option;
      });
      setSelectedUserOption(options);
    }
    if (job?.id && job.documents) {
      //const newFiles: File[] = Array.from(job.documents);

      const newFileUrls = job.documents;

      //setSelectedFiles([...selectedFiles, ...newFiles]);
      setFileUrls([...fileUrls, ...newFileUrls]);
    }
    if (job?.reminderText && job.hasReminder) {
      setJobReminder(job.reminderText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job]);

  const updateLabelText = (value: string, index: number, labelId?: number) => {
    if (labelId) {
      const labels = jobLabels.map((label) => {
        if (label.labelId === labelId) {
          return {
            ...label,
            text: value,
          };
        }
        return label;
      });
      setJobLabels(labels);
      if (index === 0 && !!value) setErrors({ text: "" });
    }
  };

  const getInputValue = (labelId?: number) => {
    if (labelId) {
      const label = jobLabels.find((label) => label.labelId === labelId);
      return label?.text || "";
    }
    return "";
  };

  const handleFileInputChange = () => {};
  const handleRemoveFile = () => {};

  const labels: Label[] =
    job && job?.jobLabels && job?.jobLabels.length
      ? job.jobLabels
          .map((jobLabel) => jobLabel.label)
          .filter((label): label is Label => label !== undefined)
      : [];

  const handleCancelUpdatingLabels = () => {
    if (job?.id && job.jobLabels) {
      const labels = job.jobLabels.map((label) => ({
        labelId: label?.label?.id || 0,
        text: label.text || "",
        id: label.id,
      }));
      setJobLabels(labels);
    }
  };

  const checkIfUpdated = () => {
    let isUpdated = false;
    if (job && job?.jobLabels) {
      // eslint-disable-next-line array-callback-return
      job.jobLabels.map((jobLabel) => {
        const updatedLabel = jobLabels.find(
          (label) => label.labelId === jobLabel.label?.id
        );
        if (jobLabel.text !== updatedLabel?.text) isUpdated = true;
      });
    }
    return isUpdated;
  };

  const handleUpdateJobLabels = async () => {
    if (!jobLabels.length || !job?.id || !currentWorkspace?.id) return;
    try {
      await jobSchema.validate(jobLabels[0], {
        abortEarly: false,
      });

      await updateJobLabels({
        jobLabels,
        jobId: job?.id,
        workspaceId: currentWorkspace?.id,
      });
    } catch (error: any) {
      let errors: any = {};
      if (error.errors.length) {
        error.errors.forEach(
          (err: { field: string; translationKey: string }) => {
            errors[err.field as keyof typeof errors] = t(err.translationKey);
          }
        );
        setErrors(errors);
      }
    }
  };

  return (
    <BaseLayout>
      <Flex
        sx={{
          p: isMobile ? "24px" : "48px",
          flexDir: "column",
          width: "100%",
          fontSize: "32px",
          alignItems: "center",
        }}
      >
        <Heading
          sx={{
            color: "black.title",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            gap: "12px",
            mb: "32px",
          }}
        >
          {t("job.detail")}
        </Heading>
        {job?.id && (
          <>
            <Inputs
              updateLabelText={updateLabelText}
              getInputValue={getInputValue}
              errors={errors}
              labels={labels}
              variant="edit"
              onCancelClick={handleCancelUpdatingLabels}
              onUpdatedClick={handleUpdateJobLabels}
              isSomethingUpdated={checkIfUpdated()}
              disabled={isPendingUpdateJobLabels}
            />

            <Statuses
              jobStatusOptions={jobStatusOptions}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />

            <AssignedTo
              selectedUserOption={selectedUserOption}
              setSelectedUserOption={setSelectedUserOption}
              workspaceId={job.workspaceId}
            />
            <Documents
              isDisabled={isFetchingJob}
              handleFileInputChange={handleFileInputChange}
              handleRemoveFile={handleRemoveFile}
              selectedFiles={selectedFiles}
              fileUrls={fileUrls}
            />
            <Reminder
              jobReminder={jobReminder}
              setJobReminder={setJobReminder}
            />
          </>
        )}
      </Flex>
    </BaseLayout>
  );
};

export default EditJobPage;
