import { Box, Flex, Image, VStack } from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { AppContext } from "../../../context/AppContext";
import { useContext } from "react";
import UserInfo from "../../Molecules/Sidebar/UserInfo";
import SidebarItems from "../../Molecules/Sidebar/SidebarItems";
import Select from "../../Molecules/Select/Select";
import { getWorkspaceOptions } from "../../../lib/utils";

const MobileSidebar = () => {
  const {
    isSidebarOpen,
    setIsSidebarOpen,
    workspaces,
    setCurrentWorkspace,
    currentWorkspace,
  } = useContext(AppContext);

  const options = workspaces?.length ? getWorkspaceOptions(workspaces) : [];

  const findOptions = options.filter(
    (cw) => Number(cw.value) === currentWorkspace?.id
  );
  const defaultOption = findOptions.length ? findOptions[0] : undefined;

  return (
    <Flex>
      <Flex
        sx={{
          flexDir: "row",
          height: "70px",
          backgroundColor: "green.foundationDark",
          width: "100%",
          borderRadius: "0px 0px 18px 0px",
          p: "16px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Image src={"/Logo.svg"} alt="Logo" />
        <HamburgerIcon
          color={"white"}
          width={"25px"}
          height={"25px"}
          onClick={() => setIsSidebarOpen(true)}
          cursor={"pointer"}
        />
      </Flex>
      {isSidebarOpen && (
        <>
          <Box
            position="fixed"
            top={0}
            left={0}
            h="100vh"
            w="100%"
            background="linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.7))"
            onClick={() => setIsSidebarOpen(false)}
            zIndex={3}
          ></Box>
          <VStack
            position="fixed"
            top={0}
            left={0}
            h="100vh"
            width="300px"
            bg="green.foundationDark"
            p={"16px"}
            zIndex={3}
          >
            <Flex
              sx={{
                flexDir: "row",
                height: "70px",
                backgroundColor: "green.foundationDark",
                width: "100%",
                borderRadius: "0px 0px 18px 0px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Image src={"/Logo.svg"} alt="Logo" />
              <CloseIcon
                color={"white"}
                width={"15px"}
                height={"15px"}
                onClick={() => setIsSidebarOpen(false)}
                cursor={"pointer"}
              />
            </Flex>
            {!!workspaces?.length && defaultOption && (
              <Box sx={{ mt: "60px", mb: "32px" }} width={"full"}>
                <Select
                  name="workspaces"
                  theme="sidebar"
                  isSearchable={false}
                  options={getWorkspaceOptions(workspaces)}
                  onChange={(option) => {
                    option?.value && setCurrentWorkspace(Number(option?.value));
                  }}
                  defaultOption={defaultOption}
                />
              </Box>
            )}
            <SidebarItems />
            <UserInfo />
          </VStack>
        </>
      )}
    </Flex>
  );
};

export default MobileSidebar;
