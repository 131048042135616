import { useTranslation } from "react-i18next";
import { Heading } from "../components/Atoms/Heading/Heading";
import BaseLayout from "../layouts/BaseLayout";
import TabContainer from "../components/Molecules/Tab/TabContainer";
import TabItem from "../components/Molecules/Tab/TabItem";
import { useState } from "react";
import { Flex } from "@chakra-ui/react";
import PersonalDataSection from "../components/Organisms/Profile/PersonalDataSection";
import WorkspacesSection from "../components/Organisms/Profile/WorkspacesSection";

enum ProfilePageTabs {
  PERSONAL_DETAILS = "personalDetails",
  WORKSPACES = "workspaces",
}

const ProfilePage = () => {
  const [currentTab, setCurrentTab] = useState<ProfilePageTabs>(
    ProfilePageTabs.PERSONAL_DETAILS
  );
  const { t } = useTranslation();
  return (
    <BaseLayout>
      <Flex
        sx={{
          p: "48px",
          flexDir: "column",
          width: "100%",
          gap: "32px",
          fontSize: "32px",
          alignItems: "center",
        }}
      >
        <Heading sx={{ color: "black.title" }}>
          {t("profile.myProfile")}
        </Heading>
        <TabContainer>
          <TabItem
            isActive={currentTab === ProfilePageTabs.PERSONAL_DETAILS}
            label={t("profile.personalDetails")}
            onClick={() => setCurrentTab(ProfilePageTabs.PERSONAL_DETAILS)}
          />
          <TabItem
            isActive={currentTab === ProfilePageTabs.WORKSPACES}
            label={t("workspace.workspaces")}
            onClick={() => setCurrentTab(ProfilePageTabs.WORKSPACES)}
          />
        </TabContainer>
        {currentTab === ProfilePageTabs.PERSONAL_DETAILS && (
          <PersonalDataSection />
        )}
        {currentTab === ProfilePageTabs.WORKSPACES && <WorkspacesSection />}
      </Flex>
    </BaseLayout>
  );
};

export default ProfilePage;
