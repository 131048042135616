import { Flex } from "@chakra-ui/react";
import {
  RegistrationStepType,
  RegistrationStepsEnum,
} from "../../../types/Registration";
import { Step } from "./Step";
import { useTranslation } from "react-i18next";

export type RegistrationStepProps = {
  currentStep: RegistrationStepsEnum;
};

export const RegistrationSteps = ({ currentStep }: RegistrationStepProps) => {
  const { t } = useTranslation();

  const stepsData = [
    {
      step: RegistrationStepsEnum.BASIC_INFO,
      title: t("register.basicInfoTitle"),
      description: t("register.basicInfoDescription"),
    },
    {
      step: RegistrationStepsEnum.PASSWORD,
      title: t("register.passwordTitle"),
      description: t("register.passwordTDescription"),
    },
    {
      step: RegistrationStepsEnum.CONFIRM_EMAIL,
      title: t("register.confirmEmailTitle"),
      description: t("register.confirmEmailDescription"),
    },
    {
      step: RegistrationStepsEnum.WORKSPACE_SETUP,
      title: t("register.workspaceTitle"),
      description: t("register.workspaceDescription"),
    },
    {
      step: RegistrationStepsEnum.SUBSCRIPTION,
      title: t("register.subscriptionTitle"),
      description: t("register.subscriptionDescription"),
    },
    {
      step: RegistrationStepsEnum.PAYMENT,
      title: t("register.paymentTitle"),
      description: t("register.paymentDescription"),
    },
  ];

  const getStepType = (step: RegistrationStepsEnum): RegistrationStepType => {
    if (currentStep === step) return RegistrationStepType.CURRENT;
    else if (currentStep > step) return RegistrationStepType.DONE;
    else return RegistrationStepType.REMAINING;
  };

  return (
    <Flex sx={{ flexDir: "column" }}>
      {stepsData.map((step) => {
        return (
          <Step
            key={step.step}
            type={getStepType(step.step)}
            title={step.title}
            description={step.description}
            addConnector={step.step !== RegistrationStepsEnum.PAYMENT}
          />
        );
      })}
    </Flex>
  );
};
