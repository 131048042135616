import { extendTheme } from "@chakra-ui/react";
import { Heading } from "../components/Atoms/Heading/Heading.styles";
import { Input } from "../components/Atoms/Input/Input.styles";
import { Button } from "../components/Atoms/Button/Button.styles";
import { Link } from "../components/Atoms/Link/Link.styles";
import { AlertMessage } from "../components/Molecules/AlertMessage/AlertMessage.styles";
import { Modal } from "../components/Molecules/Modal/Modal.styles";
import { Table } from "../components/Atoms/Table/Table.style";

export const theme = extendTheme({
  components: {
    Heading,
    Input,
    Button,
    Link,
    Alert: AlertMessage,
    Modal: Modal,
    Table: Table,
    Avatar: {
      baseStyle: {
        excessLabel: {
          backgroundColor: "green.foundationLighter",
          color: "white",
          fontSize: "14px",
          fontWeight: "600",
        },
      },
    },
  },
  colors: {
    gray: {
      100: "#F1F1F1",
      200: "#F6F6F6",
      300: "#747474",
      light: "#D0D5DD",
      darker: "#7F8C8D",
      bottom: "#EAECF0",
      head: "#F9FAFB",
      pagination: "#667085",
    },
    blue: {
      900: "#4340DA",
    },
    green: {
      foundationDark: "#388686",
      foundationDarkActive: "#0F3A3A",
      foundationLight: "#FFFFFF",
      foundationLightActive: "#A7EDF1",
      foundationLightHover: "#DEF2F4",
      foundationNormal: "#60B5AE",
      foundationLighter: "#5C9E9E",
      lightBorder: "#CDECEA",
      success25: "#F6FEF9",
      success300: "#75E0A7",
      success500: "#17B26A",
      success600: "#079455",
      success700: "#067647",
    },
    black: {
      default: "#142C2C",
      darker: "#051515",
      title: "#31384A",
      darkText: "#0F3A3A",
    },
    red: {
      error300: "#FDA29B",
      error500: "#F04438",
      error600: "#D92D20",
      error700: "#B42318",
      delete: "#F1574B",
    },
  },
});

export const multipleSelectStyles = {
  control: (provided: any) => ({
    ...provided,
    background: "white",
    boxShadow: "base",
    color: "gray.300",
    _placeholder: { color: "gray.300", opacity: 0 },
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    background: "white",
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "gray.300",
  }),
  menuList: (provided: any) => ({
    ...provided,
    boxShadow: "base",
    outline: `2px solid ${theme.colors.blue[500]}`,
    minHeight: "80px",
  }),
};
