import { Button as ChakraButton, SystemStyleObject } from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";

export type ButtonProps = {
  variant?: "primary" | "secondary" | "tertiary" | "quaternary";
  sx?: SystemStyleObject;
  children: ReactNode | string;
  size?: "sm" | "md" | "lg" | "full";
  rightIcon?: ReactElement;
  leftIcon?: ReactElement;
  type?: "button" | "submit" | "reset" | undefined;
  onClick?: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
};

export const Button = ({
  variant = "primary",
  sx,
  children,
  size = "lg",
  rightIcon,
  type = "button",
  leftIcon,
  onClick,
  isDisabled = false,
  isLoading = false,
}: ButtonProps) => {
  return (
    <ChakraButton
      variant={variant}
      size={size}
      rightIcon={rightIcon && rightIcon}
      leftIcon={leftIcon && leftIcon}
      type={type}
      onClick={onClick && onClick}
      sx={sx}
      isDisabled={isDisabled}
      isLoading={isLoading}
    >
      {children}
    </ChakraButton>
  );
};
