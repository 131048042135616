/* eslint-disable @typescript-eslint/no-unused-vars */
import Cookies from "js-cookie";
import { ErrorCodes } from "../types/ErrorCodes";
interface RequestOptions {
  headers?: HeadersInit;
  body?: BodyInit;
}

export const parseResponse = async (response: Response) => {
  const res = await response.json();
  if (response.ok || res.errorCode || res.data) return res;
  throw new Error(
    JSON.stringify({ status: response.status, message: response.statusText })
  );
};

const getNewAccessToken = async (): Promise<string | null> => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const refreshToken = Cookies.get("ref_tkn");
  const refreshTokenResponse = await fetch(baseUrl + "/auth/refresh-token", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${refreshToken}`,
    },
  });
  const response = await parseResponse(refreshTokenResponse);
  if (response?.data?.accessToken) return response.data.accessToken;
  return null;
};

async function makeRequestWithTokenRefresh(
  url: string,
  method: string,
  data?: any,
  options?: RequestOptions
): Promise<any> {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  let accessToken = Cookies.get("acc_tkn");

  const fetchOptions = {
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
      ...options?.headers,
    },
    body: data
      ? method === "POST" || method === "PUT"
        ? JSON.stringify(data)
        : data
      : undefined,
  };

  const response = await fetch(baseUrl + url, fetchOptions);
  let parsedResponse = await parseResponse(response);

  if (
    parsedResponse.errorCode === ErrorCodes.SESSION_EXPIRED ||
    parsedResponse.errorCode === ErrorCodes.UNAUTHORIZED
  ) {
    const newAccessToken = await getNewAccessToken();

    if (newAccessToken) {
      Cookies.set("acc_tkn", newAccessToken);

      const retryOptions = {
        ...options,
        headers: {
          ...options?.headers,
          Authorization: `Bearer ${newAccessToken}`,
        },
      };
      const retryResponse = await fetch(baseUrl + url, {
        ...fetchOptions,
        ...retryOptions,
      });
      parsedResponse = await parseResponse(retryResponse);
    }
  }

  return parsedResponse;
}

export async function post(
  url: string,
  data: any,
  options?: RequestOptions
): Promise<any> {
  return makeRequestWithTokenRefresh(url, "POST", data, options);
}

export async function put(
  url: string,
  data: any,
  options?: RequestOptions
): Promise<any> {
  return makeRequestWithTokenRefresh(url, "PUT", data, options);
}

export async function del(
  url: string,
  data?: any,
  options?: RequestOptions
): Promise<any> {
  return makeRequestWithTokenRefresh(url, "DELETE", data, options);
}

export async function postDataWithFormData<T>(
  url: string,
  formData: FormData,
  options?: RequestOptions
): Promise<any> {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  let accessToken = Cookies.get("acc_tkn");

  const fetchOptions = {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...options?.headers,
    },
  };

  const response = await fetch(baseUrl + url, fetchOptions);
  let parsedResponse = await parseResponse(response);

  if (
    parsedResponse.errorCode === ErrorCodes.SESSION_EXPIRED ||
    parsedResponse.errorCode === ErrorCodes.UNAUTHORIZED
  ) {
    const newAccessToken = await getNewAccessToken();

    if (newAccessToken) {
      Cookies.set("acc_tkn", newAccessToken);
      const retryOptions = {
        ...options,
        headers: {
          ...options?.headers,
          Authorization: `Bearer ${newAccessToken}`,
        },
      };
      const retryResponse = await fetch(baseUrl + url, {
        ...fetchOptions,
        ...retryOptions,
      });
      parsedResponse = await parseResponse(retryResponse);
    }
  }

  return parsedResponse;
}

export async function putDataWithFormData<T>(
  url: string,
  formData: FormData,
  options?: RequestOptions
): Promise<any> {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  let accessToken = Cookies.get("acc_tkn");

  const fetchOptions = {
    method: "PUT",
    body: formData,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...options?.headers,
    },
  };

  const response = await fetch(baseUrl + url, fetchOptions);
  let parsedResponse = await parseResponse(response);

  if (
    parsedResponse.errorCode === ErrorCodes.SESSION_EXPIRED ||
    parsedResponse.errorCode === ErrorCodes.UNAUTHORIZED
  ) {
    const newAccessToken = await getNewAccessToken();

    if (newAccessToken) {
      Cookies.set("acc_tkn", newAccessToken);
      const retryOptions = {
        ...options,
        headers: {
          ...options?.headers,
          Authorization: `Bearer ${newAccessToken}`,
        },
      };
      const retryResponse = await fetch(baseUrl + url, {
        ...fetchOptions,
        ...retryOptions,
      });
      parsedResponse = await parseResponse(retryResponse);
    }
  }

  return parsedResponse;
}

export async function get(url: string, options?: RequestOptions): Promise<any> {
  return makeRequestWithTokenRefresh(url, "GET", null, options);
}
