import { defineStyleConfig } from "@chakra-ui/react";

export const Table = defineStyleConfig({
  baseStyle: {
    table: {
      borderRadius: "12px",
      backgroundColor: "white",
      border: "1px solid #EAECF0",
      borderCollapse: "separate",
      width: "100%",
    },
    thead: {
      backgroundColor: "gray.head",
    },
    th: {
      padding: "12px",
      textAlign: "left",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 600,
    },
    tbody: {
      td: {
        padding: "12px",
        borderBottom: "1px solid #EAECF0",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        boxSizing: "border-box",
      },
      tr: {
        "&:last-child": {
          td: {
            borderBottom: "none",
          },
        },
      },
    },
  },
  variants: {
    status: {
      tr: {
        "&:first-of-type": {
          td: {
            overflow: "hidden",
            whiteSpace: "normal",
            wordBreak: "break-all",
          },
        },
      },
    },
    users: {
      tr: {
        td: {
          overflow: "hidden",
          whiteSpace: "normal",
          wordBreak: "break-all",
        },
        th: {
          overflow: "hidden",
          whiteSpace: "normal",
          wordBreak: "break-spaces",
        },
      },
      tbody: {
        td: {
          maxWidth: "calc(100% / 5)",
        },
      },
    },
    jobs: {
      table: {
        tableLayout: "fixed",
        width: "100%",
        tbody: {
          th: {
            th: {
              w: "30%",
            },
          },
          td: {
            w: "30%",
            p: {
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              height: "20px",
            },
          },
        },
      },
    },
  },

  sizes: {
    sm: {},
    md: {},
    lg: {},
  },

  defaultProps: {
    size: "md",
  },
});
