import { Flex, Avatar } from "@chakra-ui/react";

import { MdOutlineMailOutline } from "react-icons/md";
import { FileUpload } from "../../Atoms/FileUpload/FileUpload";
import { PhoneNumberInput } from "../../Atoms/PhoneNumberInput/PhoneNumberInput";
import SectionHeading from "../../Molecules/Common/SectionHeading";
import SectionItem from "../../Molecules/Common/SectionItem";
import { Input } from "../../Atoms/Input/Input";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { User } from "../../../types/user";
import { userSchema } from "../Registration/StepBasicInfo";
import { useUpdateUserData, useUpdateUserPassword } from "../../../hooks/user";
import { CheckField } from "../../Atoms/CheckField/CheckField";
import { Constants } from "../../../lib/constants";
import { isPasswordValid } from "../../../lib/utils";
import { WorkspaceUserRoles } from "../../../types/workspace";
import UserPrivileges from "../Users/UserPrivileges";

const initialUserFormErrors = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
};

const PersonalDataSection = () => {
  const { t } = useTranslation();
  const { user, currentWorkspace } = useContext(AppContext);

  const [newFile, setNewFile] = useState<File | null>(null);
  const [newFileUrl, setNewFileUrl] = useState<string | undefined>(undefined);
  const [form, setForm] = useState<User>(
    user || {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      filePath: "",
    }
  );
  const [formErrors, setFormErrors] = useState<User>(initialUserFormErrors);
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmedPassword, setConfirmedPassword] = useState<string>("");

  const { updateUserData, isPendingUpdateUserData } = useUpdateUserData();
  const { updateUserPassword, isPendingUpdateUserPassword } =
    useUpdateUserPassword();

  useEffect(() => {
    if (user) {
      setForm(user);
      setFormErrors(initialUserFormErrors);
    }
  }, [user]);

  const arePersonalDataActionButtonsDisabled =
    (form.firstName === user?.firstName &&
      form.lastName === user.lastName &&
      form.phoneNumber === user.phoneNumber &&
      !newFile) ||
    isPendingUpdateUserData;

  const handleUpdateUserData = async () => {
    try {
      await userSchema.validate(form, { abortEarly: false });
      const formData = new FormData();
      formData.append("firstName", form.firstName);
      formData.append("lastName", form.lastName);
      formData.append("phoneNumber", form.phoneNumber);
      if (newFile) formData.append("files", newFile);
      await updateUserData(formData);
      setNewFile(null);
      setNewFileUrl(undefined);
    } catch (error: any) {
      let errors: any = {};
      if (error.errors.length) {
        error.errors.forEach(
          (err: { field: string; translationKey: string }) => {
            errors[err.field as keyof typeof errors] = t(err.translationKey);
          }
        );
        setFormErrors(errors);
      }
    }
  };

  const areUpdatePasswordActionButtonsDisabled =
    !isPasswordValid(currentPassword) ||
    !isPasswordValid(newPassword) ||
    newPassword !== confirmedPassword ||
    isPendingUpdateUserPassword;

  const handlePasswordUpdate = async () => {
    if (
      !isPasswordValid(newPassword) ||
      !isPasswordValid(currentPassword) ||
      newPassword !== confirmedPassword
    )
      return;

    const { isUserPasswordUpdated } = await updateUserPassword({
      currentPassword,
      newPassword,
    });
    if (isUserPasswordUpdated) {
      setNewPassword("");
      setConfirmedPassword("");
      setCurrentPassword("");
    }
  };

  return (
    <Flex sx={{ flexDir: "column", width: "100%", gap: "24px" }}>
      <SectionHeading
        title={t("profile.personalDetails")}
        subtitle={t("profile.personalDetailsDescription")}
        primaryButtonLabel={t("common.update")}
        secondaryButtonLabel={t("common.cancel")}
        disabled={arePersonalDataActionButtonsDisabled}
        onClickSecondary={() => {
          if (user) setForm(user);
          setNewFile(null);
          setNewFileUrl(undefined);
          setFormErrors(initialUserFormErrors);
        }}
        onClickPrimary={() => handleUpdateUserData()}
      />
      <SectionItem
        label={t("profile.firstName")}
        sx={{
          flexDir: ["column", "column", "row"],
          gap: "20px",
        }}
      >
        <Input
          formControlWidth={"full"}
          sx={{ margin: 0, maxW: "420px" }}
          value={form.firstName}
          onChange={(value) => {
            setForm({ ...form, firstName: value });
            if (value) setFormErrors({ ...formErrors, firstName: "" });
          }}
          errorMessage={formErrors.firstName}
        />
        <Input
          formControlWidth={"full"}
          sx={{ margin: 0, maxW: "420px" }}
          value={form.lastName}
          onChange={(value) => {
            setForm({ ...form, lastName: value });
            if (value) setFormErrors({ ...formErrors, lastName: "" });
          }}
          errorMessage={formErrors.lastName}
        />
      </SectionItem>
      <SectionItem label={t("profile.email")}>
        <Input
          sx={{ margin: 0 }}
          formControlWidth={["full", "full", "420px"]}
          isDisabled={true}
          LeftIcon={MdOutlineMailOutline}
          value={form.email}
        />
      </SectionItem>
      <SectionItem label={t("profile.phoneNumber")}>
        <PhoneNumberInput
          value={form.phoneNumber}
          onChange={(value, _country, _e, formattedValue) => {
            setForm({ ...form, phoneNumber: formattedValue });
          }}
        />
      </SectionItem>
      <SectionItem label={t("profile.profileImage")} sx={{ w: "100%" }}>
        <Flex
          sx={{
            w: "100%",
            gap: "20px",
            flexDir: ["column", "column", "row"],
            alignItems: ["center", "center", "flex-start"],
          }}
        >
          <Avatar
            w={"64px"}
            h={"64px"}
            name={form.firstName + " " + form.lastName}
            src={!!newFileUrl ? newFileUrl : form.filePath}
            color={"white"}
          />
          <FileUpload
            onChange={(files) => {
              setNewFile(files[0]);
              setNewFileUrl(URL.createObjectURL(files[0]));
            }}
            showFileName={false}
            sx={{ width: ["full", "380px"] }}
          />
        </Flex>
      </SectionItem>

      <SectionHeading
        sx={{ mt: "32px" }}
        title={t("profile.password")}
        subtitle={t("profile.passwordTip")}
        primaryButtonLabel={t("common.update")}
        secondaryButtonLabel={t("common.cancel")}
        disabled={areUpdatePasswordActionButtonsDisabled}
        onClickSecondary={() => {
          setNewPassword("");
          setCurrentPassword("");
          setConfirmedPassword("");
        }}
        onClickPrimary={() => handlePasswordUpdate()}
      />
      <SectionItem label={t("profile.currentPassword")}>
        <Input
          type="password"
          placeholder={t("profile.currentPasswordPlaceholder")}
          value={currentPassword}
          onChange={(value) => setCurrentPassword(value)}
        />
      </SectionItem>
      <SectionItem label={t("profile.newPassword")} sx={{ flexDir: "column" }}>
        <Input
          type="password"
          placeholder={t("profile.newPasswordPlaceholder")}
          value={newPassword}
          onChange={(value) => setNewPassword(value)}
        />
        <CheckField
          label={t("validation.passwordMinSize")}
          isChecked={newPassword.length >= Constants.MinPasswordLength}
        />
        <CheckField
          label={t("validation.passwordRule")}
          isChecked={!!(/\d/.test(newPassword) && /[A-Z]/.test(newPassword))}
        />
      </SectionItem>
      <SectionItem
        label={t("profile.confirmPassword")}
        sx={{ flexDir: "column" }}
      >
        <Input
          type="password"
          placeholder={t("profile.confirmPasswordPlaceholder")}
          value={confirmedPassword}
          onChange={(value) => setConfirmedPassword(value)}
        />
        <CheckField
          label={t("validation.passwordMustMatch")}
          isChecked={!!newPassword && newPassword === confirmedPassword}
        />
      </SectionItem>
      {currentWorkspace?.role === WorkspaceUserRoles.BASIC_USER && (
        <>
          <SectionHeading
            title={t("privileges.title")}
            subtitle={t("privileges.profileSubtitle", {
              workspace: currentWorkspace.name,
            })}
          />
          <UserPrivileges
            privileges={currentWorkspace?.privileges || {}}
            isDisabled={true}
          />
        </>
      )}
    </Flex>
  );
};

export default PersonalDataSection;
