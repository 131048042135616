import { defineStyleConfig } from "@chakra-ui/react";

export const AlertMessage = defineStyleConfig({
  baseStyle: {
    container: {
      minHeight: "60px",
      borderRadius: "12px",
      opacity: "0.9",
      alignItems: "center",
    },
    description: {
      fontSize: "14px",
      lineHeight: "20px",
    },
    title: {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: "600",
    },
  },

  sizes: {
    sm: {
      container: {
        width: "30%",
      },
    },
    md: {
      container: {
        width: "50%",
      },
    },
    lg: {
      container: {
        width: "70%",
      },
    },
    xl: {
      container: {
        width: "90%",
      },
    },
  },

  variants: {
    success: {
      container: {
        backgroundColor: "green.50",
        border: "1px solid",
        borderColor: "green.success300",
      },
      title: {
        color: "green.success700",
      },
      description: {
        color: "green.success700",
      },
    },
    error: {
      container: {
        backgroundColor: "red.50",
        border: "1px solid",
        borderColor: "red.error300",
      },
      title: {
        color: "red.error700",
      },
      description: {
        color: "red.error700",
      },
    },
  },

  defaultProps: {
    variant: "success",
    size: "md",
  },
});
