import { useTranslation } from "react-i18next";
import LoginLayout from "../layouts/LoginLayout";
import { Heading } from "../components/Atoms/Heading/Heading";

import { useNavigate } from "react-router-dom";
import { Input } from "../components/Atoms/Input/Input";
import { object, string } from "yup";
import { useState } from "react";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { Button } from "../components/Atoms/Button/Button";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useSendForgotPasswordEmail } from "../hooks/user";

let forgotPasswordSchema = object({
  email: string()
    .required({ field: "email", translationKey: "validation.requiredFiled" })
    .email({ field: "email", translationKey: "validation.invalidEmail" }),
});

export const ForgotPasswordPage = () => {
  const [form, setForm] = useState({
    email: "",
  });
  const [formErrors, setFormErrors] = useState({
    email: "",
  });
  const [isSent, setIsSent] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sendForgotPasswordEmail, isPendingSendingEmail } =
    useSendForgotPasswordEmail();

  const handleNext = async () => {
    try {
      await forgotPasswordSchema.validate(form, { abortEarly: false });
      const { isEmailSent } = await sendForgotPasswordEmail(form.email);
      setIsSent(!!isEmailSent);
    } catch (error: any) {
      let errors: any = {};
      if (error.errors.length) {
        error.errors.forEach(
          (err: { field: string; translationKey: string }) => {
            errors[err.field as keyof typeof errors] = t(err.translationKey);
          }
        );
        setFormErrors(errors);
      }
    }
  };

  return (
    <LoginLayout>
      {isSent ? (
        <Flex sx={{ flexDirection: "column" }}>
          <Heading sx={{ my: "40px" }}>{t("login.checkYourEmail")}</Heading>
          <Image height={65} src="/CheckIconChecked.svg" />
          <Box sx={{ mt: "20px", width: ["100%", "420px"] }}>
            <Text sx={{ display: "inline" }}>
              {t("login.forgotPasswordEmailSent")}
            </Text>
            <Text
              sx={{
                display: "inline",
                color: "green.foundationNormal",
                fontWeight: "bold",
              }}
            >
              {form.email}
            </Text>
          </Box>
        </Flex>
      ) : (
        <>
          <Heading>{t("login.forgotPassword")}</Heading>
          <Text sx={{ marginBottom: "40px", width: ["100%", "420px"] }}>
            {t("login.forgotPasswordDescription")}
          </Text>
          <Input
            placeholder={t("profile.emailPlaceholder")}
            label={t("profile.email")}
            size="md"
            onChange={(value) => {
              setForm({ ...form, email: value });
              if (value && value.trim())
                setFormErrors({ ...formErrors, email: "" });
            }}
            value={form.email}
            errorMessage={formErrors.email}
          />
          <Flex
            sx={{
              width: "100%",
              flexDir: "row",
              justifyContent: "space-between",
              alignItems: "center",
              mt: "40px",
            }}
          >
            <Button
              variant="secondary"
              leftIcon={<ArrowBackIcon />}
              onClick={() => {
                navigate("/login");
              }}
              isDisabled={isPendingSendingEmail}
            >
              {t("login.backToLogin")}
            </Button>
            <Button
              sx={{ ml: 2 }}
              onClick={() => handleNext()}
              isDisabled={!form.email || isPendingSendingEmail}
            >
              {t("login.sendEmail")}
            </Button>
          </Flex>
        </>
      )}
    </LoginLayout>
  );
};
