import { Flex, SystemStyleObject, Text } from "@chakra-ui/react";
import { ReactNode } from "react";

interface SectionItemProps {
  children?: ReactNode;
  label: string | ReactNode;
  sx?: SystemStyleObject;
  labelColor?: string;
}

const SectionItem = ({ label, children, sx, labelColor }: SectionItemProps) => {
  return (
    <Flex
      sx={{
        width: "full",
        paddingBottom: "20px",
        flexDir: ["column", "column", "row"],
        gap: "16px",
        alignItems: "start",
      }}
    >
      <Text
        sx={{
          fontSize: "14px",
          fontWeight: "600",
          lineHeight: "20px",
          color: labelColor ? labelColor : "black.darkText",
          minWidth: "20%",
        }}
      >
        {label}
      </Text>
      <Flex sx={{ w: "full", ...sx }}>{children}</Flex>
    </Flex>
  );
};

export default SectionItem;
