import {
  Modal as ChakraModal,
  Image,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { Button } from "../../Atoms/Button/Button";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onClick: () => void;
  children?: ReactNode | string;
  variant?: "warning" | "success";
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  primaryLabel?: string;
  secondaryLabel?: string;
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "full";
  imageSrc?: string;
  disabled?: boolean;
}

const Modal = ({
  isOpen,
  onClick,
  onClose,
  children,
  variant = "success",
  title,
  subtitle,
  primaryLabel,
  secondaryLabel,
  size = "md",
  imageSrc,
  disabled = false,
}: ModalProps) => {
  return (
    <ChakraModal
      isOpen={isOpen}
      onClose={onClose}
      variant={variant}
      isCentered={true}
      size={size}
    >
      <ModalOverlay />
      <ModalContent>
        {imageSrc && <Image src={imageSrc} height={"50px"} width={"50px"} />}

        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          {subtitle}
          {children}
        </ModalBody>

        <ModalFooter>
          <Button
            variant="tertiary"
            onClick={onClose}
            size="sm"
            sx={{ mr: "12px", backgroundColor: "white !important" }}
            isDisabled={disabled}
          >
            {primaryLabel}
          </Button>
          <Button variant="primary" onClick={onClick} size="sm">
            {secondaryLabel}
          </Button>
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
};

export default Modal;
