import { defineStyleConfig } from "@chakra-ui/react";

export const Button = defineStyleConfig({
  baseStyle: {},

  sizes: {
    sm: {
      fontSize: "14px",
      width: "auto",
    },
    lg: {
      width: ["150px", "200px"],
      minW: ["150px", "200px"],
    },
    full: {
      fontSize: "14px",
      width: "full",
      fontWeight: "700",
      textAlign: "center",
    },
  },

  variants: {
    primary: {
      colorScheme: "teal",
      backgroundColor: "green.foundationNormal",
      color: "white",
      height: "44px",
      _hover: {
        _disabled: { backgroundColor: "green.foundationNormal" },
      },
    },
    secondary: {
      color: "green.foundationNormal",
      border: "1px solid #60B5AE",
      height: "44px",
    },
    tertiary: {
      color: "green.black.darkText",
      border: "1px solid #D0D5DD",
      height: "44px",
    },
    quaternary: {
      color: "#60B5AE",
      fontSize: "14px",
      fontWeight: 600,
    },
  },

  defaultProps: {
    variant: "primary",
  },
});
