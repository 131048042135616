import { Label } from "./Labels";
import { UserPrivileges } from "./Privileges";

export type Workspace = {
  id?: number;
  ownerId?: number;
  name: string;
  image?: File;
  imageUrl?: string;
  isSuspended?: boolean;
  subscriptionPlanId?: number;
  role?: string;
  jobStatuses?: JobStatus[];
  privileges?: UserPrivileges;
  labels?: Label[];
};

export enum WorkspaceUserRoles {
  OWNER = "owner",
  ADMIN = "admin",
  BASIC_USER = "basicUser",
}

export type JobStatus = {
  id?: number;
  workspaceId?: number;
  name?: string;
  order?: number;
};
