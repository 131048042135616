import { Heading as ChakraHeading, SystemStyleObject } from "@chakra-ui/react";
import { ReactNode } from "react";

export type HeadingProps = {
  variant?: "primary" | "secondary";
  sx?: SystemStyleObject;
  children: ReactNode | string;
  size?: "sm" | "md" | "lg";
};

export const Heading = ({
  variant = "primary",
  sx,
  children,
  size = "lg",
}: HeadingProps) => {
  return (
    <ChakraHeading variant={variant} sx={sx} size={size}>
      {children}
    </ChakraHeading>
  );
};
