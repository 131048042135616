import { defineStyleConfig } from "@chakra-ui/react";

export const Link = defineStyleConfig({
  baseStyle: {},

  sizes: {},

  variants: {
    primary: { color: "#60B5AE", marginX: "4px" },
  },

  defaultProps: {
    variant: "primary",
  },
});
