import { useTranslation } from "react-i18next";
import { AppContext } from "../context/AppContext";
import { useContext, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  acceptInvitationMutate,
  createWorkspaceMutate,
  deleteWorkspaceMutate,
  getAllWorkspacesQuery,
  getUserDataMutate,
  getWorkspaceUsersQuery,
  inviteUserToWorkspaceMutate,
  leaveWorkspaceMutate,
  removeUserFromWorkspaceMutate,
  updateWorkspaceMutate,
} from "../services/workspace";
import { errorCodeToTranslationMapper } from "../mappers/ErrorCodeMapper";
import { AlertMessageStatus } from "../components/Molecules/AlertMessage/AlertMessage";
import { ErrorCodes } from "../types/ErrorCodes";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

export const useCreateWorkspace = () => {
  const { t } = useTranslation();
  const {
    setAlertMessage,
    showAlertMessage,
    closeAlertMessage,
    setCurrentWorkspace,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutateAsync: createWorkspace, isPending: isPendingCreateWorkspace } =
    useMutation({
      mutationFn: createWorkspaceMutate,
      onSuccess: (data) => {
        if (showAlertMessage) closeAlertMessage();
        if (data.workspace?.id) {
          setAlertMessage(
            {
              title: t("success.workspaceCreated"),
              variant: AlertMessageStatus.SUCCESS,
            },
            true
          );
          setCurrentWorkspace(data.workspace.id);
          queryClient.invalidateQueries({ queryKey: ["currentUser"] });
        }
        if (data.errorCode) {
          if (data.errorCode === ErrorCodes.SESSION_EXPIRED) {
            Object.keys(Cookies.get()).forEach(function (cookieName) {
              Cookies.remove(cookieName);
            });
            navigate("/login");
          }
          setAlertMessage(
            {
              title: t(errorCodeToTranslationMapper[data.errorCode]),
              description: data.error
                ? data.error
                : t("errors.ERROR_CODE", { code: data.errorCode }),
              variant: AlertMessageStatus.ERROR,
            },
            true
          );
        }
      },
    });

  return { createWorkspace, isPendingCreateWorkspace };
};

export const useUpdateWorkspace = () => {
  const { t } = useTranslation();
  const { setAlertMessage, showAlertMessage, closeAlertMessage } =
    useContext(AppContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutateAsync: updateWorkspace, isPending: isPendingUpdateWorkspace } =
    useMutation({
      mutationFn: updateWorkspaceMutate,
      onSuccess: (data) => {
        if (showAlertMessage) closeAlertMessage();
        if (data.workspace) {
          setAlertMessage(
            {
              title: t("workspace.updatedSuccessfully"),
              variant: AlertMessageStatus.SUCCESS,
            },
            true
          );

          queryClient.invalidateQueries({ queryKey: ["currentUser"] });
        }
        if (data.errorCode) {
          if (data.errorCode === ErrorCodes.SESSION_EXPIRED) {
            Object.keys(Cookies.get()).forEach(function (cookieName) {
              Cookies.remove(cookieName);
            });
            navigate("/login");
          }
          setAlertMessage(
            {
              title: t(errorCodeToTranslationMapper[data.errorCode]),
              description: data.error
                ? data.error
                : t("errors.ERROR_CODE", { code: data.errorCode }),
              variant: AlertMessageStatus.ERROR,
            },
            true
          );
        }
      },
    });

  return { updateWorkspace, isPendingUpdateWorkspace };
};

export const useDeleteWorkspace = () => {
  const { t } = useTranslation();
  const { setAlertMessage, showAlertMessage, closeAlertMessage } =
    useContext(AppContext);
  const navigate = useNavigate();

  const { mutateAsync: deleteWorkspace, isPending: isPendingDeleteWorkspace } =
    useMutation({
      mutationFn: deleteWorkspaceMutate,
      onSuccess: (data) => {
        if (showAlertMessage) closeAlertMessage();
        if (data.isWorkspaceDeleted) {
          setAlertMessage(
            {
              title: t("workspace.deletedSuccessfully"),
              variant: AlertMessageStatus.SUCCESS,
            },
            true
          );

          navigate("/profile");
        }
        if (data.errorCode) {
          if (data.errorCode === ErrorCodes.SESSION_EXPIRED) {
            Object.keys(Cookies.get()).forEach(function (cookieName) {
              Cookies.remove(cookieName);
            });
            navigate("/login");
          }
          setAlertMessage(
            {
              title: t(errorCodeToTranslationMapper[data.errorCode]),
              description: data.error
                ? data.error
                : t("errors.ERROR_CODE", { code: data.errorCode }),
              variant: AlertMessageStatus.ERROR,
            },
            true
          );
        }
      },
    });

  return { deleteWorkspace, isPendingDeleteWorkspace };
};

export const useGetWorkspaceUsers = ({
  workspaceId,
  filterString,
  roleFilter,
  page,
}: {
  workspaceId?: number;
  filterString?: string;
  roleFilter?: string;
  page?: number;
}) => {
  const { setAlertMessage } = useContext(AppContext);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const accessToken = Cookies.get("acc_tkn");
  const refreshToken = Cookies.get("ref_tkn");

  const { data, isFetching } = useQuery({
    queryKey: ["workspaceUsers", filterString, page, roleFilter, workspaceId],
    queryFn: async () =>
      await getWorkspaceUsersQuery({
        roleFilter,
        page,
        filterString,
        workspaceId,
      }),
    enabled: !!accessToken && !!refreshToken && !!workspaceId,
  });

  useEffect(() => {
    if (data?.errorCode) {
      if (data.errorCode === ErrorCodes.SESSION_EXPIRED) {
        Object.keys(Cookies.get()).forEach(function (cookieName) {
          Cookies.remove(cookieName);
        });
        navigate("/login");
      }
      setAlertMessage(
        {
          title: t(errorCodeToTranslationMapper[data.errorCode]),
          description: data.error
            ? data.error
            : t("errors.ERROR_CODE", { code: data.errorCode }),
          variant: AlertMessageStatus.ERROR,
        },
        false
      );
    }
  }, [data, setAlertMessage, t, navigate]);

  const invalidateQueries = () => {
    queryClient.invalidateQueries({ queryKey: ["workspaceUsers"] });
  };

  return {
    users: data?.users,
    isLastPage: data?.isLastPage,
    userCount: data?.userCount,
    isFetchingWorkspaceUsers: isFetching,
    invalidateQueries,
    filteredUsersCount: data?.filteredUsersCount,
  };
};

export const useRemoveUserFromWorkspace = () => {
  const { t } = useTranslation();
  const { setAlertMessage, showAlertMessage, closeAlertMessage } =
    useContext(AppContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    mutateAsync: removeUserFromWorkspace,
    isPending: isPendingRemoveUser,
  } = useMutation({
    mutationFn: removeUserFromWorkspaceMutate,
    onSuccess: (data) => {
      if (showAlertMessage) closeAlertMessage();
      if (data.isUserRemoved) {
        setAlertMessage(
          {
            title: t("users.removeUserSuccess"),
            variant: AlertMessageStatus.SUCCESS,
          },
          true
        );
      }
      if (data.errorCode) {
        if (data.errorCode === ErrorCodes.SESSION_EXPIRED) {
          Object.keys(Cookies.get()).forEach(function (cookieName) {
            Cookies.remove(cookieName);
          });
          navigate("/login");
        }
        setAlertMessage(
          {
            title: t(errorCodeToTranslationMapper[data.errorCode]),
            description: data.error
              ? data.error
              : t("errors.ERROR_CODE", { code: data.errorCode }),
            variant: AlertMessageStatus.ERROR,
          },
          true
        );
      }
    },
  });
  const invalidateQueries = () => {
    queryClient.invalidateQueries({ queryKey: ["workspaceUsers"] });
  };

  return { removeUserFromWorkspace, isPendingRemoveUser, invalidateQueries };
};

export const useLeaveWorkspace = () => {
  const { t } = useTranslation();
  const { setAlertMessage, showAlertMessage, closeAlertMessage } =
    useContext(AppContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutateAsync: leaveWorkspace, isPending: isPendingLeaveWorkspace } =
    useMutation({
      mutationFn: leaveWorkspaceMutate,
      onSuccess: (data) => {
        if (showAlertMessage) closeAlertMessage();
        if (data.isLeavingSuccessful) {
          setAlertMessage(
            {
              title: t("users.removeUserSuccess"),
              variant: AlertMessageStatus.SUCCESS,
            },
            true
          );
        }
        if (data.errorCode) {
          if (data.errorCode === ErrorCodes.SESSION_EXPIRED) {
            Object.keys(Cookies.get()).forEach(function (cookieName) {
              Cookies.remove(cookieName);
            });
            navigate("/login");
          }
          setAlertMessage(
            {
              title: t(errorCodeToTranslationMapper[data.errorCode]),
              description: data.error
                ? data.error
                : t("errors.ERROR_CODE", { code: data.errorCode }),
              variant: AlertMessageStatus.ERROR,
            },
            true
          );
        }
      },
    });
  const invalidateQueries = () => {
    queryClient.invalidateQueries({ queryKey: ["currentUser"] });
  };

  return { leaveWorkspace, isPendingLeaveWorkspace, invalidateQueries };
};

export const useGetUserDetails = ({
  workspaceId,
  userId,
}: {
  workspaceId?: number;
  userId?: number;
}) => {
  const { setAlertMessage } = useContext(AppContext);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const accessToken = Cookies.get("acc_tkn");
  const refreshToken = Cookies.get("ref_tkn");

  const { data, isFetching } = useQuery({
    queryKey: ["userDetails", userId, workspaceId],
    queryFn: async () =>
      await getUserDataMutate({
        userId,
        workspaceId,
      }),
    enabled: !!accessToken && !!refreshToken && !!workspaceId && !!userId,
  });

  useEffect(() => {
    if (data?.errorCode) {
      if (data?.errorCode === ErrorCodes.SESSION_EXPIRED) {
        Object.keys(Cookies.get()).forEach(function (cookieName) {
          Cookies.remove(cookieName);
        });
        navigate("/login");
      } else {
        navigate("/users");
        setAlertMessage({
          title: t(errorCodeToTranslationMapper[data.errorCode]),
          description: data.error
            ? data.error
            : t("errors.ERROR_CODE", { code: data.errorCode }),
          variant: AlertMessageStatus.ERROR,
        });
      }
    }
  }, [data, setAlertMessage, t, navigate]);

  const invalidateQueries = () => {
    queryClient.invalidateQueries({ queryKey: ["userDetails"] });
  };

  return {
    user: data?.user,
    isFetchingUserData: isFetching,
    invalidateQueries,
  };
};

export const useInviteUserToWorkspace = () => {
  const { t } = useTranslation();
  const { setAlertMessage, showAlertMessage, closeAlertMessage } =
    useContext(AppContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutateAsync: inviteUser, isPending: isPendingInviteUser } =
    useMutation({
      mutationFn: inviteUserToWorkspaceMutate,
      onSuccess: (data) => {
        if (showAlertMessage) closeAlertMessage();
        if (data.isEmailSent) {
          setAlertMessage(
            {
              title: t("users.inviteUserSuccess"),
              variant: AlertMessageStatus.SUCCESS,
            },
            true
          );
        }
        if (data.errorCode) {
          if (data.errorCode === ErrorCodes.SESSION_EXPIRED) {
            Object.keys(Cookies.get()).forEach(function (cookieName) {
              Cookies.remove(cookieName);
            });
            navigate("/login");
          }
          setAlertMessage(
            {
              title: t(errorCodeToTranslationMapper[data.errorCode]),
              description: data.error
                ? data.error
                : t("errors.ERROR_CODE", { code: data.errorCode }),
              variant: AlertMessageStatus.ERROR,
            },
            true
          );
        }
      },
    });
  const invalidateQueries = () => {
    queryClient.invalidateQueries({ queryKey: ["workspaceUsers"] });
  };

  return { inviteUser, isPendingInviteUser, invalidateQueries };
};

export const useAcceptInvitation = () => {
  useContext(AppContext);

  const {
    mutateAsync: acceptInvitation,
    isPending: isPendingAcceptInvitation,
    status,
  } = useMutation({
    mutationFn: acceptInvitationMutate,
  });

  return { acceptInvitation, isPendingAcceptInvitation, status };
};

export const useGetWorkspaces = ({
  filter,
  role,
  page,
}: {
  filter?: string;
  role?: string;
  page?: number;
}) => {
  const { setAlertMessage } = useContext(AppContext);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const accessToken = Cookies.get("acc_tkn");
  const refreshToken = Cookies.get("ref_tkn");

  const { data, isFetching } = useQuery({
    queryKey: ["currentUser", "workspaces", filter, page, role],
    queryFn: async () =>
      await getAllWorkspacesQuery({
        role,
        page,
        filter,
      }),
    enabled: !!accessToken && !!refreshToken,
  });

  useEffect(() => {
    if (data?.errorCode) {
      setAlertMessage(
        {
          title: t(errorCodeToTranslationMapper[data.errorCode]),
          description: data.error
            ? data.error
            : t("errors.ERROR_CODE", { code: data.errorCode }),
          variant: AlertMessageStatus.ERROR,
        },
        false
      );
    }
  }, [data, setAlertMessage, t]);

  const invalidateQueries = () => {
    queryClient.invalidateQueries({ queryKey: ["currentUser"] });
  };

  return {
    workspaces: data?.workspaces,
    isLastPage: data?.isLastPage,
    allWorkspacesCount: data?.allWorkspacesCount,
    isFetchingWorkspaces: isFetching,
    invalidateQueries,
    filteredWorkspacesCount: data?.filteredWorkspacesCount,
  };
};
