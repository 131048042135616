import { Flex } from "@chakra-ui/react";

export type StepsPaginationProps = {
  numberOfSteps: number;
  currentStep: number;
};

export const StepsPagination = ({
  numberOfSteps,
  currentStep,
}: StepsPaginationProps) => {
  return (
    <Flex
      sx={{ marginBottom: "88px", justifyContent: "center", width: "100%" }}
    >
      {Array(numberOfSteps)
        .fill(currentStep)
        .map((_step, index) => {
          return (
            <Flex
              key={index}
              sx={{
                height: "8px",
                borderRadius: "4px",
                width: "50px",
                mx: "6px",
                backgroundColor:
                  index + 1 === currentStep
                    ? "green.foundationNormal"
                    : "gray.light",
              }}
            ></Flex>
          );
        })}
    </Flex>
  );
};
