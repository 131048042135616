import {
  Alert,
  AlertDescription,
  AlertTitle,
  Flex,
  Image,
} from "@chakra-ui/react";
import { AppContext } from "../../../context/AppContext";
import { useContext } from "react";
import { useCheckIsMobile } from "../../../lib/utils";

export enum AlertMessageStatus {
  SUCCESS = "success",
  ERROR = "error",
}

export type AlertMessageProps = {
  title?: string;
  variant?: AlertMessageStatus.SUCCESS | AlertMessageStatus.ERROR;
  size?: "sm" | "md" | "lg" | "xl";
  description?: string;
  position?: "top" | "bottom";
};

export const AlertMessage = ({
  title,
  variant = AlertMessageStatus.SUCCESS,
  size,
  description,
  position = "bottom",
}: AlertMessageProps) => {
  const { closeAlertMessage } = useContext(AppContext);
  const isMobile = useCheckIsMobile();
  return (
    <Flex
      sx={{
        position: "sticky",
        top: position === "bottom" ? "90vh" : "5vh",
        zIndex: 2,
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        height: 0,
      }}
    >
      <Alert variant={variant} size={isMobile ? "xl" : size}>
        <Image
          src={
            variant === AlertMessageStatus.ERROR
              ? "/AlertErrorCheckmark.svg"
              : "/AlertSuccessCheckmark.svg"
          }
          height={["30px", "40px"]}
        />
        <Flex
          sx={{
            flexDir: "column",
            flexGrow: 1,
            mx: 3,
            height: "100%",
            justifyContent: "center",
          }}
        >
          <AlertTitle>{title}</AlertTitle>
          {description && <AlertDescription>{description}</AlertDescription>}
        </Flex>
        <Image
          src={
            variant === AlertMessageStatus.ERROR
              ? "/ButtonCloseError.svg"
              : "/ButtonCloseSuccess.svg"
          }
          height={["30px", "40px"]}
          onClick={() => closeAlertMessage()}
          sx={{ cursor: "pointer" }}
        />
      </Alert>
    </Flex>
  );
};
