import {
  TableContainer,
  Table as ChakraTable,
  SystemStyleObject,
} from "@chakra-ui/react";
import { ReactNode } from "react";

export type TableProps = {
  sx?: SystemStyleObject;
  children: ReactNode | string;
  size?: "sm" | "md" | "lg" | "full";
  variant?: "status" | "simple" | "users" | "jobs";
};

export const Table = ({
  sx,
  children,
  size = "full",
  variant = "simple",
}: TableProps) => {
  return (
    <TableContainer w={"100%"}>
      <ChakraTable sx={{ ...sx }} size={size} variant={variant}>
        {children}
      </ChakraTable>
    </TableContainer>
  );
};
