/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import LoginLayout from "../layouts/LoginLayout";
import { Heading } from "../components/Atoms/Heading/Heading";
import { Input } from "../components/Atoms/Input/Input";
import { CheckField } from "../components/Atoms/CheckField/CheckField";
import { useContext, useEffect, useState } from "react";
import { Constants } from "../lib/constants";
import { Button } from "../components/Atoms/Button/Button";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { isPasswordValid } from "../lib/utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useResetPassword } from "../hooks/user";
import { Flex, Box, Text, Image } from "@chakra-ui/react";
import { AppContext } from "../context/AppContext";
import { AlertMessageStatus } from "../components/Molecules/AlertMessage/AlertMessage";

export const ResetPasswordPage = () => {
  const [password, setPassword] = useState<string>("");
  const [confirmedPassword, setConfirmedPassword] = useState<string>("");
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const { setAlertMessage } = useContext(AppContext);

  const token = searchParams.get("token");

  const { resetPassword, isPendingResetPassword } = useResetPassword();

  const handleNext = async () => {
    if (!isPasswordValid(password) || password !== confirmedPassword || !token)
      return;
    const { isPasswordUpdated } = await resetPassword({ password, token });
    setIsUpdated(!!isPasswordUpdated);
  };

  useEffect(() => {
    if (!token) {
      setAlertMessage(
        {
          title: t("errors.RESET_PASSWORD_LINK_INVALID"),
          variant: AlertMessageStatus.ERROR,
        },
        true
      );
      navigate("/login");
    }
  }, []);

  return (
    <LoginLayout>
      <Heading sx={{ mb: "40px", width: ["100%", "420px"] }}>
        {t("login.resetPassword")}
      </Heading>
      {isUpdated ? (
        <Flex sx={{ flexDirection: "column" }}>
          <Image height={65} src="/CheckIconChecked.svg" />
          <Box sx={{ mt: "20px", width: ["100%", "420px"] }}>
            <Text sx={{ display: "inline" }}>
              {t("login.resetPasswordSuccess")}
            </Text>
          </Box>
          <Button
            variant="primary"
            onClick={() => navigate("/login")}
            sx={{ width: "100%", mt: "40px" }}
          >
            {t("login.login")}
          </Button>
        </Flex>
      ) : (
        <>
          <Input
            type="password"
            label={t("register.stepPasswordTitle")}
            placeholder={t("register.passwordTDescription")}
            value={password}
            onChange={(value) => setPassword(value)}
          />
          <Input
            type="password"
            label={t("register.confirmPassword")}
            placeholder={t("register.confirmPassword")}
            value={confirmedPassword}
            onChange={(value) => setConfirmedPassword(value)}
          />

          <CheckField
            label={t("validation.passwordMinSize")}
            isChecked={password.length >= Constants.MinPasswordLength}
          />
          <CheckField
            label={t("validation.passwordRule")}
            isChecked={!!(/\d/.test(password) && /[A-Z]/.test(password))}
          />
          <CheckField
            label={t("validation.passwordMustMatch")}
            isChecked={!!password && password === confirmedPassword}
          />
          <Button
            variant="primary"
            rightIcon={<ArrowForwardIcon />}
            isDisabled={
              !isPasswordValid(password) ||
              password !== confirmedPassword ||
              isPendingResetPassword
            }
            onClick={handleNext}
            sx={{ width: "100%", mt: "40px" }}
          >
            {t("common.next")}
          </Button>
        </>
      )}
    </LoginLayout>
  );
};
