import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AlertMessageStatus } from "../components/Molecules/AlertMessage/AlertMessage";
import { AppContext } from "../context/AppContext";
import { errorCodeToTranslationMapper } from "../mappers/ErrorCodeMapper";
import {
  createJobMutate,
  deleteJobMutate,
  getJobByIdMutate,
  getJobsQuery,
  getUsersToAssignQuery,
  updateJobLabelsMutate,
} from "../services/job";
import { ErrorCodes } from "../types/ErrorCodes";
import { useNavigate } from "react-router-dom";

export const useGetJobs = ({
  workspaceId,
  filters,
}: {
  workspaceId?: number;
  filters: {
    filter?: string;
    assignedUsers?: string;
    hasReminder?: number;
    statusId?: number;
    page?: number;
  };
}) => {
  const { setAlertMessage } = useContext(AppContext);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const accessToken = Cookies.get("acc_tkn");
  const refreshToken = Cookies.get("ref_tkn");

  const { data, isFetching } = useQuery({
    queryKey: [
      "jobs",
      filters.filter,
      filters.page,
      filters.assignedUsers,
      filters.statusId,
      filters.hasReminder,
      workspaceId,
    ],
    queryFn: async () =>
      await getJobsQuery({
        filters,
        workspaceId,
      }),
    enabled: !!accessToken && !!refreshToken && !!workspaceId,
  });

  useEffect(() => {
    if (data?.errorCode) {
      if (data.errorCode === ErrorCodes.SESSION_EXPIRED) {
        Object.keys(Cookies.get()).forEach(function (cookieName) {
          Cookies.remove(cookieName);
        });
        navigate("/login");
      }
      setAlertMessage(
        {
          title: t(errorCodeToTranslationMapper[data.errorCode]),
          description: data.error
            ? data.error
            : t("errors.ERROR_CODE", { code: data.errorCode }),
          variant: AlertMessageStatus.ERROR,
        },
        false
      );
    }
  }, [data, setAlertMessage, t, navigate]);

  const invalidateQueries = () => {
    queryClient.invalidateQueries({ queryKey: ["jobs"] });
  };

  return {
    jobs: data?.jobs,
    isLastPage: data?.isLastPage,
    filteredJobsCount: data?.filteredJobsCount,
    isFetchingJobs: isFetching,
    allJobsCount: data?.allJobsCount,
    invalidateQueries,
  };
};

export const useGetUsersToAssign = ({
  workspaceId,
  filter,
}: {
  workspaceId?: number;
  filter?: string;
}) => {
  const { setAlertMessage } = useContext(AppContext);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const accessToken = Cookies.get("acc_tkn");
  const refreshToken = Cookies.get("ref_tkn");

  const { data, isFetching } = useQuery({
    queryKey: ["usersToAssign", filter, workspaceId],
    queryFn: async () =>
      await getUsersToAssignQuery({
        filter,
        workspaceId,
      }),
    enabled: !!accessToken && !!refreshToken && !!workspaceId,
  });

  useEffect(() => {
    if (data?.errorCode) {
      if (data.errorCode === ErrorCodes.SESSION_EXPIRED) {
        Object.keys(Cookies.get()).forEach(function (cookieName) {
          Cookies.remove(cookieName);
        });
        navigate("/login");
      }
      setAlertMessage(
        {
          title: t(errorCodeToTranslationMapper[data.errorCode]),
          description: data.error
            ? data.error
            : t("errors.ERROR_CODE", { code: data.errorCode }),
          variant: AlertMessageStatus.ERROR,
        },
        false
      );
    }
  }, [data, setAlertMessage, t, navigate]);

  const invalidateQueries = () => {
    queryClient.invalidateQueries({ queryKey: ["usersToAssign"] });
  };

  return {
    users: data?.users,
    isFetchingUsers: isFetching,
    invalidateQueries,
  };
};

export const useDeleteJob = () => {
  const { t } = useTranslation();
  const { setAlertMessage, showAlertMessage, closeAlertMessage } =
    useContext(AppContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutateAsync: deleteJob, isPending: isPendingDeleteJob } = useMutation(
    {
      mutationFn: deleteJobMutate,
      onSuccess: (data) => {
        if (showAlertMessage) closeAlertMessage();
        if (data.isJobDeleted) {
          setAlertMessage(
            {
              title: t("success.jobRemoved"),
              variant: AlertMessageStatus.SUCCESS,
            },
            true
          );
        }
        if (data.errorCode) {
          if (data.errorCode === ErrorCodes.SESSION_EXPIRED) {
            Object.keys(Cookies.get()).forEach(function (cookieName) {
              Cookies.remove(cookieName);
            });
            navigate("/login");
          }
          setAlertMessage(
            {
              title: t(errorCodeToTranslationMapper[data.errorCode]),
              description: data.error
                ? data.error
                : t("errors.ERROR_CODE", { code: data.errorCode }),
              variant: AlertMessageStatus.ERROR,
            },
            true
          );
        }
      },
    }
  );
  const invalidateQueries = () => {
    queryClient.invalidateQueries({ queryKey: ["jobs"] });
  };

  return { deleteJob, isPendingDeleteJob, invalidateQueries };
};

export const useCreateJob = () => {
  const { t } = useTranslation();
  const { setAlertMessage, showAlertMessage, closeAlertMessage } =
    useContext(AppContext);
  const navigate = useNavigate();

  const { mutateAsync: createJob, isPending: isPendingCreateJob } = useMutation(
    {
      mutationFn: createJobMutate,
      onSuccess: (data) => {
        if (showAlertMessage) closeAlertMessage();
        if (data.job?.id) {
          setAlertMessage(
            {
              title: t("success.jobCreated"),
              variant: AlertMessageStatus.SUCCESS,
            },
            true
          );

          navigate("/job");
        }
        if (data.errorCode) {
          if (data.errorCode === ErrorCodes.SESSION_EXPIRED) {
            Object.keys(Cookies.get()).forEach(function (cookieName) {
              Cookies.remove(cookieName);
            });
            navigate("/login");
          }
          setAlertMessage(
            {
              title: t(errorCodeToTranslationMapper[data.errorCode]),
              description: data.error
                ? data.error
                : t("errors.ERROR_CODE", { code: data.errorCode }),
              variant: AlertMessageStatus.ERROR,
            },
            true
          );
        }
      },
    }
  );

  return { createJob, isPendingCreateJob };
};

export const useGetJobById = ({ jobId }: { jobId?: number }) => {
  const { setAlertMessage } = useContext(AppContext);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const accessToken = Cookies.get("acc_tkn");
  const refreshToken = Cookies.get("ref_tkn");

  const { data, isFetching } = useQuery({
    queryKey: ["currentJob"],
    queryFn: async () => await getJobByIdMutate(jobId),
    enabled: !!accessToken && !!refreshToken && !!jobId,
  });

  useEffect(() => {
    if (data?.errorCode) {
      if (data.errorCode === ErrorCodes.SESSION_EXPIRED) {
        Object.keys(Cookies.get()).forEach(function (cookieName) {
          Cookies.remove(cookieName);
        });
        navigate("/login");
      }
      setAlertMessage(
        {
          title: t(errorCodeToTranslationMapper[data.errorCode]),
          description: data.error
            ? data.error
            : t("errors.ERROR_CODE", { code: data.errorCode }),
          variant: AlertMessageStatus.ERROR,
        },
        false
      );
    }
  }, [data, setAlertMessage, t, navigate]);

  const invalidateQueries = () => {
    queryClient.invalidateQueries({ queryKey: ["currentJob"] });
  };

  return {
    job: data?.job,
    jobStatuses: data?.jobStatuses,
    isFetchingJob: isFetching,
    invalidateQueries,
  };
};

export const useUpdateJobLabels = () => {
  const { t } = useTranslation();
  const { setAlertMessage, showAlertMessage, closeAlertMessage } =
    useContext(AppContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutateAsync: updateJobLabels, isPending: isPendingUpdateJobLabels } =
    useMutation({
      mutationFn: updateJobLabelsMutate,
      onSuccess: (data) => {
        if (showAlertMessage) closeAlertMessage();
        if (data.jobLabels) {
          setAlertMessage(
            {
              title: t("success.jobLabelEdited"),
              variant: AlertMessageStatus.SUCCESS,
            },
            true
          );
          queryClient.invalidateQueries({ queryKey: ["currentJob"] });
        }
        if (data.errorCode) {
          if (data.errorCode === ErrorCodes.SESSION_EXPIRED) {
            Object.keys(Cookies.get()).forEach(function (cookieName) {
              Cookies.remove(cookieName);
            });
            navigate("/login");
          }
          setAlertMessage(
            {
              title: t(errorCodeToTranslationMapper[data.errorCode]),
              description: data.error
                ? data.error
                : t("errors.ERROR_CODE", { code: data.errorCode }),
              variant: AlertMessageStatus.ERROR,
            },
            true
          );
        }
      },
    });

  return { updateJobLabels, isPendingUpdateJobLabels };
};
