import { Flex, Text } from "@chakra-ui/react";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../Atoms/Button/Button";
import { FaPlus } from "react-icons/fa";
import Modal from "../../Molecules/Modal/Modal";
import { Input } from "../../Atoms/Input/Input";
import { AppContext } from "../../../context/AppContext";
import Select, { Option } from "../../Molecules/Select/Select";
import { Constants } from "../../../lib/constants";
import { string } from "yup";
import { LabelType } from "../../../types/Labels";
import { useAddLabel, useGetWorkspaceLabels } from "../../../hooks/label";
import LabelsTable from "./LabelsTable";

export const jobStatusSchema = string()
  .required({ field: "name", translationKey: "validation.requiredFiled" })
  .max(Constants.MaxNumberOFCharacters, {
    field: "name",
    translationKey: "validation.maxNumberOfCharactersExceeded",
  });

const LabelsTab = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const { currentWorkspace } = useContext(AppContext);
  const [newInputName, setNewInputName] = useState<string>("");
  const [newInputNameError, setNewInputNameError] = useState<string>("");
  const { addLabel, isPendingAddingLabel } = useAddLabel();
  const { labels, isFetchingWorkspaceLabels, invalidateQueries } =
    useGetWorkspaceLabels({ workspaceId: currentWorkspace?.id });

  const inputTypeOptions = [
    {
      value: LabelType.singleLine,
      label: t("workspace.singleLine"),
    },
    {
      value: LabelType.textBox,
      label: t("workspace.textBox"),
    },
  ];

  const [selectedInputTypeOption, setSelectedInputTypeOption] =
    useState<Option>(inputTypeOptions[0]);

  const handleAddNewInput = async () => {
    try {
      await jobStatusSchema.validate(newInputName);

      await addLabel({
        name: newInputName,
        type: selectedInputTypeOption.value as LabelType,
        workspaceId: currentWorkspace?.id,
      });

      invalidateQueries();

      setShowModal(false);
      setNewInputName("");
      setNewInputNameError("");
    } catch (error: any) {
      error.errors.forEach((err: { field: string; translationKey: string }) => {
        setNewInputNameError(t(err.translationKey));
      });
    }
  };

  return (
    <Flex sx={{ flexDir: "column", width: "100%", gap: "24px" }}>
      <Button
        size="full"
        leftIcon={<FaPlus color="white" />}
        onClick={() => setShowModal(true)}
        isDisabled={isPendingAddingLabel || isFetchingWorkspaceLabels}
      >
        {t("workspace.newJobInput")}
      </Button>
      <LabelsTable labels={labels} />
      {showModal && (
        <Modal
          onClick={() => handleAddNewInput()}
          onClose={() => {
            setShowModal(false);
            setNewInputNameError("");
            setNewInputName("");
            setSelectedInputTypeOption(inputTypeOptions[0]);
          }}
          isOpen={showModal}
          primaryLabel={t("common.cancel")}
          secondaryLabel={t("common.add")}
          variant="success"
          title={<Text>{t("workspace.newJobInput")}</Text>}
          subtitle={t("workspace.addNewJobInputDescription", {
            name: currentWorkspace?.name,
          })}
          imageSrc="/AddNew.svg"
          disabled={isPendingAddingLabel}
        >
          <Input
            label={t("workspace.newJobInputName")}
            placeholder={t("workspace.addJobInputDescription")}
            onChange={(value) => {
              if (value && value.trim()) setNewInputNameError("");
              setNewInputName(value);
            }}
            value={newInputName}
            formControlWidth={"100%"}
            labelStyles={{ marginTop: "32px" }}
            errorMessage={newInputNameError}
          />
          <Select
            isMulti={false}
            name="userTypes"
            theme="default"
            label={t("workspace.newJobInputType")}
            options={inputTypeOptions}
            withImages={false}
            defaultOption={selectedInputTypeOption}
            onChange={(option) => {
              if (option) setSelectedInputTypeOption(option);
            }}
            isSearchable={false}
          />
        </Modal>
      )}
    </Flex>
  );
};

export default LabelsTab;
