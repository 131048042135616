/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Cookies from "js-cookie";
import { AppContext } from "../../../context/AppContext";
import { useGetCurrentUser } from "../../../hooks/user";

const ProtectedRoute: React.FC<any> = ({ element, ...rest }) => {
  const { setUser, setWorkspaces } = useContext(AppContext);
  const accessToken = Cookies.get("acc_tkn");
  const refreshToken = Cookies.get("ref_tkn");

  const { currentUser, workspaces } = useGetCurrentUser();

  useEffect(() => {
    if (currentUser && currentUser.id) setUser(currentUser);
    if (workspaces && workspaces.length) setWorkspaces(workspaces);
    if (!workspaces || !workspaces.length) {
      setWorkspaces([]);
    }
  }, [currentUser, workspaces]);

  const isAuthenticated = !!accessToken && !!refreshToken;

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" replace />;
};

export default ProtectedRoute;
