import { Flex } from "@chakra-ui/react";
import { ReactNode } from "react";

interface TabContainerProps {
  children?: ReactNode;
}

const TabContainer = ({ children }: TabContainerProps) => {
  return (
    <Flex
      sx={{
        minHeight: "32px",
        height: "100%",
        width: "full",
        flexDir: "row",
        borderBottom: `1px solid`,
        borderColor: "gray.bottom",
        gap: "20px",
        msOverflowStyle: "none",
        scrollbarWidth: "none",
        webkitScrollbar: "none",
      }}
    >
      {children}
    </Flex>
  );
};

export default TabContainer;
