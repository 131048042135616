import { Box, Flex, Text } from "@chakra-ui/react";
import { Image } from "@chakra-ui/react";
import { Heading } from "../../Atoms/Heading/Heading";

export type StepProps = {
  type: "current" | "done" | "remaining";
  title: string;
  description: string;
  addConnector?: boolean;
};

export const Step = ({
  type,
  title,
  description,
  addConnector = true,
}: StepProps) => {
  const getImage = (): string => {
    switch (type) {
      case "current":
        return "/StepCurrent.svg";
      case "done":
        return "/StepDone.svg";
      default:
        return "/StepRemaining.svg";
    }
  };

  return (
    <Flex sx={{ flexDir: "row" }}>
      <Flex
        sx={{
          flexDir: "column",
          justifyContent: "flexStart",
          alignItems: "center",
          height: "78px",
        }}
      >
        <Image src={getImage()} alt="Step" height={"32px"} minW={"32px"} />
        {addConnector && (
          <Box
            sx={{
              width: "2px",
              borderRadius: "2px",
              background: "white",
              height: "46px",
            }}
          ></Box>
        )}
      </Flex>

      <Flex sx={{ flexDir: "column", p: "0 16px" }}>
        <Heading variant="secondary" size="sm">
          {title}
        </Heading>
        <Text>{description}</Text>
      </Flex>
    </Flex>
  );
};
