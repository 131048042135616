import { useTranslation } from "react-i18next";
import LoginLayout from "../layouts/LoginLayout";
import { Heading } from "../components/Atoms/Heading/Heading";
import { Input } from "../components/Atoms/Input/Input";
import { useState } from "react";
import { object, string } from "yup";
import { Button } from "../components/Atoms/Button/Button";
import { Link } from "../components/Atoms/Link/Link";
import { Text } from "@chakra-ui/react";
import { useLoginUser } from "../hooks/user";
import { useNavigate } from "react-router-dom";
import { RegistrationStepsEnum } from "../types/Registration";

let loginSchema = object({
  email: string()
    .required({ field: "email", translationKey: "validation.requiredFiled" })
    .email({ field: "email", translationKey: "validation.invalidEmail" }),
  password: string().required({
    field: "password",
    translationKey: "validation.requiredFiled",
  }),
});

export const LoginPage = () => {
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState({
    email: "",
    password: "",
  });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { loginUser, isPendingLoginUser } = useLoginUser();

  const handleLogin = async () => {
    try {
      await loginSchema.validate(form, { abortEarly: false });
      const { user } = await loginUser({
        email: form.email,
        password: form.password,
      });
      if (user) {
        user.isVerified
          ? navigate("/profile")
          : navigate(`/register?step=${RegistrationStepsEnum.CONFIRM_EMAIL}`);
      }
    } catch (error: any) {
      let errors: any = {};
      if (error.errors.length) {
        error.errors.forEach(
          (err: { field: string; translationKey: string }) => {
            errors[err.field as keyof typeof errors] = t(err.translationKey);
          }
        );
        setFormErrors(errors);
      }
    }
  };

  return (
    <LoginLayout>
      <Heading sx={{ marginBottom: "40px" }}>{t("login.login")}</Heading>
      <Input
        placeholder={t("profile.emailPlaceholder")}
        label={t("profile.email")}
        onChange={(value) => {
          setForm({ ...form, email: value });
          if (value && value.trim())
            setFormErrors({ ...formErrors, email: "" });
        }}
        value={form.email}
        errorMessage={formErrors.email}
      />
      <Input
        type="password"
        label={t("register.passwordTitle")}
        placeholder={t("register.passwordTDescription")}
        value={form.password}
        onChange={(value) => {
          setForm({ ...form, password: value });
          if (value && value.trim())
            setFormErrors({ ...formErrors, password: "" });
        }}
        errorMessage={formErrors.password}
        sx={{ mb: 2 }}
        errorMessageMargin="2px"
      />
      <Link
        href="/forgot-password"
        sx={{
          textAlign: "right",
          mb: "15px",
          fontWeight: "bold",
          fontSize: "14px",
        }}
      >
        {t("login.forgotPassword")}
      </Link>
      <Button
        variant="primary"
        onClick={() => handleLogin()}
        sx={{ width: ["full", "420px"] }}
        isLoading={isPendingLoginUser}
      >
        {t("login.loginBtn")}
      </Button>
      <Text sx={{ textAlign: "center", mt: "30px", fontSize: "14px" }}>
        {t("profile.notRegistered")}
        <Link sx={{ fontWeight: "bold" }} href="/register">
          {t("register.register")}
        </Link>
      </Text>
    </LoginLayout>
  );
};
