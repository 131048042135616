import { Flex, Avatar, Text, Image } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { User } from "../../../types/user";

const UserInfo = () => {
  const { user: currentUser, isSidebarOpen } = useContext(AppContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [user, setUser] = useState<User | undefined>(currentUser || undefined);

  useEffect(() => {
    setUser(currentUser);
  }, [currentUser]);

  return (
    <Flex mt={4} align={"center"} w={"100%"}>
      <Avatar
        w={"45px"}
        h={"45px"}
        src={user?.filePath || undefined}
        name={
          !user?.filePath ? user?.firstName + " " + user?.lastName : undefined
        }
        color={"white"}
      />
      <Flex
        flexDir={"column"}
        mx={3}
        sx={{
          color: "white",
          fontSize: "14px",
          fontWeight: "600",
          flexGrow: 1,
          overflow: "hidden",
          width: "100%",
        }}
      >
        {isSidebarOpen && (
          <>
            <Text
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {user?.firstName}
            </Text>
            <Text
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {user?.lastName}
            </Text>
          </>
        )}
      </Flex>
      {isSidebarOpen && (
        <Image
          src="/LogOut.svg"
          sx={{ cursor: "pointer" }}
          title={t("common.logout")}
          onClick={() => {
            Object.keys(Cookies.get()).forEach(function (cookieName) {
              Cookies.remove(cookieName);
            });
            navigate("/login");
          }}
        />
      )}
    </Flex>
  );
};

export default UserInfo;
