import { Flex, Text } from "@chakra-ui/react";
import { Button } from "../../Atoms/Button/Button";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa";
import { useContext, useState } from "react";
import Modal from "../../Molecules/Modal/Modal";
import { AppContext } from "../../../context/AppContext";
import { Input } from "../../Atoms/Input/Input";
import { string } from "yup";
import { Constants } from "../../../lib/constants";
import JobStatusTable from "./JobStatusTable";
import { useAddJobStatus } from "../../../hooks/jobStatus";

export const jobStatusSchema = string()
  .required({ field: "name", translationKey: "validation.requiredFiled" })
  .max(Constants.MaxNumberOFCharacters, {
    field: "name",
    translationKey: "validation.maxNumberOfCharactersExceeded",
  });

const JobStatusesTab = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const { currentWorkspace } = useContext(AppContext);
  const [newStatusName, setNewStatusName] = useState<string>("");
  const [newStatusNameError, setNewStatusNameError] = useState<string>("");

  const { addJobStatus, isPendingJobStatus } = useAddJobStatus();

  const handleCreateNewStatus = async () => {
    try {
      await jobStatusSchema.validate(newStatusName);
      if (currentWorkspace?.id) {
        await addJobStatus({
          workspaceId: currentWorkspace?.id,
          name: newStatusName,
        });
      }
      setShowModal(false);
      setNewStatusName("");
      setNewStatusNameError("");
    } catch (error: any) {
      error.errors.forEach((err: { field: string; translationKey: string }) => {
        setNewStatusNameError(t(err.translationKey));
      });
    }
  };

  return (
    <Flex sx={{ flexDir: "column", width: "100%", gap: "24px" }}>
      <Button
        size="full"
        leftIcon={<FaPlus color="white" />}
        onClick={() => setShowModal(true)}
        isDisabled={isPendingJobStatus}
      >
        {t("workspace.newStatus")}
      </Button>
      <JobStatusTable />
      {showModal && (
        <Modal
          onClick={() => handleCreateNewStatus()}
          onClose={() => {
            setNewStatusName("");
            setNewStatusNameError("");
            setShowModal(false);
          }}
          isOpen={showModal}
          primaryLabel={t("common.cancel")}
          secondaryLabel={t("common.add")}
          variant="success"
          title={<Text>{t("workspace.addNewStatus")}</Text>}
          subtitle={t("workspace.addNewStatusDescription", {
            name: currentWorkspace?.name,
          })}
          imageSrc="/AddNew.svg"
          disabled={isPendingJobStatus}
        >
          <Input
            label={t("workspace.statusName")}
            placeholder={t("workspace.statusNamePlaceholder")}
            onChange={(value) => {
              if (value && value.trim()) setNewStatusNameError("");
              setNewStatusName(value);
            }}
            value={newStatusName}
            formControlWidth={"100%"}
            labelStyles={{ marginTop: "32px", color: "black.darkText" }}
            errorMessage={newStatusNameError}
            isDisabled={isPendingJobStatus}
          />
        </Modal>
      )}
    </Flex>
  );
};

export default JobStatusesTab;
