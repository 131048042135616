import { Flex, Image, Text } from "@chakra-ui/react";

export type CheckFieldProps = {
  isChecked: boolean;
  label: string;
};

export const CheckField = ({ isChecked, label }: CheckFieldProps) => {
  return (
    <Flex sx={{ flexDir: "row", alignItems: "center", my: "6px" }}>
      <Image
        src={isChecked ? "/CheckIconChecked.svg" : "/CheckIconUnChecked.svg"}
      />
      <Text
        sx={{
          ml: 2,
          fontSize: "14px",
          color: "black.darker",
          opacity: isChecked ? "1" : "0.4",
        }}
      >
        {label}
      </Text>
    </Flex>
  );
};
