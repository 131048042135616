import { Flex, SystemStyleObject } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { IconContext } from "react-icons";
import ReactPaginate from "react-paginate";
import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr";
import { useCheckIsMobile } from "../../../lib/utils";

interface PaginationProps {
  pageCount: number;
  handlePageClick: (selectedItem: { selected: number }) => void;
  pageRangeDisplayed?: number;
  currentPage: number;
}

const paginationStyles: SystemStyleObject = {
  flexDir: "row",
  height: ["30px", "30px", "40px"],
  width: "100%",
  justifyContent: "center",
  ".pagination": {
    display: "flex",
    flexDirection: "row",
    listStyle: "none",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    height: "100%",
    ".page-item": {
      height: "100%",
      width: ["30px", "30px", "40px"],
      borderRadius: "10px",
      backgroundColor: "white",
      color: "black.default",
      fontSize: "14px",
      fontWeight: "500",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      a: {
        width: "100%",
        textAlign: "center",
      },
    },
    ".active": {
      backgroundColor: "green.foundationNormal",
      color: "white",
    },
    ".break-item": {
      display: "flex",
      flexDirection: "row",
      listStyle: "none",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      height: "100%",
      width: ["30px", "30px", "40px"],
      borderRadius: "10px",
      backgroundColor: "white",
      color: "black.default",
      fontSize: "14px",
      fontWeight: "500",
    },
  },
};

const PaginationButton = ({
  label,
  isPrevious = true,
}: {
  label: string;
  isPrevious?: boolean;
}) => {
  const isMobile = useCheckIsMobile();
  return (
    <IconContext.Provider value={{ color: "#667085", size: "20px" }}>
      <Flex
        sx={{
          flexDir: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: "8px",
          color: "#667085",
          fontSize: "14px",
          fontWeight: 600,
          mr: isPrevious && ["8px", "16px"],
          ml: !isPrevious && ["8px", "16px"],
        }}
      >
        {isPrevious ? (
          <>
            <GrFormPreviousLink />
            {!isMobile && label}
          </>
        ) : (
          <>
            {!isMobile && label}
            <GrFormNextLink />
          </>
        )}
      </Flex>
    </IconContext.Provider>
  );
};

const Pagination = ({
  pageCount,
  handlePageClick,
  pageRangeDisplayed = 2,
  currentPage,
}: PaginationProps) => {
  const { t } = useTranslation();
  return (
    <Flex sx={paginationStyles}>
      <ReactPaginate
        breakLabel="..."
        nextLabel={
          <PaginationButton
            label={t("common.paginationNext")}
            isPrevious={false}
          />
        }
        onPageChange={handlePageClick}
        pageRangeDisplayed={pageRangeDisplayed}
        pageCount={pageCount}
        previousLabel={<PaginationButton label={t("common.paginationPrev")} />}
        renderOnZeroPageCount={null}
        containerClassName="pagination"
        pageClassName={"page-item"}
        activeClassName={"active"}
        breakClassName={"break-item"}
        forcePage={currentPage}
        marginPagesDisplayed={2}
      />
    </Flex>
  );
};

export default Pagination;
