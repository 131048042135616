import {
  Box,
  Input as ChakraFileUpload,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  SystemStyleObject,
  Text,
  Image,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export type FileUploadProps = {
  sx?: SystemStyleObject;
  label?: string;
  onChange: (files: File[]) => void;
  showFileName?: boolean;
};

export const FileUpload = ({
  sx,
  label,
  onChange,
  showFileName = true,
}: FileUploadProps) => {
  const inputRef = useRef<any>(null);
  const { t } = useTranslation();
  const [fileName, setFileName] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const handleClick = () => {
    inputRef.current.click();
  };
  const handleDragOver = (event: any) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "drag";
    if (!isDragging) setIsDragging(true);
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    setIsDragging(false);
    setErrorMessage("");

    const files = event.dataTransfer.files;

    if (files.length > 0) {
      const allowedTypes = ["svg", "jpg", "jpeg", "png"];
      const fileType = files[0].type;
      if (allowedTypes.some((type) => fileType.includes(type))) {
        setFileName(files[0].name);
        const newFiles: File[] = Array.from(files);
        onChange(newFiles);
      } else {
        setFileName("");
        setErrorMessage(t("common.wrongImageFormat"));
      }
    }
  };

  const handleChange = (event: any) => {
    setErrorMessage("");
    event.preventDefault();
    const files = event.target.files;
    if (files.length > 0) {
      const allowedTypes = ["svg", "jpg", "jpeg", "png"];
      const fileType = files[0].type;
      if (allowedTypes.some((type) => fileType.includes(type))) {
        setFileName(files[0].name);
        const newFiles: File[] = Array.from(files);
        onChange(newFiles);
      } else {
        setFileName("");
        setErrorMessage(t("common.wrongImageFormat"));
      }
    }
  };

  const handleDragEnter = () => {
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };
  return (
    <FormControl width={["100%", "420px"]} isInvalid={!!errorMessage}>
      <FormLabel
        color={"gray.darker"}
        sx={{
          color: "gray.darker",
          marginBottom: "6px",
          fontSize: "14px",
          fontWeight: 600,
        }}
      >
        {label}
      </FormLabel>
      <ChakraFileUpload
        ref={inputRef}
        type="file"
        display="none"
        accept={".svg, .jpg, .jpeg, .png"}
        onChange={handleChange}
      />
      <Flex
        boxShadow={"base"}
        sx={{
          width: "100%",
          height: "auto",
          border: isDragging ? "#60B5AE 2px dashed" : "#60B5AE 2px solid",
          opacity: isDragging ? 0.7 : 1,
          px: "24px",
          py: "12px",
          borderRadius: "12px",
          cursor: "pointer",
          ...sx,
        }}
        onClick={handleClick}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        flexDir={"column"}
      >
        <Image src="/UploadFile.svg" height={"50px"} />
        <Box
          sx={{
            mt: "12px",
            fontSize: "14px",
            color: "gray.darker",
            margin: "auto",
            textAlign: "center",
            width: "100%",
          }}
        >
          {showFileName && fileName ? (
            <Text sx={{ fontWeight: "bold" }}>{fileName}</Text>
          ) : (
            <>
              <Text
                sx={{
                  display: "inline",
                  color: "green.foundationNormal",
                  fontWeight: "bold",
                }}
              >
                {t("common.clickToUpload")}
              </Text>
              <Text sx={{ display: "inline" }}>
                {t("common.clickToUploadDesc")}
              </Text>
            </>
          )}
        </Box>
      </Flex>

      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
