import { Flex, Text } from "@chakra-ui/react";
import Lottie, { LottieProps } from "react-lottie";
import { useCheckIsMobile } from "../../../lib/utils";

interface LottiePlayerProps {
  animationData: LottieProps["options"]["animationData"];
  message?: string;
}

const LottiePlayer = ({ animationData, message }: LottiePlayerProps) => {
  const isMobile = useCheckIsMobile();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Flex
      sx={{
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexDir: "column",
      }}
    >
      <Lottie
        options={defaultOptions}
        height={isMobile ? 250 : 400}
        width={isMobile ? 250 : 400}
      />
      {message && (
        <Text
          sx={{
            color: "gray.darker",
            fontSize: ["12px", "14px", "16px"],
            fontWeight: "600",
          }}
        >
          {message}
        </Text>
      )}
    </Flex>
  );
};

export default LottiePlayer;
