import { Flex, SystemStyleObject, Text } from "@chakra-ui/react";
import { Button } from "../../Atoms/Button/Button";

interface SectionHeadingProps {
  title: string;
  subtitle?: string;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  onClickPrimary?: () => void;
  onClickSecondary?: () => void;
  disabled?: boolean;
  sx?: SystemStyleObject;
  primaryButtonSX?: SystemStyleObject;
}

const SectionHeading = ({
  title,
  subtitle,
  primaryButtonLabel,
  secondaryButtonLabel,
  onClickPrimary,
  onClickSecondary,
  disabled,
  sx,
  primaryButtonSX,
}: SectionHeadingProps) => {
  return (
    <Flex
      sx={{
        width: "full",
        paddingBottom: "20px",
        borderBottom: "1px solid",
        borderColor: "gray.bottom",
        minHeight: "73px",
        flexDir: ["column", "row"],
        gap: "16px",
        mt: "16px",
        ...sx,
      }}
    >
      <Flex sx={{ flexDir: "column", flexGrow: 1 }}>
        <Text
          sx={{
            fontSize: "18px",
            fontWeight: "600",
            lineHeight: "28px",
            color: "black.darkText",
          }}
        >
          {title}
        </Text>
        <Text
          sx={{ fontSize: "14px", lineHeight: "20px", color: "gray.darker" }}
        >
          {subtitle}
        </Text>
      </Flex>
      <Flex
        sx={{
          flexDir: "row",
          gap: "16px",
          justifyContent: "center",
          alignItems: "start",
        }}
      >
        {secondaryButtonLabel && (
          <Button
            variant="tertiary"
            size="sm"
            onClick={() => onClickSecondary && onClickSecondary()}
            isDisabled={disabled}
          >
            {secondaryButtonLabel}
          </Button>
        )}

        {primaryButtonLabel && (
          <Button
            size="sm"
            isDisabled={disabled}
            onClick={() => onClickPrimary && onClickPrimary()}
            sx={{ ...primaryButtonSX }}
          >
            {primaryButtonLabel}
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default SectionHeading;
