import { useTranslation } from "react-i18next";
import { Heading } from "../../Atoms/Heading/Heading";
import { Box, Flex, Text } from "@chakra-ui/react";

import { useContext, useState } from "react";
import { VerificationCodeInput } from "../../Atoms/VerificationCodeInput/VerificationCodeInput";
import { Constants } from "../../../lib/constants";
import { Button } from "../../Atoms/Button/Button";
import { AppContext } from "../../../context/AppContext";

type StepConfirmEmailProps = {
  onConfirm: (code: string) => void;
  onResend: () => void;
  isLoading: boolean;
};

export const StepConfirmEmail = ({
  onConfirm,
  onResend,
  isLoading,
}: StepConfirmEmailProps) => {
  const { t } = useTranslation();
  const { user } = useContext(AppContext);
  const [confirmationCode, setConfirmationCode] = useState<string | null>("");

  return (
    <>
      <Heading sx={{ mb: "40px" }}>{t("register.confirmEmailTitle")}</Heading>
      <Box sx={{ maxW: "420px", mb: "40px" }}>
        <Text
          sx={{
            display: "inline",
          }}
        >
          {t("register.confirmEmailSubtitle")}
        </Text>
        <Text
          sx={{
            color: "green.foundationNormal",
            fontWeight: "bold",
            display: "inline",
          }}
        >
          {user?.email}.
        </Text>
        <Text
          sx={{
            display: "inline",
          }}
        >
          {t("register.resendEmailDesc")}
        </Text>
      </Box>
      <VerificationCodeInput
        numberOfSteps={Constants.EmailVerificationCodeLength}
        onChange={(value) => setConfirmationCode(value)}
      />

      <Flex
        sx={{
          width: "100%",
          flexDir: "row",
          justifyContent: "space-between",
          alignItems: "center",
          mt: "40px",
        }}
      >
        <Button
          variant="secondary"
          onClick={() => onResend()}
          isDisabled={isLoading}
        >
          {t("register.resendEmail")}
        </Button>
        <Button
          isDisabled={
            confirmationCode?.length !==
              Constants.EmailVerificationCodeLength || isLoading
          }
          sx={{ ml: 2 }}
          onClick={() => onConfirm(confirmationCode || "")}
        >
          {t("register.confirmEmailTitle")}
        </Button>
      </Flex>
    </>
  );
};
