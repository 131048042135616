export enum ErrorCodes {
  SERVER_ERROR = 1000,
  EMAIL_ALREADY_USED = 1001,
  BAD_REQUEST = 1002,
  WRONG_VERIFICATION_CODE = 1003,
  UNAUTHORIZED = 1004,
  USER_NOT_FOUND = 1005,
  SESSION_EXPIRED = 1006,
  INVALID_EMAIL = 1007,
  WRONG_CREDENTIALS = 1008,
  USER_NOT_VERIFIED = 1009,
  EMAIL_NOT_SENT = 1010,
  MISSING_PRIVILEGES = 1011,
  NOT_FOUND = 1012,
}
