import { useContext, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AlertMessageStatus } from "../components/Molecules/AlertMessage/AlertMessage";
import { useTranslation } from "react-i18next";
import { errorCodeToTranslationMapper } from "../mappers/ErrorCodeMapper";
import { AppContext } from "../context/AppContext";
import { ErrorCodes } from "../types/ErrorCodes";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import {
  createLabelMutate,
  deleteLabelMutate,
  editLabelMutate,
  getLabelsQuery,
  reorderLabelsMutate,
} from "../services/label";

export const useAddLabel = () => {
  const { t } = useTranslation();
  const { setAlertMessage, showAlertMessage, closeAlertMessage } =
    useContext(AppContext);
  const navigate = useNavigate();

  const { mutateAsync: addLabel, isPending: isPendingAddingLabel } =
    useMutation({
      mutationFn: createLabelMutate,
      onSuccess: (data) => {
        if (showAlertMessage) closeAlertMessage();
        if (data.label) {
          setAlertMessage(
            {
              title: t("success.labelAdd"),
              variant: AlertMessageStatus.SUCCESS,
            },
            true
          );
        }
        if (data.errorCode) {
          if (data.errorCode === ErrorCodes.SESSION_EXPIRED) {
            Object.keys(Cookies.get()).forEach(function (cookieName) {
              Cookies.remove(cookieName);
            });
            navigate("/login");
          }
          setAlertMessage(
            {
              title: t(errorCodeToTranslationMapper[data.errorCode]),
              description: data.error
                ? data.error
                : t("errors.ERROR_CODE", { code: data.errorCode }),
              variant: AlertMessageStatus.ERROR,
            },
            true
          );
        }
      },
    });

  return { addLabel, isPendingAddingLabel };
};

export const useGetWorkspaceLabels = ({
  workspaceId,
}: {
  workspaceId?: number;
}) => {
  const { setAlertMessage } = useContext(AppContext);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const accessToken = Cookies.get("acc_tkn");
  const refreshToken = Cookies.get("ref_tkn");

  const { data, isFetching } = useQuery({
    queryKey: ["workspaceLabels", workspaceId],
    queryFn: async () =>
      await getLabelsQuery({
        workspaceId,
      }),
    enabled: !!accessToken && !!refreshToken && !!workspaceId,
  });

  useEffect(() => {
    if (data?.errorCode) {
      if (data.errorCode === ErrorCodes.SESSION_EXPIRED) {
        Object.keys(Cookies.get()).forEach(function (cookieName) {
          Cookies.remove(cookieName);
        });
        navigate("/login");
      }
      setAlertMessage(
        {
          title: t(errorCodeToTranslationMapper[data.errorCode]),
          description: data.error
            ? data.error
            : t("errors.ERROR_CODE", { code: data.errorCode }),
          variant: AlertMessageStatus.ERROR,
        },
        false
      );
    }
  }, [data, setAlertMessage, t, navigate]);

  const invalidateQueries = () => {
    queryClient.invalidateQueries({ queryKey: ["workspaceLabels"] });
  };

  return {
    isFetchingWorkspaceLabels: isFetching,
    invalidateQueries,
    labels: data?.labels,
  };
};

export const useDeleteLabel = () => {
  const { t } = useTranslation();
  const { setAlertMessage, showAlertMessage, closeAlertMessage } =
    useContext(AppContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutateAsync: deleteLabel, isPending: isPendingDeleteLabel } =
    useMutation({
      mutationFn: deleteLabelMutate,
      onSuccess: (data) => {
        if (showAlertMessage) closeAlertMessage();
        if (data.labels) {
          setAlertMessage(
            {
              title: t("success.labelRemoved"),
              variant: AlertMessageStatus.SUCCESS,
            },
            true
          );
          queryClient.invalidateQueries({ queryKey: ["workspaceLabels"] });
        }
        if (data.errorCode) {
          if (data.errorCode === ErrorCodes.SESSION_EXPIRED) {
            Object.keys(Cookies.get()).forEach(function (cookieName) {
              Cookies.remove(cookieName);
            });
            navigate("/login");
          }
          setAlertMessage(
            {
              title: t(errorCodeToTranslationMapper[data.errorCode]),
              description: data.error
                ? data.error
                : t("errors.ERROR_CODE", { code: data.errorCode }),
              variant: AlertMessageStatus.ERROR,
            },
            true
          );
        }
      },
    });

  return { deleteLabel, isPendingDeleteLabel };
};

export const useEditLabel = () => {
  const { t } = useTranslation();
  const { setAlertMessage, showAlertMessage, closeAlertMessage } =
    useContext(AppContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutateAsync: editLabel, isPending: isPendingEditLabel } = useMutation(
    {
      mutationFn: editLabelMutate,
      onSuccess: (data) => {
        if (showAlertMessage) closeAlertMessage();
        if (data.label) {
          setAlertMessage(
            {
              title: t("success.labelEdited"),
              variant: AlertMessageStatus.SUCCESS,
            },
            true
          );
          queryClient.invalidateQueries({ queryKey: ["workspaceLabels"] });
        }
        if (data.errorCode) {
          if (data.errorCode === ErrorCodes.SESSION_EXPIRED) {
            Object.keys(Cookies.get()).forEach(function (cookieName) {
              Cookies.remove(cookieName);
            });
            navigate("/login");
          }
          setAlertMessage(
            {
              title: t(errorCodeToTranslationMapper[data.errorCode]),
              description: data.error
                ? data.error
                : t("errors.ERROR_CODE", { code: data.errorCode }),
              variant: AlertMessageStatus.ERROR,
            },
            true
          );
        }
      },
    }
  );

  return { editLabel, isPendingEditLabel };
};

export const useReorderLabels = () => {
  const { t } = useTranslation();
  const { setAlertMessage, showAlertMessage, closeAlertMessage } =
    useContext(AppContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutateAsync: reorderLabels, isPending: isPendingReorderLabels } =
    useMutation({
      mutationFn: reorderLabelsMutate,
      onSuccess: (data) => {
        if (showAlertMessage) closeAlertMessage();
        if (data.labels) {
          queryClient.invalidateQueries({ queryKey: ["workspaceLabels"] });
        }
        if (data.errorCode) {
          if (data.errorCode === ErrorCodes.SESSION_EXPIRED) {
            Object.keys(Cookies.get()).forEach(function (cookieName) {
              Cookies.remove(cookieName);
            });
            navigate("/login");
          }
          setAlertMessage(
            {
              title: t(errorCodeToTranslationMapper[data.errorCode]),
              description: data.error
                ? data.error
                : t("errors.ERROR_CODE", { code: data.errorCode }),
              variant: AlertMessageStatus.ERROR,
            },
            true
          );
        }
      },
    });

  return { reorderLabels, isPendingReorderLabels };
};
