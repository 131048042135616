/* eslint-disable react-hooks/exhaustive-deps */
import {
  Flex,
  Grid,
  GridItem,
  Switch,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import BaseLayout from "../layouts/BaseLayout";
import { Heading } from "../components/Atoms/Heading/Heading";
import { useCheckIsMobile } from "../lib/utils";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import { useGetJobs, useGetUsersToAssign } from "../hooks/jobs";
import { Input } from "../components/Atoms/Input/Input";
import { FiSearch } from "react-icons/fi";
import { Option, Select } from "../components/Molecules/Select/Select";
import { Button } from "../components/Atoms/Button/Button";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import JobTable from "../components/Organisms/Job/JobTable";
import { Constants } from "../lib/constants";
import Pagination from "../components/Molecules/Common/Pagination";
import LottiePlayer from "../components/Molecules/Lottie/Lottie";
import animationData from "../lotties/anim_not_found.json";
import { WorkspaceUserRoles } from "../types/workspace";

const JobsPage = () => {
  const { t } = useTranslation();
  const isMobile = useCheckIsMobile();
  const { currentWorkspace, isSidebarOpen } = useContext(AppContext);
  const [usersOptions, setUserOptions] = useState<Option[]>([]);
  const navigate = useNavigate();

  const [filters, setFilters] = useState<{
    workspaceId?: number;
    filters: {
      filter?: string;
      assignedUsers?: string;
      hasReminder?: number;
      statusId?: number;
      page?: number;
    };
  }>({
    workspaceId: currentWorkspace?.id,
    filters: {
      filter: undefined,
      hasReminder: undefined,
      statusId: undefined,
      assignedUsers: undefined,
      page: 1,
    },
  });

  const {
    jobs,
    isFetchingJobs,
    filteredJobsCount,
    allJobsCount,
    invalidateQueries,
  } = useGetJobs(filters);

  const [assignedUsersFilter, setAssignedUsersFilter] = useState<
    string | undefined
  >(undefined);

  const [selectedUserOption, setSelectedUserOption] = useState<
    Option | Option[] | undefined
  >(undefined);

  const { users } = useGetUsersToAssign({
    workspaceId: currentWorkspace?.id,
    filter: assignedUsersFilter,
  });

  useEffect(() => {
    if (users?.length) {
      const options = users.map((user) => {
        const option: Option = {
          value: user.id?.toString(),
          label: user.firstName + " " + user.lastName,
          src: user.filePath,
          subtitle: user.email,
        };
        return option;
      });
      setUserOptions(options);
    } else {
      setUserOptions([]);
    }
  }, [users]);

  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [jobStatusOptions, setJobStatusOptions] = useState<Option[]>([
    {
      value: "",
      label: t("workspace.all"),
    },
  ]);
  const [selectedOption, setSelectedOption] = useState<Option>(
    jobStatusOptions[0]
  );

  const columns = useBreakpointValue({
    base: 1,
    sm: 1,
    md: 2,
    lg: isSidebarOpen ? 2 : 4,
    xl: 4,
  });

  useEffect(() => {
    if (currentWorkspace?.id) {
      setFilters({
        workspaceId: currentWorkspace.id,
        filters: {
          filter: "",
          hasReminder: undefined,
          statusId: undefined,
          assignedUsers: undefined,
        },
      });

      if (currentWorkspace?.jobStatuses) {
        const statusOptions: Option[] = currentWorkspace?.jobStatuses?.map(
          (status) =>
            ({
              label: status.name,
              value: status.id,
            } as Option)
        );
        if (statusOptions)
          setJobStatusOptions([...jobStatusOptions, ...statusOptions]);
      }
    }
  }, [currentWorkspace]);

  const handleLastUserInPage = () => {
    if (pageCount > 1)
      setFilters({
        ...filters,
        filters: {
          ...filters,
          page: filters?.filters?.page ? filters?.filters?.page - 1 : 1,
        },
      });
    else invalidateQueries();
  };

  const pageCount = filteredJobsCount
    ? Math.ceil(filteredJobsCount / Constants.DefaultItemsPerPage)
    : 0;

  const isAllowedToAddJob =
    currentWorkspace &&
    (currentWorkspace.role === WorkspaceUserRoles.ADMIN ||
      currentWorkspace.role === WorkspaceUserRoles.OWNER ||
      (currentWorkspace.role === WorkspaceUserRoles.BASIC_USER &&
        currentWorkspace.privileges?.canAddJob));

  return (
    <BaseLayout>
      <Flex
        sx={{
          p: isMobile ? "24px" : "48px",
          flexDir: "column",
          width: "100%",
          gap: "32px",
          fontSize: "32px",
          alignItems: "center",
        }}
      >
        <Heading
          sx={{
            color: "black.title",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            gap: "12px",
          }}
        >
          {t("job.jobs")}
        </Heading>
        <Grid
          sx={{
            gridTemplateColumns: `repeat(${columns}, 1fr)`,
            width: "100%",
            gap: "16px",
            alignItems: "start",
          }}
        >
          <GridItem flex={1}>
            <Input
              type="text"
              LeftIcon={FiSearch}
              placeholder={t("job.jobSearchPlaceHolder")}
              label={t("users.search")}
              formControlWidth={"full"}
              onChange={(value) => {
                if (timeoutId) {
                  clearTimeout(timeoutId);
                  setTimeoutId(null);
                }
                const id = setTimeout(() => {
                  setFilters({
                    ...filters,
                    filters: {
                      ...filters.filters,
                      filter: value,
                    },
                  });
                }, 500);
                setTimeoutId(id);
              }}
              bottomMargin={"0px"}
            />
          </GridItem>
          <GridItem>
            <Select
              isMulti={false}
              isSearchable={false}
              name="jobStatuses"
              theme="default"
              label={t("workspace.status")}
              options={jobStatusOptions}
              withImages={false}
              defaultOption={selectedOption}
              onChange={(option) => {
                if (option) {
                  setSelectedOption(option);
                  setFilters({
                    ...filters,
                    filters: {
                      ...filters.filters,
                      statusId: Number(option?.value) || undefined,
                      page: 1,
                    },
                  });
                }
              }}
            />
          </GridItem>
          <GridItem>
            <Select
              isMulti={true}
              isSearchable={true}
              name="userTypes"
              theme="withSuggestions"
              label={t("job.assignedTo")}
              options={usersOptions}
              withImages={true}
              defaultOption={selectedUserOption}
              placeholder={t("job.all")}
              onChange={(options) => {
                if (timeoutId) {
                  clearTimeout(timeoutId);
                  setTimeoutId(null);
                }
                if (options && Array.isArray(options)) {
                  setSelectedUserOption(options);
                  const userIds = options.map((o) => Number(o.value));
                  setFilters({
                    ...filters,
                    filters: {
                      ...filters.filters,
                      page: 1,
                      assignedUsers: userIds.join(","),
                    },
                  });
                }
              }}
              onInputChange={(value) => {
                if (timeoutId) {
                  clearTimeout(timeoutId);
                  setTimeoutId(null);
                }
                const id = setTimeout(() => {
                  setAssignedUsersFilter(value.toString() || undefined);
                }, 500);
                setTimeoutId(id);
              }}
            />
          </GridItem>
          <GridItem height={"67px"}>
            <Flex
              sx={{
                flexDir: "row",
                gap: "8px",
                height: "100%",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Text
                sx={{
                  fontSize: "14px",
                  textAlign: "start",
                  fontWeight: "600",
                  color: "gray.darker",
                }}
              >
                {t("job.jobsWithReminder")}
              </Text>
              <Switch
                isChecked={!!filters.filters.hasReminder}
                colorScheme={"teal"}
                onChange={() =>
                  setFilters({
                    ...filters,
                    filters: {
                      ...filters.filters,
                      hasReminder:
                        filters.filters.hasReminder === 1 ? undefined : 1,
                    },
                  })
                }
              />
            </Flex>
          </GridItem>
        </Grid>
        {isAllowedToAddJob && (
          <Flex sx={{ height: "44px", width: "full" }}>
            <Button
              size="full"
              leftIcon={<FaPlus color="white" />}
              isDisabled={isFetchingJobs}
              onClick={() => navigate("/job/new")}
            >
              {t("job.new")}
            </Button>
          </Flex>
        )}

        {!!jobs?.length && !!filteredJobsCount && (
          <>
            <JobTable jobs={jobs} onLastJob={handleLastUserInPage} />
            {pageCount > 1 && (
              <Pagination
                pageCount={pageCount}
                handlePageClick={(selectedItem) => {
                  setFilters({
                    ...filters,
                    filters: {
                      ...filters.filters,
                      page: selectedItem.selected + 1,
                    },
                  });
                }}
                currentPage={
                  filters.filters.page ? filters.filters.page - 1 : 0
                }
              />
            )}
          </>
        )}
        {!jobs?.length &&
          !isFetchingJobs &&
          !!allJobsCount &&
          !filteredJobsCount && (
            <LottiePlayer
              message={t("job.filtersNotFound")}
              animationData={animationData}
            />
          )}
        {!jobs?.length && !isFetchingJobs && !allJobsCount && (
          <LottiePlayer
            message={t("job.noJobs")}
            animationData={animationData}
          />
        )}
      </Flex>
    </BaseLayout>
  );
};

export default JobsPage;
