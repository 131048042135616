export const sidebarSelectStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    background: "green.foundationLighter",
    boxShadow: "base",
    color: "white",
    _placeholder: { color: "white", opacity: 0 },
    width: "full",
    _hover: { background: "green.foundationLighter" },
    _focusVisible: { background: "green.foundationLighter" },
    cursor: "pointer",
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "white",
  }),

  option: (provided: any) => ({
    color: "green.foundationLighter",
    cursor: "pointer",
    _hover: { background: "white" },
  }),
};

export const defaultSelectStyles = {
  control: (provided: any) => ({
    ...provided,
    background: "white",
    boxShadow: "base",
    color: "gray.300",
    _placeholder: { color: "gray.300", opacity: 0 },
    width: "100%",
    _hover: { background: "none" },
    _focusVisible: { borderColor: "gray.light" },
    textAlign: "start",
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    background: "white",
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "gray.300",
  }),
  menuList: (provided: any) => ({
    ...provided,
    boxShadow: "base",
    minHeight: "80px",
  }),
  option: (provided: any) => ({
    ...provided,
    cursor: "pointer",
    _selected: { background: "green.lightBorder" },
  }),
};

export const withSuggestionsSelectStyles = {
  control: (provided: any) => ({
    ...provided,
    background: "white",
    boxShadow: "base",
    color: "gray.300",
    _placeholder: { color: "gray.300", opacity: 0 },
    width: "100%",
    _hover: { background: "none" },
    _focusVisible: { borderColor: "gray.light" },
    textAlign: "start",
    maxHeight: "60px",
    overflowY: "scroll",
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    background: "white",
    display: "none",
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "gray.300",
  }),
  menuList: (provided: any) => ({
    ...provided,
    boxShadow: "base",
    minHeight: "80px",
  }),
  option: (provided: any) => ({
    ...provided,
    cursor: "pointer",
    _selected: { background: "green.lightBorder" },
  }),
};
