import { Text } from "@chakra-ui/react";

interface TabItemProps {
  label: string;
  isActive?: boolean;
  onClick: () => void;
}

const TabItem = ({ isActive = false, label, onClick }: TabItemProps) => {
  return (
    <Text
      sx={{
        color: isActive ? "green.foundationNormal" : "gray.darker",
        fontSize: ["12px", "14px"],
        fontWeight: "600",
        lineHeight: "20px",
        padding: "1px 4px 11px 4px",
        borderBottom: isActive ? "2px solid" : "unset",
        borderColor: isActive ? "green.foundationNormal" : "unset",
        cursor: "pointer",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
      }}
      onClick={() => onClick()}
    >
      {label}
    </Text>
  );
};

export default TabItem;
