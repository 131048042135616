export enum RegistrationStepsEnum {
  BASIC_INFO = 1,
  PASSWORD,
  CONFIRM_EMAIL,
  WORKSPACE_SETUP,
  SUBSCRIPTION,
  PAYMENT,
}

export enum RegistrationStepType {
  CURRENT = "current",
  DONE = "done",
  REMAINING = "remaining",
}
