import { Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Input } from "../../Atoms/Input/Input";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Button } from "../../Atoms/Button/Button";
import { Link } from "../../Atoms/Link/Link";
import { Heading } from "../../Atoms/Heading/Heading";
import { object, string } from "yup";
import { useState } from "react";
import { PhoneNumberInput } from "../../Atoms/PhoneNumberInput/PhoneNumberInput";
import { User } from "../../../types/user";

export type StepBasicInfoProps = {
  onNext: (userData: User) => void;
  userData: User;
  isLoading: boolean;
};

export let userSchema = object({
  firstName: string().required({
    field: "firstName",
    translationKey: "validation.requiredFiled",
  }),
  email: string()
    .required({ field: "email", translationKey: "validation.requiredFiled" })
    .email({ field: "email", translationKey: "validation.invalidEmail" }),
  lastName: string().required({
    field: "lastName",
    translationKey: "validation.requiredFiled",
  }),
  phoneNumber: string().test({
    name: "valid-format",
    test: (value) => {
      if (!value) {
        return false;
      }
      return true;
    },
    message: {
      field: "phoneNumber",
      translationKey: "validation.requiredFiled",
    },
  }),
});

export const StepBasicInfo = ({
  onNext,
  userData,
  isLoading = false,
}: StepBasicInfoProps) => {
  const { t } = useTranslation();
  const [form, setForm] = useState<User>(userData);
  const [formErrors, setFormErrors] = useState<User>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });

  const handleNext = async () => {
    try {
      await userSchema.validate(form, { abortEarly: false });
      onNext(form);
    } catch (error: any) {
      let errors: any = {};
      if (error.errors.length) {
        error.errors.forEach(
          (err: { field: string; translationKey: string }) => {
            errors[err.field as keyof typeof errors] = t(err.translationKey);
          }
        );
        setFormErrors(errors);
      }
    }
  };

  return (
    <>
      <Heading sx={{ marginBottom: "40px" }}>
        {t("register.basicInfoTitle")}
      </Heading>

      <Input
        placeholder={t("profile.firstNamePlaceholder")}
        label={t("profile.firstName")}
        size="md"
        onChange={(value) => {
          setForm({ ...form, firstName: value });
          if (value && value.trim())
            setFormErrors({ ...formErrors, firstName: "" });
        }}
        value={form.firstName}
        errorMessage={formErrors.firstName}
      />

      <Input
        placeholder={t("profile.lastNamePlaceholder")}
        label={t("profile.lastName")}
        size="md"
        onChange={(value) => {
          setForm({ ...form, lastName: value });
          if (value && value.trim())
            setFormErrors({ ...formErrors, lastName: "" });
        }}
        value={form.lastName}
        errorMessage={formErrors.lastName}
      />

      <Input
        placeholder={t("profile.emailPlaceholder")}
        label={t("profile.email")}
        size="md"
        onChange={(value) => {
          setForm({ ...form, email: value });
          if (value && value.trim())
            setFormErrors({ ...formErrors, email: "" });
        }}
        value={form.email}
        errorMessage={formErrors.email}
      />

      <PhoneNumberInput
        value={form.phoneNumber || ""}
        placeholder={t("profile.phoneNumberPlaceholder")}
        onChange={(value, _country, _e, formattedValue) => {
          setForm({ ...form, phoneNumber: formattedValue });
          if (value && value.trim())
            setFormErrors({ ...formErrors, phoneNumber: "" });
        }}
        label={t("profile.phoneNumber")}
        errorMessage={formErrors.phoneNumber}
      />

      <Flex
        sx={{
          width: "100%",
          flexDir: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "40px",
        }}
      >
        <Text>
          {t("profile.alreadyHaveAccount")}
          <Link href="/login">{t("login.login")}</Link>
        </Text>
        <Button
          variant="primary"
          rightIcon={<ArrowForwardIcon />}
          onClick={() => handleNext()}
          isDisabled={isLoading}
        >
          {t("common.next")}
        </Button>
      </Flex>
    </>
  );
};
