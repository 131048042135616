import { useBreakpointValue } from "@chakra-ui/react";
import { ErrorCodes } from "../types/ErrorCodes";
import { Workspace } from "../types/workspace";
import { Constants } from "./constants";

export const isValidEmail = (email: string | undefined) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email || "");
};

export const parseError = (error: Error) => {
  try {
    const errorString = error.toString().substring(7);

    if (errorString) {
      const data = JSON.parse(errorString);
      const { status, message } = data;
      return { status, message };
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const handleErrorResponse = (error: Error) => {
  const parsedError = parseError(error);

  return {
    errorCode: ErrorCodes.SERVER_ERROR,
    error: JSON.stringify(parsedError),
  };
};

export const isPasswordValid = (password: string) => {
  const hasMinLength = password.length >= Constants.MinPasswordLength;
  const hasDigit = /\d/.test(password);
  const hasUpperCase = /[A-Z]/.test(password);

  return hasMinLength && hasDigit && hasUpperCase;
};

export const getWorkspaceOptions = (workspaces: Workspace[]) => {
  return workspaces.map((workspace) => {
    return {
      value: workspace.id?.toString(),
      label: workspace.name,
      src: workspace.imageUrl,
    };
  });
};

export const useCheckIsMobile = () => {
  return useBreakpointValue({ base: true, lg: false }, { ssr: false });
};
