import { FormControl, Textarea } from "@chakra-ui/react";
import { Input } from "../../Atoms/Input/Input";
import SectionHeading from "../../Molecules/Common/SectionHeading";
import SectionItem from "../../Molecules/Common/SectionItem";
import { useTranslation } from "react-i18next";
import { LabelType, Label } from "../../../types/Labels";

interface InputsProps {
  updateLabelText: (value: string, index: number, labelId?: number) => void;
  getInputValue: (labelId?: number) => string;
  errors: { text?: string };
  variant?: "add" | "edit";
  labels?: Label[];
  isSomethingUpdated?: boolean;
  onCancelClick?: () => void;
  onUpdatedClick?: () => void;
  disabled?: boolean;
}

const Inputs = ({
  updateLabelText,
  getInputValue,
  errors,
  variant = "add",
  labels,
  isSomethingUpdated = false,
  onCancelClick,
  onUpdatedClick,
  disabled = false,
}: InputsProps) => {
  const { t } = useTranslation();

  return (
    <>
      <SectionHeading
        title={t("job.inputsTitle")}
        subtitle={
          variant === "edit"
            ? t("job.inputsSubtitleEdit")
            : t("job.inputsSubtitle")
        }
        primaryButtonLabel={variant === "edit" ? t("common.update") : undefined}
        secondaryButtonLabel={
          variant === "edit" ? t("common.cancel") : undefined
        }
        disabled={!isSomethingUpdated || disabled}
        sx={{ mb: "20px" }}
        onClickPrimary={onUpdatedClick}
        onClickSecondary={onCancelClick}
      />
      {labels && !!labels.length && (
        <>
          {labels.map((label, index) => {
            if (label.type === LabelType.singleLine)
              return (
                <SectionItem
                  label={
                    label.name === "name" && index === 0
                      ? t("job.title")
                      : label.name || ""
                  }
                  key={label.id}
                  sx={{
                    flexDir: ["column", "column", "row"],
                    gap: "20px",
                  }}
                  labelColor="gray.darker"
                >
                  <Input
                    type="text"
                    formControlWidth={"full"}
                    onChange={(value) =>
                      updateLabelText(value, index, label.id)
                    }
                    value={getInputValue(label.id)}
                    errorMessage={index === 0 ? errors.text : ""}
                  />
                </SectionItem>
              );

            return (
              <SectionItem
                label={
                  label.name === "name" && index === 0
                    ? t("job.title")
                    : label.name || ""
                }
                key={label.id}
                sx={{
                  flexDir: ["column", "column", "row"],
                  gap: "20px",
                }}
                labelColor="gray.darker"
              >
                <FormControl
                  width={"100%"}
                  sx={{ fontSize: "14px" }}
                  key={label.id}
                >
                  <Textarea
                    resize={"none"}
                    _placeholder={{ color: "gray.light" }}
                    _hover={{ borderColor: "gray.dark" }}
                    focusBorderColor="gray.light"
                    sx={{
                      color: "black.darkText",
                      mb: "20px",
                      boxShadow: "base",
                      w: "100%",
                      h: "100px",
                    }}
                    onChange={(e) =>
                      updateLabelText(e.target.value, index, label.id)
                    }
                    value={getInputValue(label.id)}
                  />
                </FormControl>
              </SectionItem>
            );
          })}
        </>
      )}
    </>
  );
};

export default Inputs;
