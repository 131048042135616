import { Flex, Icon, Tooltip } from "@chakra-ui/react";
import { IconType } from "react-icons";
import { MdOutlineModeEdit, MdDeleteOutline } from "react-icons/md";

interface UserCardProps {
  flexDir?: "row" | "column";
  onEditClick?: () => void;
  onRemoveClick: () => void;
  isRemoveDisabled?: boolean;
  isEditDisabled?: boolean;
  showEdit?: boolean;
  removeIcon?: IconType;
  removeToolTip?: string;
  editToolTip?: string;
}

const UserActions = ({
  flexDir = "row",
  onEditClick,
  onRemoveClick,
  isRemoveDisabled,
  isEditDisabled,
  showEdit = true,
  removeIcon,
  removeToolTip = "",
  editToolTip = "",
}: UserCardProps) => {
  return (
    <Flex
      sx={{
        flexDir: flexDir,
        alignItems: "center",
        justifyContent: "center",
        gap: flexDir === "column" ? 0 : "10px",
      }}
    >
      {showEdit && (
        <Tooltip
          label={!isEditDisabled && editToolTip}
          placement={flexDir === "column" ? "left" : "top-end"}
          backgroundColor={"green.foundationLighter"}
        >
          <span>
            <Icon
              as={MdOutlineModeEdit}
              height="20px"
              width="20px"
              cursor={isEditDisabled ? "not-allowed" : "pointer"}
              opacity={isEditDisabled ? 0.3 : 1}
              onClick={() => onEditClick && !isEditDisabled && onEditClick()}
            />
          </span>
        </Tooltip>
      )}

      <Tooltip
        label={!isRemoveDisabled && removeToolTip}
        placement={flexDir === "column" ? "left" : "top-end"}
        backgroundColor={"green.foundationLighter"}
      >
        <span>
          <Icon
            as={removeIcon ?? MdDeleteOutline}
            height="20px"
            width="20px"
            color="red.delete"
            cursor={isRemoveDisabled ? "not-allowed" : "pointer"}
            onClick={() => {
              if (isRemoveDisabled) return;
              onRemoveClick();
            }}
            opacity={isRemoveDisabled ? 0.3 : 1}
            mr="10px"
          />
        </span>
      </Tooltip>
    </Flex>
  );
};

export default UserActions;
