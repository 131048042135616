import { Center, Flex } from "@chakra-ui/react";
import { ReactNode, useContext } from "react";
import { Image } from "@chakra-ui/react";
import { Heading } from "../components/Atoms/Heading/Heading";
import { useTranslation } from "react-i18next";
import { RegistrationSteps } from "../components/Molecules/Registration/RegistrationSteps";
import { RegistrationStepsEnum } from "../types/Registration";
import { StepsPagination } from "../components/Molecules/Registration/StepsPagination";
import { Constants } from "../lib/constants";
import { AlertMessage } from "../components/Molecules/AlertMessage/AlertMessage";
import { AppContext } from "../context/AppContext";
import { useCheckIsMobile } from "../lib/utils";

interface RegisterLayoutProps {
  children: ReactNode;
  currentStep: RegistrationStepsEnum;
}

const RegisterLayout = ({ children, currentStep }: RegisterLayoutProps) => {
  const isMobile = useCheckIsMobile();
  const { showAlertMessage, alertMessageProps } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <Flex bg="white" minH="100vh" w="100%" h={"100%"}>
      {!isMobile && (
        <Flex
          w="25%"
          minW={"400px"}
          minH="100vh"
          height="100%"
          sx={{
            backgroundColor: "green.foundationDark",
            borderRadius: "0px 20px 0px 0px",
            color: "white",
            padding: "48px 16px  16px 48px",
            gap: "88px",
            flexDirection: "column",
            alignItems: "flexStart",
            alignSelf: "stretch",
          }}
        >
          <Image src="/Logo.svg" width={"215px"} alt="Logo" />
          <Heading variant="secondary">{t("register.register")}</Heading>
          <RegistrationSteps currentStep={currentStep} />
        </Flex>
      )}

      <Flex
        w={isMobile ? "100%" : "75%"}
        minH="100vh"
        height="100%"
        mt={isMobile ? 6 : 0}
        backgroundColor={"white"}
        sx={{
          flexDir: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {showAlertMessage && alertMessageProps && (
          <AlertMessage {...alertMessageProps} position="top" />
        )}

        <Center
          sx={{
            p: "16px",
            flexGrow: 1,
          }}
        >
          <Flex
            sx={{
              flexDir: "column",
              alignItems: "flexStart",
            }}
          >
            {children}
          </Flex>
        </Center>
        <StepsPagination
          currentStep={currentStep}
          numberOfSteps={Constants.NumberOfRegistrationSteps}
        />
      </Flex>
    </Flex>
  );
};

export default RegisterLayout;
