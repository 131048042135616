import { HStack, PinInput, PinInputField } from "@chakra-ui/react";

type VerificationCodeInputProps = {
  numberOfSteps: number;
  isDisabled?: boolean;
  onChange: (value: string) => void;
};

export const VerificationCodeInput = ({
  numberOfSteps,
  isDisabled = false,
  onChange,
}: VerificationCodeInputProps) => {
  return (
    <HStack>
      <PinInput
        type="number"
        autoFocus
        placeholder="•"
        size={"lg"}
        onChange={(value) => onChange(value)}
        isDisabled={isDisabled}
      >
        {Array(numberOfSteps)
          .fill(numberOfSteps)
          .map((_step, index) => {
            return (
              <PinInputField
                key={index}
                sx={{
                  height: ["48px", "56px"],
                  width: ["48px", "56px"],
                  borderRadius: "8px",
                  border: "2px",
                  borderColor: "green.foundationNormal",
                  color: "black.default",
                  outline: "none",
                  _focusVisible: {
                    borderColor: "green.foundationNormal",
                  },
                  _hover: {
                    borderColor: "green.foundationNormal",
                  },
                }}
              />
            );
          })}
      </PinInput>
    </HStack>
  );
};
