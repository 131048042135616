import { Flex } from "@chakra-ui/react";
import { ReactNode, useContext } from "react";
import DesktopSidebar from "../components/Organisms/Sidebar/DesktopSidebar";
import MobileSidebar from "../components/Organisms/Sidebar/MobileSidebar";
import { AppContext } from "../context/AppContext";
import { AlertMessage } from "../components/Molecules/AlertMessage/AlertMessage";
import { useCheckIsMobile } from "../lib/utils";

interface BaseLayoutProps {
  children?: ReactNode;
}

const BaseLayout = ({ children }: BaseLayoutProps) => {
  const isMobile = useCheckIsMobile();
  const { showAlertMessage, alertMessageProps } = useContext(AppContext);

  return (
    <Flex
      sx={{
        width: "100%",
        minH: "100vh",
        flexDir: isMobile ? "column" : "row",
        position: "relative",
      }}
    >
      {isMobile ? <MobileSidebar /> : <DesktopSidebar />}
      <Flex
        sx={{
          flexGrow: 1,
          flexDir: "column",
          position: "relative",
        }}
      >
        {showAlertMessage && alertMessageProps && (
          <AlertMessage {...alertMessageProps} size="md" />
        )}
        {children}
      </Flex>
    </Flex>
  );
};

export default BaseLayout;
