import { Tr, Td, Flex, Icon } from "@chakra-ui/react";
import { Draggable } from "react-beautiful-dnd";
import { MdDragIndicator } from "react-icons/md";
import UserActions from "./UserActions";
import { useTranslation } from "react-i18next";
import { Label } from "../../../types/Labels";

interface DraggableJobLabelRowProps {
  label: Label;
  onEdit: (label: Label) => void;
  onDelete: (label: Label) => void;
  iconsDisabled?: boolean;
  isRowDisabled?: boolean;
}

const DraggableJobLabelRow = ({
  label,
  onDelete,
  onEdit,
  iconsDisabled = false,
  isRowDisabled = false,
}: DraggableJobLabelRowProps) => {
  const { t } = useTranslation();
  if (!label.id || !label.order) return null;

  return (
    <Draggable
      key={label.id.toString()}
      draggableId={label.id.toString()}
      index={label.order}
      isDragDisabled={isRowDisabled}
    >
      {(provided, snapshot) => (
        <Tr
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          key={label.id?.toString()}
          width="full"
          display={"flex"}
          sx={{
            backgroundColor: snapshot.isDragging ? "gray.head" : "white",
          }}
        >
          <Td
            sx={{ flexGrow: 1 }}
            opacity={isRowDisabled ? 0.3 : 1}
            cursor={isRowDisabled ? "not-allowed" : "grab"}
          >
            {label.name}
          </Td>
          <Td
            width={"140px"}
            px={"0px !important"}
            opacity={isRowDisabled ? 0.3 : 1}
            cursor={isRowDisabled ? "not-allowed" : "grab"}
          >
            {t(`workspace.${label.type}`)}
          </Td>
          <Td width="110px">
            <Flex w="full">
              <UserActions
                onRemoveClick={() => {
                  onDelete(label);
                }}
                onEditClick={() => onEdit(label)}
                isRemoveDisabled={iconsDisabled || isRowDisabled}
                isEditDisabled={iconsDisabled || isRowDisabled}
                removeToolTip={t(`workspace.deleteLabel`)}
                editToolTip={t(`workspace.editLabel`)}
              />
              <Icon
                as={MdDragIndicator}
                height="20px"
                width="20px"
                color="gray.600"
                cursor={isRowDisabled ? "not-allowed" : "grab"}
                opacity={isRowDisabled ? 0.3 : 1}
                pointerEvents={iconsDisabled ? "none" : "auto"}
              />
            </Flex>
          </Td>
        </Tr>
      )}
    </Draggable>
  );
};

export default DraggableJobLabelRow;
