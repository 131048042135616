import { useTranslation } from "react-i18next";
import { AppContext } from "../context/AppContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { editUserRoleMutate } from "../services/userRole";
import { AlertMessageStatus } from "../components/Molecules/AlertMessage/AlertMessage";
import { ErrorCodes } from "../types/ErrorCodes";
import Cookies from "js-cookie";
import { errorCodeToTranslationMapper } from "../mappers/ErrorCodeMapper";

export const useEditUserRole = () => {
  const { t } = useTranslation();
  const { setAlertMessage, showAlertMessage, closeAlertMessage } =
    useContext(AppContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutateAsync: editUserRole, isPending: isPendingEditUserRole } =
    useMutation({
      mutationFn: editUserRoleMutate,
      onSuccess: (data) => {
        if (showAlertMessage) closeAlertMessage();
        if (data.user) {
          setAlertMessage(
            {
              title: t("success.userRoleUpdated"),
              variant: AlertMessageStatus.SUCCESS,
            },
            true
          );
          queryClient.invalidateQueries({ queryKey: ["userDetails"] });
        }
        if (data.errorCode) {
          if (data.errorCode === ErrorCodes.SESSION_EXPIRED) {
            Object.keys(Cookies.get()).forEach(function (cookieName) {
              Cookies.remove(cookieName);
            });
            navigate("/login");
          }
          setAlertMessage(
            {
              title: t(errorCodeToTranslationMapper[data.errorCode]),
              description: data.error
                ? data.error
                : t("errors.ERROR_CODE", { code: data.errorCode }),
              variant: AlertMessageStatus.ERROR,
            },
            true
          );
        }
      },
    });

  return { editUserRole, isPendingEditUserRole };
};
