import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LoginPage } from "./pages/LoginPage";
import { HomePage } from "./pages/HomePage";
import { ForgotPasswordPage } from "./pages/ForgotPasswordPage";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "./lib/theme";
import i18n from "i18next";
import { I18nextProvider, initReactI18next } from "react-i18next";
import hr from "./locales/hr.json";
import { RegisterPage } from "./pages/RegisterPage";
import { ResetPasswordPage } from "./pages/ResetPasswordPage";
import { InvitationAcceptedPage } from "./pages/InvitationAcceptedPage";
import { AppContextProvider } from "./context/AppContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import JobsPage from "./pages/JobsPage";
import ProfilePage from "./pages/ProfilePage";
import UsersPage from "./pages/UsersPage";
import WorkspacePage from "./pages/WorkspacePage";
import ProtectedRoute from "./components/Organisms/Routes/ProtectedRoute";
import OwnerAdminProtected from "./components/Organisms/Routes/OwnerAdminProtected";
import UserDetailPage from "./pages/UserDetailPage";
import PageNotFound from "./pages/PageNotFound";
import NewJobPage from "./pages/NewJobPage";
import EditJobPage from "./pages/EditJobPage";

i18n.use(initReactI18next).init({
  resources: {
    hr: { translation: hr },
  },
  lng: "hr",
  fallbackLng: "hr",
  interpolation: { escapeValue: false },
});

const queryClient = new QueryClient({
  defaultOptions: {},
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={i18n}>
        <ChakraProvider theme={theme}>
          <AppContextProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/job" element={<ProtectedRoute />}>
                  <Route path="/job" element={<JobsPage />} />
                </Route>
                <Route path="/profile" element={<ProtectedRoute />}>
                  <Route path="/profile" element={<ProfilePage />} />
                </Route>
                <Route path="/workspace" element={<OwnerAdminProtected />}>
                  <Route path="/workspace" element={<WorkspacePage />} />
                </Route>
                <Route path="/users" element={<OwnerAdminProtected />}>
                  <Route path="/users" element={<UsersPage />} />
                </Route>
                <Route path="/job/new" element={<OwnerAdminProtected />}>
                  <Route path="/job/new" element={<NewJobPage />} />
                </Route>
                <Route path="/job/edit/:id" element={<OwnerAdminProtected />}>
                  <Route path="/job/edit/:id" element={<EditJobPage />} />
                </Route>
                <Route path="/users/:id" element={<OwnerAdminProtected />}>
                  <Route path="/users/:id" element={<UserDetailPage />} />
                </Route>
                <Route path="/register" element={<RegisterPage />} />
                <Route
                  path="/forgot-password"
                  element={<ForgotPasswordPage />}
                />
                <Route path="/reset-password" element={<ResetPasswordPage />} />
                <Route
                  path="/invitation"
                  element={<InvitationAcceptedPage />}
                />
                <Route path="/" element={<HomePage />} />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </BrowserRouter>
          </AppContextProvider>
        </ChakraProvider>
      </I18nextProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default App;
