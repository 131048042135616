import {
  Input as ChakraInput,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  InputLeftElement,
  SystemStyleObject,
} from "@chakra-ui/react";
import { IconType } from "react-icons";
import { useCheckIsMobile } from "../../../lib/utils";

export type InputProps = {
  sx?: SystemStyleObject;
  labelStyles?: SystemStyleObject;
  formControlStyles?: SystemStyleObject;
  size?: "xs" | "sm" | "md" | "lg";
  type?: "text" | "tel" | "password" | "number";
  variant?: "outline" | "unstyled" | "flushed" | "filled";
  placeholder?: string;
  label?: string;
  isRequired?: boolean;
  errorMessage?: string;
  isInvalid?: boolean;
  onChange?: (value: any) => void;
  value?: string | number | undefined;
  errorMessageMargin?: string;
  formControlWidth?: string | string[];
  isDisabled?: boolean;
  LeftIcon?: IconType;
  bottomMargin?: string;
};

export const Input = ({
  sx,
  size,
  type,
  variant,
  placeholder,
  label,
  isRequired = false,
  errorMessage,
  onChange,
  value,
  errorMessageMargin,
  formControlWidth,
  isDisabled,
  LeftIcon,
  labelStyles,
  formControlStyles,
  bottomMargin,
}: InputProps) => {
  const isMobile = useCheckIsMobile();
  return (
    <FormControl
      width={formControlWidth ? formControlWidth : isMobile ? "100%" : "420px"}
      isRequired={isRequired}
      isInvalid={!!errorMessage}
      sx={{ fontSize: "14px", ...formControlStyles }}
    >
      {label && (
        <FormLabel
          color={"gray.darker"}
          sx={{
            color: "gray.darker",
            marginBottom: "6px",
            fontSize: "14px",
            fontWeight: 600,
            ...labelStyles,
          }}
        >
          {label}
        </FormLabel>
      )}
      <InputGroup>
        {LeftIcon && (
          <InputLeftElement pointerEvents="none">
            {<LeftIcon opacity={isDisabled ? "0.5" : 1} color={"#D0D5DD"} />}
          </InputLeftElement>
        )}
        <Flex flexDir={"column"} flexGrow={1}>
          <ChakraInput
            size={size}
            type={type}
            variant={variant}
            placeholder={placeholder}
            _placeholder={{ color: "gray.light" }}
            focusBorderColor="gray.light"
            _hover={{ borderColor: "gray.dark" }}
            boxShadow={"base"}
            w={"100%"}
            marginBottom={
              errorMessage ? 0 : bottomMargin ? bottomMargin : "20px"
            }
            sx={{
              color: "black.darkText",
              paddingLeft: LeftIcon && "40px",
              ...sx,
            }}
            onChange={(e) => onChange && onChange(e.target.value)}
            value={value}
            isDisabled={isDisabled}
          />
          <FormErrorMessage
            sx={{
              marginBottom: errorMessageMargin ? errorMessageMargin : "20px",
            }}
          >
            {errorMessage}
          </FormErrorMessage>
        </Flex>
      </InputGroup>
    </FormControl>
  );
};
