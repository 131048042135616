import {
  GroupBase,
  OptionProps,
  Select as SelectChakra,
  chakraComponents,
} from "chakra-react-select";
import {
  sidebarSelectStyles,
  defaultSelectStyles,
  withSuggestionsSelectStyles,
} from "./Select.styles";
import { JSX } from "react/jsx-runtime";
import {
  Text,
  Avatar,
  Flex,
  FormLabel,
  SystemStyleObject,
  Tag,
  TagCloseButton,
  FormErrorMessage,
  FormControl,
} from "@chakra-ui/react";
import { AppContext } from "../../../context/AppContext";
import { useContext } from "react";
import { t } from "i18next";

export type Option = {
  value?: string;
  label?: string;
  src?: string;
  disabled?: boolean;
  subtitle?: string;
};

interface SelectProps {
  isMulti?: boolean;
  name: "users" | "workspaces" | "userTypes" | "email" | "jobStatuses";
  closeMenuOnSelect?: boolean;
  variant?: "outline" | "filled" | "flushed" | "unstyled";
  theme?: "default" | "sidebar" | "withSuggestions";
  isSearchable?: boolean;
  options?: Option[];
  onChange?: (option: Option | null) => void;
  onRemove?: () => void;
  defaultOption?: Option | Option[];
  labelStyles?: SystemStyleObject;
  label?: string;
  withImages?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  onInputChange?: (value: string) => void;
  errorMessage?: string;
}

const getComponents = (
  withImages: boolean,
  theme: "default" | "sidebar" | "withSuggestions",
  onRemove?: () => void
) => ({
  Option: (
    props: JSX.IntrinsicAttributes &
      OptionProps<Option, boolean, GroupBase<Option>>
  ) => {
    return (
      <chakraComponents.Option {...props} isDisabled={!!props.data?.disabled}>
        <Flex
          sx={{
            alignItems: "center",
            gap: "10px",
            px: "10px",
            fontWeight:
              theme === ("default" || "withSuggestions") ? "400" : "bold",
            mb: "10px",
            justifyContent: "center",
            height: "40px",
            cursor: props.data?.disabled ? "not-allowed" : "pointer",
            opacity: props.data?.disabled ? "0.5" : 1,
            width: "100%",
          }}
        >
          {withImages && (
            <Avatar
              w={"35px"}
              h={"35px"}
              src={props.data?.src || undefined}
              name={props.data?.label}
              color={"white"}
            />
          )}
          <Flex
            sx={{
              flexDir: "column",
              width: "100%",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            <Text
              sx={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                width: "100%",
              }}
            >
              {props.data?.label}
            </Text>
            {props.data.subtitle && (
              <Text
                sx={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: "100%",
                  fontSize: "12px",
                }}
              >
                {props.data?.subtitle}
              </Text>
            )}
          </Flex>
        </Flex>
      </chakraComponents.Option>
    );
  },
  SingleValue: (props: any) => {
    if (theme === "withSuggestions") {
      return (
        <chakraComponents.SingleValue {...props}>
          <Tag>
            {props.data?.label}
            <TagCloseButton
              onClick={() => {
                onRemove && onRemove();
              }}
            />
          </Tag>
        </chakraComponents.SingleValue>
      );
    } else {
      return <chakraComponents.SingleValue {...props} />;
    }
  },
});

export const Select = ({
  isMulti = false,
  name = "workspaces",
  closeMenuOnSelect = true,
  variant = "filled",
  theme = "sidebar",
  isSearchable = true,
  options = [],
  onChange,
  defaultOption,
  label,
  labelStyles,
  withImages = true,
  isDisabled = false,
  placeholder,
  onInputChange,
  onRemove,
  errorMessage,
}: SelectProps) => {
  const { isSidebarOpen } = useContext(AppContext);

  let selectStyles = undefined;

  if (theme === "sidebar") {
    selectStyles = {
      ...sidebarSelectStyles,
      dropdownIndicator: (provided: any) => ({
        ...provided,
        background: "green.foundationLighter",
        display: !isSidebarOpen && "none",
      }),
      valueContainer: (provided: any) => ({
        ...provided,
        px: !isSidebarOpen ? 0 : "10px",
      }),
      menuList: (provided: any) => ({
        ...provided,
        boxShadow: "base",
        overflow: "auto",
        position: !isSidebarOpen ? "absolute" : "unset",
        width: !isSidebarOpen ? "250px" : "full",
        background: "gray.bottom",
      }),
    };
  } else if (theme === "withSuggestions") {
    selectStyles = withSuggestionsSelectStyles;
  } else {
    selectStyles = defaultSelectStyles;
  }

  const selectComponents =
    theme === "sidebar" && !isSidebarOpen
      ? {
          ...getComponents(withImages, theme),
          SingleValue: (props: any) => {
            return (
              <chakraComponents.SingleValue {...props} sx={{ p: 0 }}>
                <Flex
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    p: "0",
                    w: "full",
                  }}
                >
                  <Avatar
                    w={"35px"}
                    h={"35px"}
                    src={props.data?.src || undefined}
                    name={props.data?.label}
                    color={"white"}
                  />
                </Flex>
              </chakraComponents.SingleValue>
            );
          },
        }
      : getComponents(withImages, theme, onRemove);

  return (
    <Flex sx={{ flexDir: "column", width: "100%" }}>
      {label && (
        <FormLabel
          color={"gray.darker"}
          sx={{
            color: "gray.darker",
            marginBottom: "6px",
            fontSize: "14px",
            fontWeight: 600,
            ...labelStyles,
          }}
        >
          {label}
        </FormLabel>
      )}
      <FormControl isInvalid={!!errorMessage}>
        <SelectChakra
          isMulti={isMulti}
          name={name}
          closeMenuOnSelect={closeMenuOnSelect}
          variant={variant}
          isSearchable={isSearchable}
          options={options}
          chakraStyles={selectStyles}
          components={selectComponents}
          value={defaultOption || null}
          onChange={(option) => onChange && onChange(option as Option)}
          isDisabled={isDisabled}
          placeholder={placeholder}
          onInputChange={(newValue) => onInputChange && onInputChange(newValue)}
          filterOption={null}
          //menuIsOpen={true}
          noOptionsMessage={() => t("common.noOptions")}
          menuPlacement="auto"
        />
        {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
      </FormControl>
    </Flex>
  );
};

export default Select;
