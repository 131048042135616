/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Cookies from "js-cookie";
import { AppContext } from "../../../context/AppContext";
import { useGetCurrentUser } from "../../../hooks/user";
import { WorkspaceUserRoles } from "../../../types/workspace";

const OwnerAdminProtectedRoute: React.FC<any> = ({ element, ...rest }) => {
  const { setUser, setWorkspaces, currentWorkspace } = useContext(AppContext);
  const accessToken = Cookies.get("acc_tkn");
  const refreshToken = Cookies.get("ref_tkn");

  const { currentUser, workspaces } = useGetCurrentUser();
  const [isWorkspaceLoaded, setIsWorkspaceLoaded] = useState(false);

  useEffect(() => {
    if (currentUser && currentUser.id) setUser(currentUser);
    if (workspaces && workspaces.length) {
      setWorkspaces(workspaces);
      setIsWorkspaceLoaded(true);
    }
    if (!workspaces || !workspaces.length) setWorkspaces([]);
  }, [currentUser, workspaces]);

  const isAuthenticated = !!accessToken && !!refreshToken;

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  if (
    !isWorkspaceLoaded ||
    (isWorkspaceLoaded && !!workspaces?.length && !currentWorkspace)
  ) {
    return <Outlet />;
  }

  const isAdmin =
    isWorkspaceLoaded &&
    currentWorkspace &&
    currentWorkspace.role &&
    (currentWorkspace.role === WorkspaceUserRoles.ADMIN ||
      currentWorkspace.role === WorkspaceUserRoles.OWNER);

  const canSeeUsersTab =
    window.location.pathname === "/users" &&
    (currentWorkspace?.privileges?.canAddUsersToWorkspace ||
      currentWorkspace?.privileges?.canRemoveUsersFromWorkspace);

  const canSeeAddJobPage =
    window.location.pathname === "/job/new" &&
    currentWorkspace?.privileges?.canAddJob;

  return isAdmin || canSeeUsersTab || canSeeAddJobPage ? (
    <Outlet />
  ) : (
    <Navigate to="/profile" replace />
  );
};

export default OwnerAdminProtectedRoute;
