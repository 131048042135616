import { Flex, Switch, Text } from "@chakra-ui/react";
import {
  UserPrivileges as UserPrivilegesType,
  PrivilegesEnum,
} from "../../../types/Privileges";
import { useTranslation } from "react-i18next";

interface UserPrivilegesProps {
  privileges: UserPrivilegesType;
  isDisabled?: boolean;
  onChange?: (privilege: PrivilegesEnum, value: boolean) => void;
}

const UserPrivileges = ({
  privileges,
  isDisabled = false,
  onChange,
}: UserPrivilegesProps) => {
  const { t } = useTranslation();
  return (
    <Flex
      sx={{
        width: "full",
        justifyContent: "center",
        alignItems: "center",
        flexDir: "column",
      }}
    >
      {Object.values(PrivilegesEnum).map((privilege: PrivilegesEnum, i) => {
        const hasPrivilege = privileges[privilege] ?? false;
        return (
          <Flex
            key={i}
            sx={{
              w: "50%",
              fontSize: "14px",
              fontWeight: "500",
              color: "green.foundationDarkActive",

              lineHeight: "20px",
              p: "14px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text
              flexGrow={1}
              mr={"14px"}
              sx={{ opacity: isDisabled ? "0.4" : "1" }}
            >
              {t(`privileges.${privilege}`)}
            </Text>
            <Switch
              id={i.toString()}
              isChecked={hasPrivilege}
              colorScheme={"teal"}
              isDisabled={isDisabled}
              onChange={() => onChange && onChange(privilege, !hasPrivilege)}
            />
          </Flex>
        );
      })}
    </Flex>
  );
};

export default UserPrivileges;
