import { Link as ChakraLink, SystemStyleObject } from "@chakra-ui/react";
import { ReactNode } from "react";

export type LinkProps = {
  variant?: "primary" | "secondary";
  sx?: SystemStyleObject;
  children: ReactNode | string;
  href: string;
};

export const Link = ({
  variant = "primary",
  sx,
  children,
  href,
}: LinkProps) => {
  return (
    <ChakraLink variant={variant} sx={sx} href={href}>
      {children}
    </ChakraLink>
  );
};
