import { Flex } from "@chakra-ui/react";
import LottiePlayer from "../components/Molecules/Lottie/Lottie";
import animationData from "../lotties/anim_page_not_found_web.json";
import { useTranslation } from "react-i18next";
import { Link } from "../components/Atoms/Link/Link";

const PageNotFound = () => {
  const { t } = useTranslation();
  return (
    <Flex
      sx={{
        justifyContent: "center",
        alignItems: "center",
        width: "full",
        h: "100vh",
        flexDir: "column",
      }}
    >
      <Flex>
        <LottiePlayer
          animationData={animationData}
          message={t("common.pageNotFound")}
        />
      </Flex>

      <Link
        href="/job"
        sx={{
          mb: "15px",
          fontWeight: "bold",
          fontSize: "16px",
          backgroundColor: "green.foundationNormal",
          p: "12px",
          color: "white",
          borderRadius: "8px",
          my: "12px",
          textDecoration: "none !important",
        }}
      >
        {t("common.goBackToJobs")}
      </Link>
    </Flex>
  );
};

export default PageNotFound;
