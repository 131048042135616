import { handleErrorResponse } from "../lib/utils";
import { UserPrivileges } from "../types/Privileges";
import { put } from "./api";

export const updateUserPrivilegesMutate = async ({
  workspaceId,
  updatedUserId,
  privileges,
}: {
  workspaceId: number;
  updatedUserId: number;
  privileges: UserPrivileges;
}): Promise<{
  userPrivileges?: UserPrivileges;
  errorCode?: number;
  error?: string;
}> => {
  const url = `/user-privilege`;

  try {
    const res = await put(url, { workspaceId, updatedUserId, privileges });
    if (res.data) return { userPrivileges: res.data.userPrivileges };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};
