import { defineStyleConfig } from "@chakra-ui/react";

export const Heading = defineStyleConfig({
  baseStyle: {
    fontWeight: "bold",
    color: "black.darker",
    fontFamily: "sans-serif",
  },

  sizes: {
    sm: {
      fontSize: "16px",
      lineHeight: "24px",
    },
    md: {
      fontSize: "24px",
      lineHeight: "24px",
    },
    lg: {
      fontSize: "36px",
      lineHeight: "48px",
    },
  },

  variants: {
    primary: {
      color: "black",
    },
    secondary: {
      color: "white",
    },
  },

  defaultProps: {
    variant: "primary",
    size: "md",
  },
});
