import { createContext, useEffect, useState } from "react";
import { User } from "../types/user";
import { AlertMessageProps } from "../components/Molecules/AlertMessage/AlertMessage";
import { Workspace } from "../types/workspace";
import { useCheckIsMobile } from "../lib/utils";
import Cookies from "js-cookie";

interface IAppContext {
  user?: User;
  setUser: (user: User) => void;
  workspaces?: Workspace[];
  setWorkspaces: (workspaces: Workspace[]) => void;
  showAlertMessage: boolean;
  alertMessageProps?: AlertMessageProps;
  setAlertMessage: (props: AlertMessageProps, autoClose?: boolean) => void;
  closeAlertMessage: () => void;
  isSidebarOpen: boolean;
  setIsSidebarOpen: (sidebarState: boolean) => void;
  currentWorkspace?: Workspace;
  setCurrentWorkspace: (workspaceId: number) => void;
}

export const AppContext = createContext<IAppContext>({
  user: undefined,
  setUser: (user: User) => {},
  showAlertMessage: false,
  setAlertMessage: (props: AlertMessageProps, autoClose?: boolean) => {},
  closeAlertMessage: () => {},
  alertMessageProps: undefined,
  isSidebarOpen: true,
  setIsSidebarOpen: (sidebarState: boolean) => {},
  setWorkspaces: (workspaces: Workspace[]) => {},
  workspaces: [],
  currentWorkspace: undefined,
  setCurrentWorkspace: () => {},
});

export const AppContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const isMobile = useCheckIsMobile();

  const [user, setUser] = useState<User | undefined>(undefined);
  const [workspaces, setWorkspaces] = useState<Workspace[] | undefined>([]);
  const [currentWorkspace, setCurrentWorkspace] = useState<
    Workspace | undefined
  >(undefined);
  const [showAlertMessage, setShowAlertMessage] = useState<boolean>(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
  const [alertMessageProps, setAlertMessageProps] = useState<
    AlertMessageProps | undefined
  >(undefined);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const closeAlertMessage = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }
    setShowAlertMessage(false);
    setAlertMessageProps(undefined);
  };
  const setAlertMessage = (
    props: AlertMessageProps,
    autoClose: boolean = true
  ) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }

    if (autoClose) {
      setAlertMessageProps(props);
      setShowAlertMessage(true);
      const id = setTimeout(() => {
        closeAlertMessage();
      }, 10000);
      setTimeoutId(id);
    } else {
      setAlertMessageProps(props);
      setShowAlertMessage(true);
    }
  };

  const updateUser = (user: User) => {
    setUser(user);
  };

  const updateWorkspaces = (workspaces: Workspace[]) => {
    setWorkspaces(workspaces);
  };

  const updateCurrentWorkspace = (workspaceId: number) => {
    if (workspaces && !!workspaces.length) {
      const currentWorkspaceId = workspaces?.findIndex(
        (w) => w.id === workspaceId
      );
      if (currentWorkspaceId !== undefined && currentWorkspaceId !== null) {
        setCurrentWorkspace(workspaces[currentWorkspaceId]);
        Cookies.set("curr_wsp_id", workspaceId.toString());
      }
    } else {
      setCurrentWorkspace(undefined);
    }
  };

  useEffect(() => {
    if (workspaces?.length) {
      const lastUsedWorkspace = Cookies.get("curr_wsp_id");
      if (lastUsedWorkspace) {
        const filteredWorkspaces = workspaces.filter(
          (w) => w.id === Number(lastUsedWorkspace)
        );
        if (!!filteredWorkspaces.length)
          setCurrentWorkspace(filteredWorkspaces[0]);
        else setCurrentWorkspace(workspaces[0]);
      } else setCurrentWorkspace(workspaces[0]);
    } else {
      setCurrentWorkspace(undefined);
    }
  }, [workspaces]);

  useEffect(() => {
    setIsSidebarOpen(!isMobile);
  }, [isMobile]);

  const setSidebarState = (sidebarState: boolean) => {
    setIsSidebarOpen(sidebarState);
  };

  return (
    <AppContext.Provider
      value={{
        user,
        showAlertMessage,
        alertMessageProps,
        closeAlertMessage,
        setAlertMessage,
        setUser: updateUser,
        isSidebarOpen,
        setIsSidebarOpen: setSidebarState,
        workspaces,
        setWorkspaces: updateWorkspaces,
        currentWorkspace,
        setCurrentWorkspace: updateCurrentWorkspace,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
