export enum PrivilegesEnum {
  canAddJob = "canAddJob",
  canRemoveJob = "canRemoveJob",
  canAddUsersToWorkspace = "canAddUsersToWorkspace",
  canRemoveUsersFromWorkspace = "canRemoveUsersFromWorkspace",
  canSeeAllJobsInWorkspace = "canSeeAllJobsInWorkspace",
}

export type UserPrivileges = {
  [key in PrivilegesEnum]?: boolean;
};
