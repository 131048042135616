import { User } from "../types/user";
import { put } from "./api";
import { handleErrorResponse } from "../lib/utils";

export const editUserRoleMutate = async (data: {
  role: string;
  workspaceId: number;
  updatedUserId: number;
}): Promise<{ user?: User; errorCode?: number; error?: string }> => {
  const url = `/user-role`;

  try {
    const res = await put(url, data);
    if (res.data) return { user: res.data.user };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};
