import { useTranslation } from "react-i18next";
import { Heading } from "../../Atoms/Heading/Heading";
import { Input } from "../../Atoms/Input/Input";
import { Flex } from "@chakra-ui/react";
import { Button } from "../../Atoms/Button/Button";
import { ArrowForwardIcon, ArrowBackIcon } from "@chakra-ui/icons";
import { CheckField } from "../../Atoms/CheckField/CheckField";
import { Constants } from "../../../lib/constants";
import { useState } from "react";
import { isPasswordValid } from "../../../lib/utils";

export type StepPasswordProps = {
  onNext: (password: string) => void;
  onBack: () => void;
  isLoading?: boolean;
};

export const StepPassword = ({
  onNext,
  onBack,
  isLoading,
}: StepPasswordProps) => {
  const [password, setPassword] = useState<string>("");
  const [confirmedPassword, setConfirmedPassword] = useState<string>("");
  const { t } = useTranslation();

  const handleNext = () => {
    if (!isPasswordValid(password) || password !== confirmedPassword) return;

    onNext(password);
  };

  return (
    <>
      <Heading sx={{ marginBottom: "40px" }}>
        {t("register.stepPasswordTitle")}
      </Heading>
      <Input
        type="password"
        label={t("register.stepPasswordTitle")}
        placeholder={t("register.passwordTDescription")}
        value={password}
        onChange={(value) => setPassword(value)}
      />
      <Input
        type="password"
        label={t("register.confirmPassword")}
        placeholder={t("register.confirmPassword")}
        value={confirmedPassword}
        onChange={(value) => setConfirmedPassword(value)}
      />

      <CheckField
        label={t("validation.passwordMinSize")}
        isChecked={password.length >= Constants.MinPasswordLength}
      />
      <CheckField
        label={t("validation.passwordRule")}
        isChecked={!!(/\d/.test(password) && /[A-Z]/.test(password))}
      />
      <CheckField
        label={t("validation.passwordMustMatch")}
        isChecked={!!password && password === confirmedPassword}
      />

      <Flex
        sx={{
          width: "100%",
          flexDir: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "40px",
        }}
      >
        <Button
          variant="secondary"
          leftIcon={<ArrowBackIcon />}
          onClick={onBack}
          isDisabled={isLoading}
        >
          {t("common.previous")}
        </Button>
        <Button
          variant="primary"
          rightIcon={<ArrowForwardIcon />}
          isDisabled={
            !isPasswordValid(password) ||
            password !== confirmedPassword ||
            isLoading
          }
          onClick={handleNext}
          sx={{ ml: 2 }}
        >
          {t("common.next")}
        </Button>
      </Flex>
    </>
  );
};
