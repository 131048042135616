import { Box, Flex, Image } from "@chakra-ui/react";
import { useContext } from "react";
import UserInfo from "../../Molecules/Sidebar/UserInfo";
import { AppContext } from "../../../context/AppContext";
import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import SidebarItems from "../../Molecules/Sidebar/SidebarItems";
import Select from "../../Molecules/Select/Select";
import { getWorkspaceOptions } from "../../../lib/utils";

const DesktopSidebar = () => {
  const {
    isSidebarOpen,
    setIsSidebarOpen,
    workspaces,
    setCurrentWorkspace,
    currentWorkspace,
  } = useContext(AppContext);

  const options = workspaces?.length ? getWorkspaceOptions(workspaces) : [];

  const findOptions = options.filter(
    (cw) => Number(cw.value) === currentWorkspace?.id
  );
  const defaultOption = findOptions.length ? findOptions[0] : undefined;

  const showSelect = !!workspaces?.length && defaultOption;

  return (
    <Flex
      sx={{
        flexDir: "column",
        backgroundColor: "green.foundationDark",
        width: isSidebarOpen ? "320px" : "85px",
        minW: isSidebarOpen ? "320px" : "85px",
        borderRadius: "0px 0px 32px 0px",
        p: isSidebarOpen ? "32px" : "20px",
        h: "100vh",
        position: "sticky",
        top: 0,
        zIndex: 1,
        transition: "width 0.2s ease",
      }}
    >
      {isSidebarOpen && <Image src={"/Logo.svg"} width={"255px"} alt="Logo" />}

      {!isSidebarOpen && <Image src={"/Logo2.svg"} width={"45px"} alt="Logo" />}

      {showSelect && (
        <Box sx={{ mt: "60px", mb: "32px" }}>
          <Select
            name="workspaces"
            theme="sidebar"
            isSearchable={false}
            options={options}
            onChange={(option) => {
              option?.value && setCurrentWorkspace(Number(option?.value));
            }}
            defaultOption={defaultOption}
          />
        </Box>
      )}

      <Flex sx={{ flexGrow: 1, mt: showSelect ? "0" : "64px" }}>
        <SidebarItems />
      </Flex>
      <Flex
        sx={{
          mb: ["24px", "48px"],
          height: "48px",
          width: "100%",
          position: "relative",
        }}
      >
        <Flex
          backgroundColor="white"
          width="68px"
          height={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
          borderRadius="12px 0px 0px 12px"
          right={isSidebarOpen ? "-32px" : "-20px"}
          position={"absolute"}
          cursor={"pointer"}
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
          {isSidebarOpen ? (
            <ArrowLeftIcon color={"green.foundationNormal"} />
          ) : (
            <ArrowRightIcon color={"green.foundationNormal"} />
          )}
        </Flex>
      </Flex>
      <UserInfo />
    </Flex>
  );
};

export default DesktopSidebar;
