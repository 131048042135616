import { Thead, Tr, Th, Tbody, Flex, Text } from "@chakra-ui/react";
import { Table } from "../../Atoms/Table/Table";
import { AppContext } from "../../../context/AppContext";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { DragDropContext } from "react-beautiful-dnd";
import { StrictModeDroppable } from "./StrictModeDroppable";
import LottiePlayer from "../../Molecules/Lottie/Lottie";
import animationData from "../../../lotties/anim_not_found.json";
import { Label, LabelType } from "../../../types/Labels";
import Select, { Option } from "../../Molecules/Select/Select";

import DraggableJobLabelRow from "./DraggableJobInputRow";
import Modal from "../../Molecules/Modal/Modal";
import {
  useDeleteLabel,
  useEditLabel,
  useReorderLabels,
} from "../../../hooks/label";
import { Input } from "../../Atoms/Input/Input";
import { jobStatusSchema } from "./LabelsTab";

const reorderLabelsList = (
  list: Label[],
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex - 1, 1);
  result.splice(endIndex - 1, 0, removed);

  result.forEach((status, index) => {
    status.order = index + 1;
  });

  return result;
};

interface LabelsTableProps {
  labels?: Label[];
}

const JobInputsTable = ({ labels }: LabelsTableProps) => {
  const { currentWorkspace } = useContext(AppContext);

  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [editLabelId, setEditLabelId] = useState<number | null>(null);
  const [newLabelName, setNewLabelName] = useState<string>("");
  const [newLabelNameError, setNewLabelNameError] = useState<string>("");
  const [deleteLabel, setDeleteLabel] = useState<Label | null>(null);
  const { deleteLabel: removeLabel, isPendingDeleteLabel } = useDeleteLabel();
  const { editLabel, isPendingEditLabel } = useEditLabel();
  const { reorderLabels, isPendingReorderLabels } = useReorderLabels();

  const { t } = useTranslation();

  const [workspaceLabels, setWorkspaceLabels] = useState<Label[] | undefined>(
    []
  );

  const inputTypeOptions = [
    {
      value: LabelType.singleLine,
      label: t("workspace.singleLine"),
    },
    {
      value: LabelType.textBox,
      label: t("workspace.textBox"),
    },
  ];

  const [selectedInputTypeOption, setSelectedInputTypeOption] =
    useState<Option>(inputTypeOptions[0]);

  useEffect(() => {
    setWorkspaceLabels(labels);
  }, [labels]);

  const onEditClick = (label: Label) => {
    if (label.id) {
      setShowEditModal(true);
      setEditLabelId(label.id);
      setNewLabelName(label?.name || "");
      setNewLabelNameError("");
      setSelectedInputTypeOption(
        label.type === LabelType.singleLine
          ? inputTypeOptions[0]
          : inputTypeOptions[1]
      );
    }
  };

  const onDeleteLabelClick = (label: Label) => {
    setDeleteLabel(label);
    setShowDeleteModal(true);
  };

  const handleDeleteLabel = async () => {
    if (deleteLabel?.id) await removeLabel(deleteLabel?.id);
    setDeleteLabel(null);
    setShowDeleteModal(false);
  };

  const handleEditLabel = async () => {
    if (
      isPendingDeleteLabel ||
      isPendingEditLabel ||
      isPendingReorderLabels ||
      !editLabel ||
      !editLabelId ||
      !selectedInputTypeOption ||
      !currentWorkspace?.id
    )
      return;

    try {
      await jobStatusSchema.validate(newLabelName);
      if (selectedInputTypeOption.value) {
        await editLabel({
          labelId: editLabelId,
          name: newLabelName,
          type: selectedInputTypeOption.value,
          workspaceId: currentWorkspace.id,
        });
        setShowEditModal(false);
        setNewLabelName("");
        setNewLabelNameError("");
        setSelectedInputTypeOption(inputTypeOptions[0]);
      }
    } catch (error: any) {
      error.errors.forEach((err: { field: string; translationKey: string }) => {
        setNewLabelNameError(t(err.translationKey));
      });
    }
  };

  const onDragEnd = async (result: any) => {
    if (
      isPendingReorderLabels ||
      !result.destination ||
      !workspaceLabels ||
      result.destination.index === 1 ||
      result.source.index === 1
    ) {
      return;
    }

    const items = reorderLabelsList(
      workspaceLabels,
      result.source.index,
      result.destination.index
    );
    setWorkspaceLabels(items);

    if (currentWorkspace?.id)
      await reorderLabels({
        labels: items,
        workspaceId: currentWorkspace?.id,
      });
  };

  return (
    <>
      {!!workspaceLabels?.length ? (
        <>
          <Table variant="status">
            <Thead>
              <Tr>
                <Th>
                  <Flex>
                    <Flex
                      sx={{
                        flexDir: "row",
                        justifyContent: "space-between",
                        width: "100%",
                        flexGrow: 1,
                      }}
                    >
                      <Text>{t("workspace.newJobInputName")} </Text>
                      <Text sx={{ width: "140px", px: 0 }}>
                        {t("workspace.newJobInputType")}
                      </Text>
                    </Flex>
                    <Text sx={{ width: "110px" }}></Text>
                  </Flex>
                </Th>
              </Tr>
            </Thead>
            <DragDropContext onDragEnd={onDragEnd}>
              <StrictModeDroppable droppableId="statuses">
                {(provided) => (
                  <Tbody {...provided.droppableProps} ref={provided.innerRef}>
                    {workspaceLabels.map((label, index) => (
                      <DraggableJobLabelRow
                        label={label}
                        iconsDisabled={isPendingDeleteLabel}
                        onEdit={onEditClick}
                        onDelete={onDeleteLabelClick}
                        key={label.id?.toString()}
                        isRowDisabled={index === 0}
                      />
                    ))}
                    {provided.placeholder}
                  </Tbody>
                )}
              </StrictModeDroppable>
            </DragDropContext>
          </Table>
          {showDeleteModal && (
            <Modal
              onClick={() => handleDeleteLabel()}
              onClose={() => {
                setDeleteLabel(null);
                setShowDeleteModal(false);
              }}
              isOpen={showDeleteModal}
              primaryLabel={t("common.cancel")}
              secondaryLabel={t("common.delete")}
              variant="warning"
              title={<Text>{t("workspace.deleteLabel")}</Text>}
              subtitle={t("workspace.deleteLabelDescription", {
                name: deleteLabel?.name,
              })}
              imageSrc="/AlertErrorCheckmark.svg"
              disabled={isPendingDeleteLabel}
            ></Modal>
          )}
          {showEditModal && (
            <Modal
              onClick={handleEditLabel}
              onClose={() => {
                setShowEditModal(false);
                setNewLabelName("");
                setNewLabelNameError("");
                setSelectedInputTypeOption(inputTypeOptions[0]);
              }}
              isOpen={showEditModal}
              primaryLabel={t("common.cancel")}
              secondaryLabel={t("common.update")}
              variant="success"
              title={<Text>{t("workspace.editLabel")}</Text>}
              subtitle={t("workspace.editNewJobInputDescription", {
                name: currentWorkspace?.name,
              })}
              imageSrc="/Edit.svg"
              disabled={isPendingEditLabel}
            >
              <Input
                label={t("workspace.newJobInputName")}
                placeholder={t("workspace.addJobInputDescription")}
                onChange={(value) => {
                  if (value && value.trim()) setNewLabelNameError("");
                  setNewLabelName(value);
                }}
                value={newLabelName}
                formControlWidth={"100%"}
                labelStyles={{ marginTop: "32px" }}
                errorMessage={newLabelNameError}
              />
              <Select
                isMulti={false}
                name="userTypes"
                theme="default"
                label={t("workspace.newJobInputType")}
                options={inputTypeOptions}
                withImages={false}
                defaultOption={selectedInputTypeOption}
                onChange={(option) => {
                  if (option) setSelectedInputTypeOption(option);
                }}
                isSearchable={false}
              />
            </Modal>
          )}
        </>
      ) : (
        <LottiePlayer
          message={t("workspace.noLabelSet")}
          animationData={animationData}
        />
      )}
    </>
  );
};

export default JobInputsTable;
