import { Avatar, Flex, Tbody, Td, Th, Thead, Tr, Text } from "@chakra-ui/react";
import { User } from "../../../types/user";
import { Table } from "../../Atoms/Table/Table";
import { useTranslation } from "react-i18next";
import { useContext, useState } from "react";
import Modal from "../../Molecules/Modal/Modal";
import { AppContext } from "../../../context/AppContext";
import { WorkspaceUserRoles } from "../../../types/workspace";
import { useNavigate } from "react-router-dom";
import { useRemoveUserFromWorkspace } from "../../../hooks/workspace";
import { useCheckIsMobile } from "../../../lib/utils";
import UserActions from "./UserActions";

interface WorkspaceUsersTableProps {
  users: User[];
  onLastUser: () => void;
}

const WorkspaceUsersTable = ({
  users,
  onLastUser,
}: WorkspaceUsersTableProps) => {
  const { t } = useTranslation();
  const isMobile = useCheckIsMobile();
  const { user: currentUser, currentWorkspace } = useContext(AppContext);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [removeUser, setRemoveUser] = useState<User | null>(null);
  const navigate = useNavigate();
  const { removeUserFromWorkspace, isPendingRemoveUser, invalidateQueries } =
    useRemoveUserFromWorkspace();

  const handleRemoveUserFromWorkspace = async () => {
    if (currentWorkspace?.id && removeUser?.id) {
      const { isUserRemoved } = await removeUserFromWorkspace({
        workspaceId: currentWorkspace?.id,
        removedUserId: removeUser?.id,
      });
      if (isUserRemoved) {
        if (users.length > 1) invalidateQueries();
        else {
          onLastUser();
        }
      }
      setRemoveUser(null);
      setShowDeleteModal(false);
    }
  };

  return (
    <>
      {isMobile ? (
        <Flex sx={{ flexDir: "column", w: "full", gap: "8px" }}>
          {users.map((user) => {
            const isRemoveDisabled =
              user.id === currentUser?.id ||
              user.role === WorkspaceUserRoles.OWNER;
            return (
              <Flex
                key={user.id}
                sx={{
                  flexDir: "row",
                  width: "full",
                  p: "8px",
                  alignItems: "center",
                  borderRadius: "8px",
                }}
                boxShadow={"base"}
                border={"1px solid"}
                borderColor={"gray.light"}
              >
                <Avatar
                  w={"60px"}
                  h={"60px"}
                  name={user.firstName + " " + user.lastName}
                  src={user.filePath}
                  color={"white"}
                  mr={"8px"}
                />
                <Flex
                  sx={{
                    flexDir: "column",
                    width: "full",
                    fontSize: "16px",
                    color: "gray.darker",
                    fontWeight: "bold",
                    flexGrow: 1,
                    overflow: "hidden",
                  }}
                >
                  <Text
                    sx={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {user?.firstName + " " + user?.lastName}
                  </Text>
                  <Text
                    sx={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      fontSize: "12px",
                    }}
                  >
                    {user?.email}
                  </Text>
                  <Text
                    sx={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      fontSize: "12px",
                    }}
                  >
                    {t(`workspace.${user.role}`)}
                  </Text>
                </Flex>
                <UserActions
                  flexDir="column"
                  onEditClick={() => {
                    if (user.id === currentUser?.id) {
                      navigate("/profile");
                    } else {
                      navigate(`/users/${user.id}`);
                    }
                  }}
                  onRemoveClick={() => {
                    setShowDeleteModal(true);
                    setRemoveUser(user);
                  }}
                  isEditDisabled={
                    isPendingRemoveUser ||
                    currentWorkspace?.role === WorkspaceUserRoles.BASIC_USER
                  }
                  isRemoveDisabled={
                    isRemoveDisabled ||
                    isPendingRemoveUser ||
                    (currentWorkspace?.role === WorkspaceUserRoles.BASIC_USER &&
                      !currentWorkspace.privileges?.canRemoveUsersFromWorkspace)
                  }
                  editToolTip={t("users.update")}
                  removeToolTip={t("users.delete")}
                />
              </Flex>
            );
          })}
        </Flex>
      ) : (
        <Table variant="users">
          <Thead>
            <Tr>
              <Th w={"60px !important"}>{""}</Th>
              <Th>{t("profile.firstName")}</Th>
              <Th>{t("profile.lastName")}</Th>
              <Th>{t("profile.email")}</Th>
              <Th>{t("profile.role")}</Th>
              <Th w={"40px !important"}>{""}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {users.map((user) => {
              const isRemoveDisabled =
                user.id === currentUser?.id ||
                user.role === WorkspaceUserRoles.OWNER;
              return (
                <Tr key={user.id}>
                  <Td w={"40px !important"}>
                    <Avatar
                      w={"60px"}
                      h={"60px"}
                      name={user.firstName + " " + user.lastName}
                      src={user.filePath}
                      color={"white"}
                    />
                  </Td>
                  <Td>{user.firstName}</Td>
                  <Td>{user.lastName}</Td>
                  <Td>{user.email}</Td>
                  <Td>{t(`workspace.${user.role}`)}</Td>
                  <Td w={"40px !important"}>
                    <UserActions
                      onEditClick={() => {
                        if (user.id === currentUser?.id) {
                          navigate("/profile");
                        } else {
                          navigate(`/users/${user.id}`);
                        }
                      }}
                      onRemoveClick={() => {
                        setShowDeleteModal(true);
                        setRemoveUser(user);
                      }}
                      isEditDisabled={
                        isPendingRemoveUser ||
                        currentWorkspace?.role === WorkspaceUserRoles.BASIC_USER
                      }
                      isRemoveDisabled={
                        isRemoveDisabled ||
                        isPendingRemoveUser ||
                        (currentWorkspace?.role ===
                          WorkspaceUserRoles.BASIC_USER &&
                          !currentWorkspace.privileges
                            ?.canRemoveUsersFromWorkspace)
                      }
                      editToolTip={t("users.update")}
                      removeToolTip={t("users.delete")}
                    />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      )}
      {showDeleteModal && removeUser && (
        <Modal
          onClick={() => handleRemoveUserFromWorkspace()}
          onClose={() => {
            setShowDeleteModal(false);
            setRemoveUser(null);
          }}
          isOpen={showDeleteModal}
          primaryLabel={t("common.cancel")}
          secondaryLabel={t("common.remove")}
          variant="warning"
          title={<Text>{t("users.removeUser")}</Text>}
          subtitle={t("users.removeUserDescription", {
            name: removeUser.firstName + " " + removeUser.lastName,
            workspaceName: currentWorkspace?.name,
          })}
          imageSrc="/AlertErrorCheckmark.svg"
          disabled={false}
        ></Modal>
      )}
    </>
  );
};
export default WorkspaceUsersTable;
