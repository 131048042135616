import { handleErrorResponse } from "../lib/utils";
import { Label } from "../types/Labels";
import { del, get, post, put } from "./api";

export const createLabelMutate = async (
  data: Label
): Promise<{ label?: Label; errorCode?: number; error?: string }> => {
  const url = `/label`;

  try {
    const res = await post(url, data);
    if (res.data) return { label: res.data.label };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const getLabelsQuery = async ({
  workspaceId,
}: {
  workspaceId?: number;
}): Promise<{
  labels?: Label[];
  errorCode?: number;
  error?: string;
}> => {
  let url = `/label/workspace/${workspaceId}`;

  try {
    const res = await get(url);
    if (res.data) return { ...res.data };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const deleteLabelMutate = async (
  id: number
): Promise<{ labels?: Label[]; errorCode?: number; error?: string }> => {
  const url = `/label/${id}`;

  try {
    const res = await del(url);
    if (res.data) return { labels: res.data.labels };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const editLabelMutate = async (data: {
  labelId: number;
  name: string;
  type: string;
  workspaceId: number;
}): Promise<{ label?: Label; errorCode?: number; error?: string }> => {
  const url = `/label/${data.labelId}`;

  try {
    const res = await put(url, data);
    if (res.data) return { label: res.data.label };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};

export const reorderLabelsMutate = async (data: {
  labels: Label[];
  workspaceId: number;
}): Promise<{ labels?: Label; errorCode?: number; error?: string }> => {
  const url = `/label/reorder`;

  try {
    const res = await put(url, data);
    if (res.data) return { labels: res.data.labels };
    else return { errorCode: res.errorCode };
  } catch (error: any) {
    if (error instanceof Error) {
      return handleErrorResponse(error);
    }
    throw new Error(error);
  }
};
