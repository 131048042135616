import { Flex } from "@chakra-ui/react";
import { useCreateWorkspace, useGetWorkspaces } from "../../../hooks/workspace";
import WorkspacesTable from "./WorkspacesTable";
import { useState } from "react";
import { Input } from "../../Atoms/Input/Input";
import { useCheckIsMobile } from "../../../lib/utils";
import { FiSearch } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import Select, { Option } from "../../Molecules/Select/Select";
import { Button } from "../../Atoms/Button/Button";
import { FaPlus } from "react-icons/fa";
import { Constants } from "../../../lib/constants";
import Pagination from "../../Molecules/Common/Pagination";
import Modal from "../../Molecules/Modal/Modal";
import { FileUpload } from "../../Atoms/FileUpload/FileUpload";
import { string } from "yup";
import LottiePlayer from "../../Molecules/Lottie/Lottie";
import animationData from "../../../lotties/anim_not_found.json";

export const workspaceSchema = string()
  .required({ field: "name", translationKey: "validation.requiredFiled" })
  .max(Constants.MaxNumberOFCharacters, {
    field: "name",
    translationKey: "validation.maxNumberOfCharactersExceeded",
  });

const WorkspacesSection = () => {
  const { t } = useTranslation();
  const isMobile = useCheckIsMobile();
  const [showCreteWorkspaceModal, setShowCreateWorkspaceModal] =
    useState<boolean>(false);
  const [workspaceName, setWorkspaceName] = useState<string>("");
  const [newWorkspaceNameError, setNewWorkspaceNameError] =
    useState<string>("");
  const [workspaceImage, setWorkspaceImage] = useState<File | undefined>(
    undefined
  );
  const { createWorkspace, isPendingCreateWorkspace } = useCreateWorkspace();
  const [filters, setFilters] = useState({
    page: 1,
    role: "",
    filter: "",
  });
  const userTypeOptions = [
    {
      value: "",
      label: t("workspace.all"),
    },
    {
      value: "owner",
      label: t("workspace.owner"),
    },
    {
      value: "admin",
      label: t("workspace.admin"),
    },
    {
      value: "basicUser",
      label: t("workspace.basicUser"),
    },
  ];
  const [selectedOption, setSelectedOption] = useState<Option>(
    userTypeOptions[0]
  );
  const {
    isFetchingWorkspaces,
    workspaces,
    filteredWorkspacesCount,
    invalidateQueries,
    allWorkspacesCount,
  } = useGetWorkspaces(filters);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const pageCount = filteredWorkspacesCount
    ? Math.ceil(filteredWorkspacesCount / Constants.DefaultItemsPerPage)
    : 0;

  const handleLastWorkspaceInPage = () => {
    if (pageCount > 1) setFilters({ ...filters, page: filters.page - 1 });
    else invalidateQueries();
  };

  const handleAddNewWorkspace = async () => {
    try {
      await workspaceSchema.validate(workspaceName);
      const formData = new FormData();
      formData.append("name", workspaceName);
      if (workspaceImage) formData.append("files", workspaceImage);
      await createWorkspace(formData);
      setWorkspaceImage(undefined);
      setWorkspaceName("");
      setNewWorkspaceNameError("");
      setShowCreateWorkspaceModal(false);
    } catch (error: any) {
      error.errors.forEach((err: { field: string; translationKey: string }) => {
        setNewWorkspaceNameError(t(err.translationKey));
      });
    }
  };

  return (
    <>
      <Flex sx={{ flexDir: "column", width: "100%", gap: "24px" }}>
        <Flex
          sx={{
            flexDir: isMobile ? "column" : "row",
            width: "100%",
            gap: isMobile ? 0 : "16px",
            alignItems: "start",
          }}
        >
          <Input
            type="text"
            LeftIcon={FiSearch}
            placeholder={t("workspace.search")}
            label={t("users.search")}
            formControlStyles={{ flexGrow: 1 }}
            formControlWidth={"full"}
            onChange={(value) => {
              if (timeoutId) {
                clearTimeout(timeoutId);
                setTimeoutId(null);
              }
              const id = setTimeout(() => {
                setFilters({
                  ...filters,
                  filter: value || "",
                  page: 1,
                });
              }, 500);
              setTimeoutId(id);
            }}
          />
          <Select
            isMulti={false}
            isSearchable={false}
            name="userTypes"
            theme="default"
            label={t("profile.role")}
            options={userTypeOptions}
            withImages={false}
            defaultOption={selectedOption}
            onChange={(option) => {
              if (option) {
                setSelectedOption(option);
                setFilters({
                  ...filters,
                  role: option?.value || "",
                  page: 1,
                });
              }
            }}
          />
          <Button
            size="lg"
            leftIcon={<FaPlus color="white" />}
            sx={{
              mt: "26px",
              height: "40px",
              w: "230px !important",
              fontSize: "14px",
              fontWeight: 600,
            }}
            onClick={() => setShowCreateWorkspaceModal(true)}
          >
            {t("workspace.addNew")}
          </Button>
        </Flex>
        {workspaces &&
          !!workspaces.length &&
          !!filteredWorkspacesCount &&
          !!allWorkspacesCount && (
            <>
              <WorkspacesTable
                workspaces={workspaces}
                onLastWorkspaceInPage={handleLastWorkspaceInPage}
              />
              {pageCount > 1 && (
                <Pagination
                  pageCount={pageCount}
                  handlePageClick={(selectedItem) => {
                    setFilters({ ...filters, page: selectedItem.selected + 1 });
                  }}
                  currentPage={filters.page - 1}
                />
              )}
            </>
          )}
        {!workspaces?.length &&
          !isFetchingWorkspaces &&
          !!allWorkspacesCount &&
          !filteredWorkspacesCount && (
            <LottiePlayer
              message={t("workspace.filtersNotFound")}
              animationData={animationData}
            />
          )}
        {!workspaces?.length &&
          !isFetchingWorkspaces &&
          !allWorkspacesCount && (
            <LottiePlayer
              message={t("workspace.noWorkspaces")}
              animationData={animationData}
            />
          )}
      </Flex>
      {showCreteWorkspaceModal && (
        <Modal
          isOpen={showCreteWorkspaceModal}
          onClose={() => {
            setWorkspaceImage(undefined);
            setWorkspaceName("");
            setNewWorkspaceNameError("");
            setShowCreateWorkspaceModal(false);
          }}
          onClick={handleAddNewWorkspace}
          imageSrc="/AddNew.svg"
          title={t("register.workspaceTitle")}
          size="lg"
          primaryLabel={t("common.cancel")}
          secondaryLabel={t("common.add")}
          disabled={isPendingCreateWorkspace}
        >
          <Input
            placeholder={t("workspace.nameInputPlaceholder")}
            label={t("workspace.nameInputTitle")}
            size="md"
            onChange={(value) => {
              if (value) setNewWorkspaceNameError("");
              setWorkspaceName(value);
            }}
            value={workspaceName}
            errorMessage={newWorkspaceNameError}
          />

          <FileUpload
            label={t("workspace.imageInputTitle")}
            onChange={(files) => setWorkspaceImage(files[0])}
          />
        </Modal>
      )}
    </>
  );
};

export default WorkspacesSection;
